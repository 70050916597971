<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">班级管理</span>
                    <el-row>

                        <el-button size="small" type="primary" @click="pushPlan">加入班级</el-button>
                        <el-button size="small" type="primary" @click="addPlan(1)" plain>创建班级</el-button>
                    </el-row>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="当前班级" name="first">
                        <p class="serach">

                            <el-input v-model="keyword" size="small" :clearable="true" @input="changKeyword" placeholder="请输入班级名称"></el-input>
                    
                        </p>
                    </el-tab-pane>
                    <el-tab-pane label="历史班级" name="second"></el-tab-pane>
                </el-tabs>

                <Skeleton v-if="loading" :skeleton_type="'image_text'"></Skeleton>

                <div v-if="!loading">

                    <el-col v-if="!plan_data.length" :span="24">
                        <el-empty description="未创建班级"></el-empty>
                    </el-col>

                    <el-col v-for="(item,index) in plan_data" :key="index" :span="24">
                        <el-card shadow="hover">
                            <div class="list_thumb">
                                <img v-if="!item.image" src="../../../static/image/bjtp-mr4.png" />
                                <img v-if="item.image" :src="item.image" />
                                <img class="plan_tage" v-if="item.status == 1" src="../../../static/image/dlbj.png">
                                <img class="plan_tage" v-if="item.status == 0" src="../../../static/image/zrbj.png">
                            </div>
                            <div class="list_info">
                                <div class="list_info_text" style="font-weight: 700;">{{ item.name }}</div>
                                <div class="list_info_text">
                                    <span>年级: {{ item.grade_text }}</span>
                                    <span>学院: {{ item.college }}</span>
                                    <span>专业: {{ item.specialty }}</span>
                                </div>
                                <div class="list_info_text">人数: {{ item.number }}</div>
                            </div>
                            <div v-if="form_data.state == 0" class="list_right">
                                <div v-if="item.isadmin" class="list_right_bu">
                                    <i @click="deletePlan(item.plan_id)" style="color: #f56c6c;" class="el-icon-delete"></i>
                                    <i @click="editPlan(item.plan_id,2)" class="el-icon-edit"></i>
                                    
                                </div>
                                <div :style="`${!item.isadmin?'margin-top:35px':''}`" class="list_right_bu">
                                    <el-button size="small" @click="planDetail(item.plan_id)" type="primary">班级详情</el-button>
                                </div>
                            </div>
                            <div v-if="form_data.state == 1" class="list_right">
                                <div class="list_right_bu">
                                    <i @click="deletePlan(item.plan_id)" class="el-icon-delete"></i>
                                </div>
                                <div class="list_right_bu">
                                    <el-button size="small" @click="recovery(item.plan_id)" type="primary">恢复</el-button>
                                </div>
                            </div>
                        </el-card>
                    </el-col>

                    <el-pagination
                        v-if="total_number"
                        @size-change="changeSize"
                        @current-change="changeNumber"
                        @prev-click="prev"
                        @next-click="next"
                        :page-size="form_data.pageSize"
                        :total="total_number">
                    </el-pagination>
                </div>
            </div>
            <div class="dialog_">
                <el-dialog title="加入班级" width="50%" :visible.sync="dialogFormVisible">
                    <el-form size="small" :model="form">
                        <el-form-item label="" :label-width="formLabelWidth">
                            <el-input v-model="form.qcode" @input="serachPlan" placeholder="输入班级码"></el-input>
                            <!-- <el-button class="search_button" type="primary">搜索</el-button> -->
                        </el-form-item>
                        
                    </el-form>
                    
                    <el-table size="medium" :data="tableData" style="width: 100%">
                        <el-table-column
                            prop="name"
                            label="班级名称">
                        </el-table-column>
                        <el-table-column
                            prop="u_id_text"
                            label="管理员">
                        </el-table-column>
                        <el-table-column
                            prop="grade_text"
                            label="年级">
                        </el-table-column>
                        <el-table-column
                            prop="college"
                            label="年级">
                        </el-table-column>
                        <el-table-column
                            prop="specialty"
                            label="学院">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
       
                                <el-button
                                size="mini"
                                type="primary"
                                @click="handleCreate(scope.$index, scope.row)">加入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-dialog>
            </div>
            <!-- 创建班级 -->
            <div class="dialog_add">
                <el-dialog :title="`${add_type == 1?'创建班级':'修改信息'}`" width="40%" @close="dialogAddColose" :visible.sync="dialogFormAdd">
                    <el-form :model="add_data">
                        <el-form-item label="班级" :label-width="addformLabelWidth">
                            <el-input v-model="add_data.name" placeholder="输入班级名称"></el-input>
                        </el-form-item>
                        <el-form-item label="年级" :label-width="addformLabelWidth">
                            <el-select v-model="add_data.grade" placeholder="请选择年级">
                                
                                <el-option v-for="(item,index) in grade_data" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="学院" :label-width="addformLabelWidth">
                            <el-input v-model="add_data.college" placeholder="输入学院名称"></el-input>
                        </el-form-item>
                        <el-form-item label="专业" :label-width="addformLabelWidth">
                            <el-input v-model="add_data.specialty" placeholder="输入专业名称"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" :label-width="addformLabelWidth">
                            <el-select v-model="add_data.status" placeholder="请选择班级类型">
                                <el-option label="自然班级" value="0"></el-option>
                                <el-option label="独立班级" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-upload
                                :action="upload_path"
                                :data="upload_data"
                                :multiple="false"
                                :file-list="image"
                                list-type="picture-card"
                                :on-success="handleSuccess_"
                                accept="image/*"
                                :auto-upload="true">
                                    <i slot="default" class="el-icon-plus"></i>
                                    <div slot="file" slot-scope="{file}">
                                    <img
                                        class="el-upload-list__item-thumbnail"
                                        :src="file.url" alt=""
                                    >
                                    <span class="el-upload-list__item-actions">
                                        <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)"
                                        >
                                        <i class="el-icon-zoom-in"></i>
                                        </span>
                                        <span
                                        v-if="!disabled"
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                        >
                                        <i class="el-icon-download"></i>
                                        </span>
                                        <span
                                        v-if="!disabled"
                                        class="el-upload-list__item-delete"
                                        @click="handleRemove(file)"
                                        >
                                        <i class="el-icon-delete"></i>
                                        </span>
                                    </span>
                                    </div>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>

                        

                    </el-form>
                    <div class="flex-container">  
                        <div class="flex-item" v-for="(item,index) in plan_thumb" :key="index">
                            <img @click="checkImg(item)" :src="item" />
                        </div>  
                    
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormAdd = false">取 消</el-button>
                        <el-button type="primary" @click="addForm">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
            
        </layout>
    </div>
</template>
  
<script>
    import Http from "../../apis/teach";
    import skeleton from "../../../untils/skeleton.vue";
    export default{
        components:{
            'Skeleton':skeleton
        },
        data(){

            return{
                loading:true,
                disabled:false,
                upload_path:`${this.$Config.baseUrl}/api/common/upload`,
                upload_data:{},
                activeName:'first',
                keyword:'',
                dialogFormVisible:false,
                dialogVisible:false,
                dialogImageUrl:'',
                form:{
                    qcode:''
                },
                add_data:{

                },
                formLabelWidth:'0px',
                tableData: [],
                dialogFormAdd:false,
                addformLabelWidth:'3vw',
                plan_data:[],
                form_data:{
                    page:1,
                    pageSize:6,
                    state:0
                },
                total_number:0,
                grade_data:[],
                image:[],
                add_type:1,
                plan_id:0,
                plan_thumb:[]
            }
        },
        computed: {  
            
        },  

        created(){
            
            var params = {}
            params.token = window.sessionStorage.getItem("token")
            this.upload_data = params
        },
        activated() {
            window.addEventListener('popstate', this.handlePopState());
            this.getList()
            this.getYear_()
        
        },

        methods:{
            handlePopState(){
                document.body.style.overflow = 'auto';
            },
            // 获取年级
            getYear_(){
                Http.getYear().then((res) => {
                    this.grade_data = res.data
                });
            },
            
            // 获取班级列表
            // state 0 管理中的班级，1历史班级
            getList(){
                // const loading = this.$loading({
                //     lock: true,
                //     text: '疯狂加载中...',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.5)'
                // });
                var params = this.form_data
                params.keyword = this.keyword
                Http.PlanList(params).then((res) => {
                    this.loading = false;
                    //loading.close()
                    this.plan_data = res.data.list
                    this.total_number = res.data.count
                    this.plan_thumb = res.data.plan_thumb
                });
            },

            // 上一页

            prev(e){
                this.form_data.page = e
                this.getList()
            },

            // 下一页
            next(e){
        
                this.form_data.page = e
                this.getList()
            },

            // 点击页码
            changeNumber(e){
                this.form_data.page = e
                this.getList()
            },

            // 前往指定页数
            changeSize(e){
                this.form_data.page = e
                this.getList()
            },

            // 切换班级列表
            // tab.index 0 现在管理班级 1历史班级
            handleClick(tab, event){
                this.form_data.state = tab.index
                this.getList();
            },

            // 搜索班级
            changKeyword(value){
                this.form_data.keyword = value
                this.getList();
            },

        
            //加入班级 - 按班级码搜索班级
            
            serachPlan(value){
  
                var params = this.form
                params.qcode = value
                Http.getPlan(params).then((res) => {
      
                    this.tableData = res.data
                });
         
            },
            // 加入班级
            handleCreate(index,item){
                var params = {}
                params.plan_id = item.id
                Http.pushPlan(params).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    this.dialogFormVisible = false
                    if(res.code){
                        this.getList()
                    }
                });
            },

            checkImg(thumb){
                var row = {}
                row.name = ''
                row.url = thumb
                var image = []
                image.push(row)
                this.image = image
                this.add_data.image = thumb
            },

            // 图片上传成功回调
            handleSuccess_(e){
                var row = {}
                row.name = ''
                row.url = e.data.fullurl
                var image = []
                image.push(row)
                this.image = image
                this.add_data.image = e.data.url
            },

            //提交保存

            addForm(){
                // 添加
                if(this.add_type == 1){
                    this.create()
                }else{
                    // 修改
                    this.edit()
                }
            },

            create(){
                Http.createPlan(this.add_data).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    this.dialogFormAdd = false
                    this.getList()
                });
            },

            edit(){

                var params = this.add_data
                params.plan_id = this.plan_id
                Http.updatePlan(params).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });

                    this.add_data = {}
                    this.image = []
                    this.dialogFormAdd = false
                    this.getList()
                });
            },

            // type 1 创建班级
            addPlan(type){
                this.add_type = type
                this.dialogFormAdd = true
            },
            pushPlan(){
                this.dialogFormVisible = true
            },

            planDetail(plan_id){
                var _this = this
                _this.$router.push({name:'PlanDetail',query:{plan_id:plan_id}});
            },
            // 删除班级
            deletePlan(plan_id){

                var diao_str = this.form_data.state == 0?
                '删除后可在历史班级中恢复, 是否继续?'
                :'从历史班级中删除后不可恢复, 是否继续?';

                this.$confirm(diao_str, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params = {}
                    params.plan_id = plan_id
                    params.state = this.form_data.state
                    Http.delPlan(params).then((res) => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.code ? 'success' : 'error'
                        });
                        this.getList()
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });          
                });
            },

            
            // 班级信息回显 type 2修改班级信息
            editPlan(plan_id,type){
                this.plan_id = plan_id
                this.add_type = type
                var params = {}
                params.plan_id = plan_id
                Http.getDetail(params).then((res) => {

                    var add_data = {}
                    add_data.college = res.data.college
                    add_data.grade = res.data.grade.toString()
                    add_data.status = res.data.status.toString()
                    add_data.image = res.data.image
                    add_data.name = res.data.name
                    add_data.specialty = res.data.specialty
                    console.log(add_data)
                    this.add_data = add_data


                    this.$Common.reconsitution(res.data,'plan_detail')
                    var row = {}
                    row.name = ''
                    row.url = res.data.image
                    var image = []
                    image.push(row)
                    this.image = image
                    this.dialogFormAdd = true
                });
            },

            // 关闭创建、修改窗口回调
            dialogAddColose(){
                this.add_data = {}
                this.image = []
            },

            // 恢复班级
            recovery(plan_id){

                var params = {}
                params.plan_id = plan_id
                Http.recoveryPlan(params).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    this.getList()
                });

            }
        }
    }

</script>
<style scoped>
    .dialog_ >>> .el-table thead{
        color: #000000;
    }
    .paln_page >>> .el-main{
        background: #ffffff !important;
        margin: 20px;
        border-radius: 8px;
    }
    .top_label >>> .el-row{
        float: right;
    }
    .main_body >>> .el-pagination{
        display: inline-block;
        margin-top: 20px;
    }
    .main_body >>> .el-tabs__content{
        padding-bottom: 20px;
    }
    .main_body >>> .el-tabs__item{
        font-size: 16px;
    }
    .main_body >>> p{
        text-align: left;
    }
    .search{
        margin-left: 15px;
    }
    .main_body >>>.el-card__body{
        float: left;
        width: 100%;
        padding-right: 25px;
        box-sizing: border-box;
    }
    .list_right >>> i{
        float: right;
        margin-left: 10px;
        font-size: 18px;
    }
    .list_right_bu >>> .el-button{
        float: right;
    }
    .main_body >>> .el-col{
        margin-bottom: 20px;
    }
    .serach >>> .el-input{
        width: 10vw;
        float: left;
    }
    .dialog_ >>> .el-input{
        width: 30%;
        float: left;
    }
    .dialog_ >>> .el-dialog{
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
    }
    .dialog_ >>> .search_button{
        float: left;
        margin-left: 10px;
    }
    .dialog_ >>> .el-dialog__header{
        background: #409EFF;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .dialog_ >>> .el-dialog__title{
        color: #ffffff !important;
    }
    .dialog_ >>> .el-dialog__close{
        color: #ffffff !important;
    }
    .dialog_add >>> .el-form{
        width: 60%;
        margin: 0 auto;
    }
    .dialog_add >>> .el-select{
        width: 100%;
    }
    .flex-container {  
    display: flex;  
    justify-content: flex-start; /* 或者其他你需要的对齐方式 */  
    gap: 10px; /* 设置子元素之间的间隙 */  
}  
  
.flex-item {  
    flex: 1;
    /* 这里不需要设置margin-right了 */  
}
.flex-item img{
    width: 7vw;
}
.list_thumb{
    position: relative;
}
.plan_tage{
    position: absolute;
    width: 60px !important;
    height: 38px !important;
    left: 0;
}

</style>
<style lang="less" scoped>
@import url('../../../static/css/main.less');
</style>