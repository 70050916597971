<template>
    <div class="first_box">
        <div style="display: flex; flex-wrap: wrap;">
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.total_number }}</span><span class="unit">篇</span>
                </div>
                <div class="item name">
                    做题总数 
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_number }}</span><span class="unit">篇</span>
                </div>
                <div class="item name">
                    阅读在线 
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_number }}</span><span class="unit">篇</span>
                </div>
                <div class="item name">
                    应试英语 
                </div>
            </el-card>
            
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_average_score }}</span><span class="unit">%</span>
                </div>
                <div class="item name">
                    阅读在线正确率
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_average_score }}</span><span class="unit">%</span>
                </div>
                <div class="item name">
                    应试英语正确率
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_time }}</span><span class="unit"></span>
                </div>
                <div class="item name">
                    阅读平均时长
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_time }}</span><span class="unit"></span>
                </div>
                <div class="item name">
                    应试平均时长
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.total_time }}</span><span class="unit"></span>
                </div>
                <div class="item name">
                    学习总时长
                </div>
            </el-card>
            <el-card class="box-card" style="padding: 25px;" shadow="never">

                <div style="color: #409EFF;cursor: pointer;" class="item">
                    <i class="el-icon-s-data"></i>
                    图标统计 
                </div>
            </el-card>
        </div>

        <div class="label_button_box">
            <div class="label_button_box_left">
                <!-- <el-input v-model="input" placeholder="请输入学生姓名"></el-input> -->
                
            </div>
            <div class="label_button_box_right">
                <!-- <el-button size="small" type="primary">导出成绩</el-button> -->
    
            </div>
            
        </div>

        <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            >
            <el-table-column
            type="index"
            width="55">
            </el-table-column>
            <el-table-column
            prop="w_id_text"
            label="标题"
            >
            </el-table-column>
            <el-table-column
            prop="table_type_text"
            label="类别"
            width="120"
            >
            </el-table-column>
            <el-table-column
            prop="score"
            width="100"
            label="正确率"
            >
            </el-table-column>
            <el-table-column
            prop="time"
            width="100"
            label="耗时"
            >
            </el-table-column>
            <el-table-column
            prop="createtime"
            width="100"
            label="时间"
            >
            </el-table-column>
            <el-table-column width="100" label="操作">
                <template slot-scope="scope">
           
                    <el-button
                    size="mini"
                    type="primary"
                    @click="lookWork(scope.$index, scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="total_number"
            style="margin-top:20px"
            @size-change="changeSize"
            @current-change="changeNumber"
            @prev-click="prev"
            @next-click="next"
            :page-size="pageSize"
            :total="total_number">
        </el-pagination>
    </div>
    
</template>
<script>
import Http from "../src/apis/student";
export default {
    data(){
        return{
            total_data:[],
            tableData:[],
            total_number:0,
            form:{
                plan_id:''
            },
            page:1,
            pageSize:20
        }
    },

    methods:{
        // 整体成绩
        getTotalData(plan_id){
            this.form.plan_id = plan_id
            var params = this.form
            params.type = 1
            Http.workResult(params).then((res) => {
                this.total_data = res.data
            });
        },

        // 历史记录

        getHistory(){

            var params = {}
            params.page = this.page
            params.pageSize = this.pageSize
            Http.historyList(params).then((res) => {
                this.total_number = res.data.total_number
                this.tableData = res.data.list
            });

        },

        lookWork(index,item){
            if(item.table_type == "read"){
                this.$router.push({name:'ReadDetail',query:{id:item.w_id}});
            }else{
                this.$router.push({name:'SpecialtyDetail',query:{id:item.w_id,type:item.r_type}});
            }
            
        },

        // 上一页

        prev(e){
            this.page = e
            this.getHistory()
        },

        // 下一页
        next(e){
        
            this.page = e
            this.getHistory()
        },

        // 点击页码
        changeNumber(e){
            this.page = e
            this.getHistory()
        },

        // 前往指定页数
        changeSize(e){
            this.page = e
            this.getHistory()
        },
    }
}
</script>

<style scoped>

.first_box >>> .el-row{
    padding: 20px 0;
    border-top: 10px #409EFF solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin: 0 !important;
}
.first_box >>> .head{
    line-height: 30px;
}

.first_box >>> .title{
    color: #000000;
    font-size: 18px;
    font-weight: 700;
}
.first_box >>> .number{
    color: #409EFF;
}
.first_box >>> .el-card{
    float: left;
}
.box-card{
    padding: 10px 35px;
    margin-top: 20px;
    margin-right: 15px;
}
.box-card  >>> .el-card__body{

    padding: 0;
}
.box-card .item{
    line-height: 30px;

}

.box-card .number{
    font-weight: 700;
    color: #409EFF;
}
.box-card >>> .name{
    font-weight: 0 !important;
    font-size: 14px;
}
.box-card span{
    width: auto;
}
.unit{
    display: inline-block;
    font-size: 12px;
}
</style>