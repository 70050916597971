import Server from './server.js'
import axios from 'axios'
const getTime = (time_str)=>{

    if(!time_str){
        return 0;
    }

    var date = new Date(time_str);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;

    let time = y + '-' + m + '-' + d;
    return time;
}


const reconsitution = (data,type)=>{
    var host = Server.Host
    // 班级详情
    if(type == 'plan_detail'){
        if(data.image){
            data.image = host + data.image
        }
    }
    if(type == 'plan_list'){
        for(var index in data){
            if(data[index].image){
                // 不包含http 或 https
                data[index].image = host + data[index].image
            }
        }
    }

    if(type == 'campus'){
        for(var index in data){
            if(data[index].image){
                // 不包含http 或 https
                data[index].image = host + data[index].image
            }
            if(data[index].fileurl){
                data[index].fileurl = host + data[index].fileurl
            }
        }
    }

    if(type == 'music'){
        for(var index in data){
            if(data[index].image){
                // 不包含http 或 https
                data[index].image = host + data[index].image
            }
            if(data[index].m_url){
                data[index].m_url = host + data[index].m_url
            }
        }
    }

    if(type == 'shorts'){
        for(var index in data){
            if(data[index].image){
                // 不包含http 或 https
                data[index].image = host + data[index].image
            }
            if(data[index].w_url){
                data[index].w_url = host + data[index].w_url
            }
        }
    }
    

    if(type == 'read'){
        for(var index in data){
            if(data[index].image){
                // 不包含http 或 https
                data[index].image = host + data[index].image
            }
        }
    }
    if(type == 'skill'){
        for(var index in data){
            if(data[index].thumb){
                // 不包含http 或 https
                data[index].thumb = host + data[index].thumb
            }
        }
    }
    if(type == 'culture'){
        for(var index in data){

            for(var i in data[index]){
                if(data[index][i].image){
                    data[index][i].image = host + data[index][i].image
                }
                
            }
            if(data[index].fileurl){
                data[index].fileurl = host + data[index].fileurl
            }
        }
    }

}

// 域名
const Server_host = ()=>{
    var host = Server.Host
    return host
}

const objectToQueryString = (obj) => {  
    // 去除可能的属性名或值中的非法字符  
    const params = Object.entries(obj)  
        .map(([key, value]) => {  
            // 假设value是字符串，如果不是，需要转换为字符串  
            let strValue = String(value);  
            // 去除值尾部的逗号（如果有）  
            if (strValue.endsWith(',')) {  
                strValue = strValue.slice(0, -1);  
            }  
            // 使用encodeURIComponent来编码键和值  
            return encodeURIComponent(key) + '=' + encodeURIComponent(strValue);  
        })  
        .join('&'); // 使用&符号连接参数对  
  
    return params;  
}  
  

export default{
    getTime,
    reconsitution,
    Server_host,
    objectToQueryString
}