<!-- 查看周期作业 -->
<template>
    <div class="cycle_page">
        <el-dialog title="查看作业" :visible.sync="dialogFormVisible">
            <el-timeline >
                <el-timeline-item v-for="(item,index) in detail" :key="index" :timestamp="`第${item.periods}${period_text}`" placement="top" :type="item.now_time?'primary':''">
                    <h4>作业信息：</h4>
                    <el-card>
                        <h4>阅读/应试: {{ item.number }}</h4>
                        <h4>完成人数: {{ item.submit_number }}</h4>
                        <h4>时间周期：{{ item.starttime }} 至 {{ item.endtime }}</h4>
                        <h4 style="color: rgb(245, 108, 108);">周期得分：{{ item.fraction }} 分</h4>
                        <div class="work_detail">
                            <el-col v-for="(value,k) in item.item" :key="k" :span="24">
                                <el-card shadow="hover">
                                    <div @click="doWork(value.wid,value.periods,value.type,value.z_type,value.starttime,value.endtime)">
                                        <span >{{ value.type == 0?'阅读在线':'应试英语' }}</span>
                                        <span :style="`${!value.is_do?'color:#f56c6c':''}`"> {{ value.is_do?'已完成':'未完成' }} </span>
                                        <span >{{ value.title }}</span>
                                    </div>
                                    
                                </el-card>
                    
                                
                            </el-col>
                        </div>
                        
                    </el-card>
                </el-timeline-item>

            </el-timeline>
        </el-dialog>
    </div>
</template>

<script>
import Http from "../src/apis/student";
export default {
    data(){
        return{
            dialogFormVisible:false,
            detail:[],
            period_text:'',
            task_id:''
        }
    },

    methods:{

        viewWorkDetail(task_id,period_text,plan_id){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            this.period_text = period_text
            this.task_id = task_id
            this.plan_id = plan_id
            var params = {}
            params.task_id = task_id
            params.plan_id = plan_id
            params.type = 0
            Http.workDetail(params).then((res) => {
                this.detail = res.data
                this.dialogFormVisible = true
                loading.close()
            });
        },
        // 跳转文章做题
        // worktype 0 周期作业  1临时作业
        // type、dotype 0 非学习计划 1学习计划
        // id work表id
        doWork(wid,periods,type,z_type,starttime,endtime){
            // window.sessionStorage.setItem("plan_identifying", this.plan_id);
            // window.sessionStorage.setItem("type_identifying", 0);
            // window.sessionStorage.setItem("periods", periods);
            var timestamp = Math.floor(Date.now() / 1000);

            if(timestamp > starttime && timestamp < endtime){
                this.dialogFormVisible = false
                if(type == 0){
                    this.$router.push({name:'ReadDetail',query:{id:wid,task_id:this.task_id,plan_id:this.plan_id,periods:periods,worktype:0,type:0}})
                }else{
                    this.$router.push({name:'SpecialtyDetail',query:{id:wid,task_id:this.task_id,plan_id:this.plan_id,periods:periods,worktype:0,type:z_type,dotype:0}})
                }
            }else{
                this.$message({
                    showClose: true,
                    message: '完成时间已过或还未开始，暂时不能做题',
                    type: 'error'
                });
            }


            
            
        },
    }
}
</script>
<style scoped>
.work_detail >>> .el-card__body{
    padding: 5px;
}
.work_detail >>> .el-col{
    margin-bottom: 10px;
}
.work_dialog_box span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    
    color: #000000;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
}
.cycle_page >>> .el-timeline-item__timestamp{
    color: #409EFF !important;
    font-size: 14px;
    font-weight: 700;
}
</style>