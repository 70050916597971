import axios from '../services/request'

// 列表
export const Index = (data = {}) => axios.get('api/wiki/index',data);

// 百科分类
export const getWikiType = (data = {}) => axios.get('api/wiki/getWikiType',data);

/* 收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);

// 下载文件
export const Downfile = (data = {}) => axios.post('api/wiki/downfile',data);
export default{
    Index,
    getWikiType,
    Coll,
    Downfile
}