<template>
    <div class="everyday_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/everyday.jpg" />
            <el-page-header @back="goBack" content="每日谚语"></el-page-header>
            <div class="content">
                
                <el-table
                v-if="loading"
                :data="row"
                style="width: 100%">
            
                    <el-table-column
                        prop="content"
                        label="谚语"
                        >
                    </el-table-column>

                </el-table>
                <el-pagination
                    v-if="total_number"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="pageSize"
                    :total="total_number"
                    style="margin-top: 20px;">
                </el-pagination>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/index";
export default {
    data(){
        return{
            row:{},
            total_number:0,
            page:1,
            pageSize:20,
            loading:false
        }
    },

    activated(){
        this.getList()
    },
    methods:{
        getList(){
            var params = {}
            params.page = this.page
            params.pageSize = this.pageSize
            Http.Everyday(params).then((res) => {
                this.loading = true
                this.row= res.data.data
                this.total_number= res.data.total_number
            })
        },
        goBack(){
            window.history.back();
        },
        // 上一页
        prev(e){
            this.page = e
            this.getList()
        },

        // 下一页
        next(e){
    
            this.page = e
            this.getList()
        },

        // 点击页码
        changeNumber(e){
            this.page = e
            this.getList()
        },

        // 前往指定页数
        changeSize(e){
            this.page = e
            this.getList()
        },
        // 搜索班级
        changKeyword(value){
            this.keyword = value
            this.getList();
        },
    }
}
</script>
<style scoped>
.everyday_page .content{
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px;
    margin-top: 20px;
}
.banner{
    width: 100%;
}
.everyday_page >>> .el-page-header{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
}
</style>