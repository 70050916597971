import { render, staticRenderFns } from "./Plandetail.vue?vue&type=template&id=30dd772e&scoped=true"
import script from "./Plandetail.vue?vue&type=script&lang=js"
export * from "./Plandetail.vue?vue&type=script&lang=js"
import style0 from "./Plandetail.vue?vue&type=style&index=0&id=30dd772e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30dd772e",
  null
  
)

export default component.exports