<template>
  <div id="app" class="app-container">

    <!-- <LoginPage /> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>


export default {
  name: 'App',

  created(){
      
  },
  mounted(){
    
    //this.$store.dispatch('fetchConfig');
   
    
  },
  methods:{
  
  }
}
</script>

<style>
body{
  padding: 0;
  margin: 0;
  background: #F5F7FB;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;

}
.banner{
    width: 100%;
}
.app-container {
  width: 100%;
  overflow: hidden;
}
.none_img{
  width: 9vw;
  height: auto;
  margin-top: 10vw;
}
.none_message_img{
  width: 6vw;
  height: auto;
  margin-top: 9vw;
}
.main_body{
  min-height: 40vw;
}

</style>
<style scoped>
.page >>> .el-main{
  background: #ffffff !important;
  margin: 20px;
  border-radius: 8px;
}
</style>