import axios from '../services/request'

/* 登录 */
export const Login = (data = {}) => axios.post('api/user/login',data);

/* 发送验证码 */
export const Sms = (data = {}) => axios.post('api/sms/send',data);

/* 注册 */
export const Register = (data = {}) => axios.post('api/user/register',data);

/* 重置密码 */
export const Resetpwd = (data = {}) => axios.post('api/user/resetpwd',data);

/* 用户信息 */
export const Info = (data = {}) => axios.post('api/user/info',data);

/* 重置密码 */
export const Edit = (data = {}) => axios.post('api/user/edit',data);

/* 老师个人中心左边班级作业、正确率信息 */
export const planInfo = (data = {}) => axios.post('api/teach/planInfo',data);

/* 退出登录 */
export const Logout = (data = {}) => axios.post('api/user/logout',data);

/* 学生个人中心左边班级作业、正确率信息 */
export const studentInfo = (data = {}) => axios.post('api/student/planInfo',data);

/* 学生消息 */
export const studentTidings = (data = {}) => axios.post('api/student/tidings',data);

/* 老师消息 */
export const teachTidings = (data = {}) => axios.post('api/teach/tidings',data);

/* 学校基础设置 */
export const Config_ = (data = {}) => axios.get('api/store/config',data);

/* 注册账号学校搜索 */
export const Serach = (data = {}) => axios.get('api/school/serach',data);

/* 个人中心修改密码 */
export const Resetinfopwd = (data = {}) => axios.post('api/user/resetinfopwd',data);


export default{
    Login,
    Sms,
    Register,
    Resetpwd,
    Info,
    Edit,
    planInfo,
    Logout,
    studentInfo,
    studentTidings,
    teachTidings,
    Config_,
    Serach,
    Resetinfopwd
}