<template>
    <div>
        <!-- 情景对话 -->
        <div class="page-header">
            <el-page-header @back="goBack" :content="typetext"></el-page-header>
        </div>
        <div class="content">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span style="font-weight: 700;">{{ specialty.title }}</span>
                    
                    <!-- <el-button v-if="userInfo && userInfo.type == 1" size="mini" type="primary">布置作业</el-button> -->
                    <el-button :type="button_type" :loading="button_loading" @click="Coll" icon="el-icon-star-off" size="mini" circle></el-button>
                </div>
                <div class="question_box">

                    <audio style="display: none;" :src="specialty.file" ref="audioPlayer" @ended="onEnded"></audio>  
                    <!-- <div class="progress-bar">  
                    <div class="progress-fill" :style="{ width: progress + '%' }"></div>  
                    </div>  
                    <p>当前时间: {{ formatTime(currentTime) }}</p>  
                    <p>总时长: {{ formatTime(duration) }}</p>  
                    <button @click="playAudio">播放</button>  
                    <button @click="pauseAudio" :disabled="!isPlaying">暂停</button>   -->
                    <div class="question_left" v-if="isNotEmptyObject(specialty)">
                        <el-tabs v-model="specialtyname">
                            <el-tab-pane :label="typetext" name="first">
                                <div style="text-align: left;font-size: 12px !important;line-height: 25px;" v-html="specialty.body"></div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
          
                </div>
            </el-card>
            <div class="play_box">
                <span class="play_title">{{ specialty.title }}</span>
                <div class="play_icon">
                    <!-- <i @click="last" class="el-icon-caret-left"></i> -->
                    <i @click="playAudio" :class="isPlaying?'el-icon-video-pause':'el-icon-video-play'"></i>
                    <!-- <i @click="next" class="el-icon-caret-right"></i> -->
                </div>
                <span class="play_time">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
            </div>
            <div class="container">
                <div @click="changeTalk(item,index)" v-for="(item,index) in talk" :key="index" :class="`${index === play_index?'play_ac':''} item`">
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { param } from "jquery";
import Http from "../../src/apis/specialty";
export default {
    props: {  
        form_data: {  
            type: Object,
        },
        userInfo:{
            type: Object,
        }
    },
    data(){
        return{
            button_loading:false,
            button_type:'info',
            specialtyname:'first',
            testname:'first',
            isPlaying: false,  
            specialty:{},
            specialtytest:[],
            typetext:'',
            currentTime: 0,
            duration: 0,
            progress: 0,
            talk:[],
            audio: null,
            play_index:-1,
            id:this.form_data.id
        }
    },
    watch: {
        // 当组件被销毁时
        '$route'(to, from) {
            this.pauseAudio()
        },
    },
    beforeDestroy() {
        
    },
    mounted() {  
        // 初始化时获取音频时长  
        this.$refs.audioPlayer.addEventListener('loadedmetadata', () => {  
            this.duration = this.$refs.audioPlayer.duration;  
        });  
        
    },
    computed: {  
        isNotEmptyObject() {  
            // 返回一个函数，用于在模板中作为方法调用  
            return function(obj) {  
                return Object.keys(obj).length > 0;  
            };  
        }
        
    },  
    mounted(){
        window.addEventListener('popstate', this.handlePopState);  
        
        setTimeout(()=>{
            this.initAudio(); // 在组件加载完成时初始化音频
        },300)
    },
    methods:{
        next(){
            
            if(this.play_index == 5){
                this.$message({
                    showClose: true,
                    message: '已是最后一条，返回列表查看更多',
                    type:  'error'
                });
                return false;
            }
            if(this.play_index == -1){
                this.form_data.id = this.id
                this.play_index = 0
            }else{
                
                this.play_index = this.play_index + 1
                console.log(this.play_index)
                this.form_data.id = this.talk[this.play_index].id
                
            }
            
            //this.getData()
        },
        last(){
            
            if(this.play_index == -1){
                this.$message({
                    showClose: true,
                    message: '已是第一条',
                    type:  'error'
                });
                return false;
            }else{
                this.play_index = this.play_index - 1
            }
        },
        changeTalk(item, index) {
        // 如果当前正在播放，先暂停当前音频
        if (this.audio) {
            this.pauseAudio(); 
        }

        this.play_index = index;
        this.specialty = item;

        // 重置播放状态
        this.isPlaying = false;

        // 初始化新音频
        this.initAudio();
    },
        getPlay(){
            var params = {}
            params.wid = this.specialty.id
            Http.getTalk(params).then((res) => {
                this.talk = res.data
            });
        },
        handlePopState(){
            this.specialty = {}
            this.specialtytest = []
        },
        initAudio() {  
        // 如果已经存在音频对象，先释放它
        if (this.audio) {
            this.audio.pause();
            this.audio.src = ''; // 释放资源
        }

        // 创建新的音频对象
        this.audio = new Audio(this.specialty.file);  
        this.audio.oncanplaythrough = () => {  
            this.duration = Math.floor(this.audio.duration);  
        };  
        this.audio.ontimeupdate = () => {  
            this.currentTime = Math.floor(this.audio.currentTime);  
        };  
        this.audio.onended = () => {  
            this.currentTime = 0;  
            this.isPlaying = false;  
            console.log('音频播放结束');  
        };  
    },

    playAudio() {
        // 只有在音频对象存在时才播放
        if (this.audio) {
            if (this.isPlaying) {
                this.pauseAudio();
                return false;
            }
            this.isPlaying = true;
            this.audio.play().catch(error => {
                console.error('播放失败:', error);
                this.isPlaying = false; // 播放失败时更新状态
            });
        }
    },

    pauseAudio() {
        if (this.audio && this.isPlaying) {
            this.isPlaying = false;
            this.audio.pause();
        }
    },
        onEnded() {
            this.currentTime = 0;
            this.isPlaying = false;
        },
        formatTime(seconds) {
                const minutes = Math.floor(seconds / 60);
                const secs = seconds % 60;
                return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
            },

        goBack(){
            window.history.back();
        },
        // 收藏
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.form_data.id
            params.tabel_type = 'specialty'
            params.title = this.specialty.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
                
                    
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                
            });
        },
        getData(){
            // const loading = this.$loading({
            //     lock: true,
            //     text: 'Loading',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.4)'
            // });
            var params = {}
            params.id = this.form_data.id
            Http.Detail(params).then((res) => {
                this.button_type = res.data.collection?'primary':'info'
                this.specialty = res.data.specialty
                this.specialtytest = res.data.specialty_test
                this.typetext = res.data.typetext
                this.getPlay()
                //loading.close()
            });

        },

    }
}
</script>
<style scoped>
.content{
    width: 1200px;
    margin: 0 auto;
}
.content >>> .el-card__header{
    display: inline-block;
    width: 100%;
}
.clearfix >>> .el-button{
    float: right;
    margin-left: 15px;
}
.clearfix span{
    float: left;
}
.question_box{

    width: 100%;
    min-height: 400px;

}
.question_left{
    width: 100%;
    min-height: 400px;
    float: left;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 20px;
}
.question_left::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_left::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_left::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_left::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_left >>> .el-tabs__content{

    padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
}
.right{
    background: #75A3E4 !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.error{
    background: #FF6E6E !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.specialtytest{
    width: 100% !important;
}
.progress-bar {  
  width: 100%;  
  background-color: #f3f3f3;  
  border-radius: 10px;  
  overflow: hidden;  
}  
  
.progress-fill {  
    height: 20px;  
    background-color: #409eff;  

    text-align: center; /* 通常不需要在进度条上显示文本 */  
    line-height: 20px; /* 通常不需要在进度条上显示文本 */  
    color: white; /* 通常不需要在进度条上显示文本 */  
    width: 0%; /* 初始宽度为0 */  
    border-radius: 10px; /* 圆角只在左边 */  
    transition: width 0.2s ease; /* 添加过渡效果 */  
}
.page-header{
    width: 1200px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.page-header >>> .el-page-header{
    line-height: 40px;
    padding: 5px 15px;
}
.page-header >>> .el-page-header__content{
    font-size: 15px;
}
.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
}

.item {
    flex: 0 0 calc(16.66% - 15px);
    margin-bottom: 15px;
    font-size: 14px;
    box-sizing: border-box;
    padding: 15px;
    text-align: left;
    border: 1px solid #EBEEF5;
    background: #ffffff;
    cursor: pointer;
}
.play_box{
    display: inline-block;
    width: 1200px;
    padding: 20px 10px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 10px;
    background: #ffffff;
    line-height: 30px;
}
.play_title{
    width: 500px;
    text-align: left;
    float: left;
}
.play_icon{
    float: left;
}
.play_icon >>> i{
    font-size: 32px;
    color: #409eff;
    margin-left: 20px;
    cursor: pointer;
}
.play_time{
    float: right;
}
.play_ac{
    border: 1px solid #409eff;
}
</style>