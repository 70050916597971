<template>
    <div class="page">
        <layout>
            <div class="cyclework_body">

                <div class="top_label">
                    <span class="lable_name">

                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: 'work' }">作业管理</el-breadcrumb-item>
                            <el-breadcrumb-item>布置周期作业</el-breadcrumb-item>
                        </el-breadcrumb>

                    </span>

                </div>
                <form :model="form_data">
                    <div class="top_label">
                        <span class="lable_name">作业名称</span>
                    </div>
                    <div class="top_label">
                        
                        <span><el-input size="small" v-model="form_data.title" placeholder="请输入作业名称"></el-input></span>
                    </div>
                    <div class="top_label">
                        <span class="lable_name">阅读在线设置</span>
                    </div>
                    <div class="top_label_type">
                        <div style="display: inline-block;width: 100%;">

                            <div style="clear: both;"></div>
                            <div v-for="(city,index) in cities" :key="index" style="margin-bottom: 10px;">
                                <span :class="`read_label`">{{city}}</span>
                                <el-input size="small" v-model="form_data.read_type[index]" placeholder="输入篇数"></el-input>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="top_label">
                        <span class="lable_name">应试英语设置</span>
                    </div>
                    <div class="top_label_type">
                        <div>
                            <div style="clear: both;"></div>
                            <div v-for="(city,index) in cispace_data" :key="index" style="margin-bottom: 10px;">
                                <span :class="`specialty_label`" >{{city}}</span>
                                <el-input size="small" v-model="form_data.specialty_type[index]" placeholder="输入篇数"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="top_label">
                        <span class="lable_name">推送设置</span>
                    </div>
        
                    <div v-if="plan.length > 1" class="top_label_type" style="margin-bottom: 20px;line-height: 30px;">
                        <div>
                            <el-button size="small" type="primary">试题随机</el-button>
                            <el-checkbox style="margin-left:15px" v-model="form_data.rands">每个班级是否推送相同试题</el-checkbox>
                        </div>
                    </div>
                    <div class="top_label_type" style="margin-bottom: 20px;">
                        <div>
                            <!-- <el-tooltip class="item" effect="dark" content="" placement="top-start"> -->
                                <el-button size="small" type="primary">作业周期</el-button>
                            <!-- </el-tooltip> -->
                            
                            <el-date-picker
                                v-model="form_data.time"
                                type="daterange"
                                align="right"
                                size="small"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                            <el-tooltip class="item" effect="dark" content="按周或按月布置作业选择的时间区间最后一个周期如果不足一周或者一月则不会计算，比如：1月1日到2月20日，二月时间不足一月，则只会生成一个周期" placement="right">
                                <i style="margin-top: 7px;font-size: 20px;margin-left: 10px;color: #409EFF;" class="el-icon-question"></i>
                            </el-tooltip>
                            
                        </div>
                    </div>
                    <div class="top_label_type" style="margin-bottom: 20px;line-height: 33px;">
                        <div>
                            <el-button size="small" type="primary">推送周期</el-button>
                            <el-radio-group v-model="form_data.period">
                                <el-radio :label="1">周</el-radio>
                                <el-radio :label="2">月</el-radio>
                                <el-radio :label="3">学期</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="top_label">
                        <span class="lable_name">推送班级</span>
                    </div>

                    <div class="top_label_type" style="margin-bottom: 20px;">
                        <div>
                            <el-tag v-for="tag in plan" :key="tag" type="danger">{{tag}}</el-tag>
                        </div>
                        
                    </div>
                </form>

                <el-button :loading="loading" size="small" @click="submitForm" type="primary">设置完成，确认布置作业</el-button>
                
                <!-- <div class="top_label">
                    <span class="lable_name">推送明细</span>
                </div>
                <div class="top_label_type" style="margin-bottom: 20px;">
                    <div>
                        
                        <el-table
                        :data="work_data"
                        style="width: 100%">
                        <el-table-column
                        type="index"
                        label="序号"
                        width="80">
                        </el-table-column>
                        <el-table-column
                            prop="count_read"
                            label="阅读在线"
                            width="120"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="count_specialty"
                            label="应试英语"
                            width="120"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="starttime"
                            label="开始时间">
                        </el-table-column>
                        <el-table-column
                            prop="endtime"
                            label="结束时间">
                        </el-table-column>
                        </el-table>
                    </div>
                </div> -->
            </div>
            
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/work";
export default {
    data(){
        return{
            loading:false,
            form_data:{
                read_type:[],
                specialty_type:[],
                period:1,
                plan:'',
                rands:'',
                time:null,
                title:''
            },
            
            cities: {1:'一级', 2:'二级', 3:'三级', 4:'四级',5:'五级',6:'六级',7:'七级'},
    
            cispace_data: [],
            input:'',
            checked:true,
            value2:'',
            radio:1,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            plan:[],
            work_data:[]
        }
    },

    activated(){

        this.form_data = {
                read_type:[],
                specialty_type:[],
                period:1,
                plan:'',
                rands:'',
                time:null,
                title:''
            },

        this.form_data.plan = this.$route.query.plan
        this.getCollection_()
        this.getPlan_(this.form_data.plan)
    },

    methods:{

        // 获取应试题型
        getCollection_(){
            Http.getCollection_().then((res) => {
      
                this.cispace_data = res.data
            });
        },

        // 获取班级名称

        getPlan_(plan){
            var params = {}
            params.plan = plan
            Http.getPlanName(params).then((res) => {
                this.plan = res.data

            });
        },
        
        // 确认布置
        submitForm(){
            //this.loading = true
            var params = JSON.parse(JSON.stringify(this.form_data))
            if(params.read_type){
                var read_data = this.initialize(params.read_type)
                params.read_type = read_data.type.join(',')
                params.read_number = read_data.number.join(',')
            }
            if(params.specialty_type){
                var specialty_data = this.initialize(params.specialty_type)
                params.specialty_type = specialty_data.type.join(',')
                params.specialty_number = specialty_data.number.join(',')
            }

            if (!params.read_number.length && !params.specialty_number.length) {  
                this.$message({
                    message: '请任意选择一种类型作业',
                    type: 'error'
                });
                return false
            }

     
            
            if (!params.time) {  
                this.$message({
                    message: '请选择作业时间周期',
                    type: 'error'
                });
                return false
            }

            
            const loading = this.$loading({
                lock: true,
                text: '正在布置作业，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.3)'
            });
            params['start_time'] = this.$Common.getTime(params.time[0])
            params['end_time'] = this.$Common.getTime(params.time[1])
            params['rands'] = params.rands?1:0
            // params['specialty_type'] = params.specialty_type.join(',')
            // params['read_type'] = params.read_type.join(',')
            delete params.time;

            Http.createCyclework(params).then((res) => {
                this.loading = false
                //this.work_data = res.data
                setTimeout(()=>{
                    loading.close()
                },1000)
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
            });

        },


        initialize(data){

            var type = [];
            var number = [];
            var result = {}
            for(var index in data){

                if(data[index]){
                    type.push(index)
                    number.push(data[index])
                }
            }
            result.type = type
            result.number = number
            return result;
        }


    }

}
</script>

<style scoped>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .top_label_type >>> .el-input{
        margin-top: 0px;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .top_label_type >>> .el-input{
        margin-top: -3px;
    }
    
}
.cyclework_body{
    display: inline-block;
    width: 100%;
}

.top_label{
    width: 100%;
    height: 2vw;
    margin-bottom: 1vw;
    line-height: 2vw;
    display: inline-block;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
    line-height: 1.4vw;
    margin-top: 0.3vw;
    margin-right: 15px;
}
.lable_name >>> .el-breadcrumb{
    font-size: 16px;
}
.top_label >>> .el-input{
    width: 20vw;
    float: left;
}
.top_label_type div{
    text-align: left;
}
.top_label_type div >>> .el-button{
    float: left;
}
.top_label_type{
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}
.top_label_type >>> .el-input{
    width: 12vw;
    margin-left: 10px;
    margin-right: 15px;
}
.top_label_type >>> .el-checkbox-group{
    text-align: left;
    margin-top: 10px;
}
.top_label_type >>> .el-date-editor{
    float: left;
    margin-left: 15px;
}
.top_label_type >>> .el-radio-group{
    margin-left: 15px;
}
.top_label_type >>> .el-tag{
    margin-right: 12px;
}
.read_label{
    padding: 7px 20px;
    border: 1px solid #DCDFE6;
    font-size: 12px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.specialty_label{
    display: inline-block;
    padding: 7px 20px;
    border: 1px solid #DCDFE6;
    font-size: 12px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 118px;
    text-align: center;
}
.active{
    background-color: #409EFF !important;
    border-color: #409EFF !important;
    color: #ffffff !important;
}
</style>
