<template>
    <div class="page">
        <layout>
            <div class="main_body">

                <div class="top_label">
                    <span class="lable_name">

                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: 'statistics' }">成绩统计</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ plan_name }}</el-breadcrumb-item>
                        </el-breadcrumb>

                    </span>

                </div>

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="整体成绩" name="first">
                        <div class="first_box">
                            <Total-score ref="totalscore"></Total-score>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="周期作业" name="second">
                        <div class="second_box">
                            
                            <Periodic-statistics @hideLoading="hideLoading" ref="periodicstatistics"></Periodic-statistics>
    
                            <el-row v-for="(item,index) in list" :key="index" :gutter="24">
                                <el-col :span="24">
                                    <el-card shadow="hover">
                                        <div class="left_worrk">
                                            <p>
                                                <span class="number">{{ index + 1 }}</span>
                                                <span class="title">{{ item.title }}</span>
                                            </p>
                                            <p>作业内容：阅读在线{{ item.read_number }}篇/应试英语 {{ item.specialty_number }} 篇</p>
                                            <p v-if="form.type == 0">作业周期：{{ item.period_text }}</p>
                                            <p>布置时间：{{ item.createtime_text }}</p>
                                            <p>起止时间：{{ item.start_time_text }} 至 {{ item.end_time_text }}</p>
                                        </div>
                                        <div class="right_work">
                                            <el-button size="small" :loading="loading" style="margin-bottom: 10px;" @click="viewWorkDetail_(item.id)" type="primary">作业统计</el-button><br>
                                        </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                        </div>
                        


                    </el-tab-pane>
                    <el-tab-pane label="临时作业" name="third">
                        <div class="second_box">
                            
                            <Temporary-statistics ref="temporarystatistics" @hideLoading="hideLoading"></Temporary-statistics>
                            <el-row v-for="(item,index) in temporarylist" :key="index" :gutter="24">
                                <el-col :span="24">
                                    <el-card shadow="hover">
                                        <div class="left_worrk">
                                            <p>
                                                <span class="number">{{ index + 1 }}</span>
                                                <span class="title">{{ item.title }}</span>
                                            </p>
                                            <p>作业内容：阅读在线{{ item.read_number }}篇/应试英语 {{ item.specialty_number }} 篇</p>
                                            <p v-if="form.type == 0">作业周期：{{ item.period_text }}</p>
                                            <p>布置时间：{{ item.createtime_text }}</p>
                                            <p>起止时间：{{ item.start_time_text }} 至 {{ item.end_time_text }}</p>
                                        </div>
                                        <div class="right_work">
                                            <el-button size="small" :loading="loading" style="margin-bottom: 10px;" @click="viewWorkTemporaryDetail_(item.id)" type="primary">作业统计</el-button><br>
                                        </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="自主学习" name="fourth">

                        <div class="second_box">
   

                            <div class="label_button_box">
                                <div class="label_button_box_left">
                                    <el-input v-model="self_form.name" size="small" @input="selectStudent" placeholder="请输入学生姓名"></el-input>
                                    <!-- <el-button type="primary">搜索</el-button> -->
                                </div>
                                <div class="label_button_box_right">
                                    
                                    <el-button size="small" type="primary">导出成绩</el-button>
                                </div>
                                
                            </div>
                            <el-table
                                :data="self_data"
                                tooltip-effect="dark"
                                style="width: 100%;display: inline-block;margin-top: 30px;"
                                >
                                <el-table-column
                                type="index"
                                width="55">
                                </el-table-column>
                                <el-table-column
                                prop="name"
                                label="姓名"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="stuid"
                                label="学号"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="read_number"
                                label="阅读在线"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="read_rate"
                                label="正确率"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="specialty_number"
                                label="应试英语"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="specialty_rate"
                                label="正确率"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="study_time"
                                label="做题时长"
                                >
                                </el-table-column>
                            </el-table>
    
                 
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
                
        </layout>
    </div>
</template>
<script>
import totalscore from "../../../untils/totalscoretemplate.vue";
import periodicstatistics from "../../../untils/periodicstatisticstemp.vue";
import temporarystatistics from "../../../untils/temporarystatistics.vue";

import Http from "../../apis/statistics";
import Work from "../../apis/work";
export default {
    components:{
        'Total-score':totalscore,
        'Periodic-statistics':periodicstatistics,
        'Temporary-statistics':temporarystatistics,
    },
    data() {
        return {
            activeName: 'first',
            form:{
                plan_id:'',
                type:0,
                page:1,
                pageSize:20
            },
            loading:false,
            student:[],
            list:[],
            temporarylist:[],
            self_form:{
                plan_id:'',
                name:''
            },
            plan_name:'',
            self_data:[],
            checkList:['name','stuid','low_rate','high_rate','average_rate'],
            file_form:{'name':'姓名','stuid':'学号','low_rate':'最低正确率','high_rate':'最高正确率','average_rate':'平均正确率','read_number':'阅读在线做题数','specialty_number':'应试英语做题数','time':'耗时','detail':'做题材料'},
            workfile_form:{'name':'姓名','stuid':'学号','low_rate':'最低正确率','high_rate':'最高正确率','average_rate':'平均正确率','score':'得分','read_number':'阅读在线做题数','specialty_number':'应试英语做题数','time':'耗时','detail':'做题材料'}
        };
    },


    activated(){
        this.form.plan_id = this.$route.query.plan_id
        this.form.state = this.$route.query.state
        this.self_form.plan_id = this.$route.query.plan_id
        this.plan_name = this.$route.query.plan_name
        this.getDetail(this.form.plan_id,this.form.state)
        this.getBottomStudent()
        this.geList()
        this.geTemporaryList()
        this.selfStudy()
        setTimeout(()=>{
            this.setData()
        },300)
        
    },
    methods: {
        // 周期作业
        geList(){

            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            Work.getWork(this.form).then((res) => {
                loading.close()
                this.list = res.data
            });

        },
        // 临时作业
        geTemporaryList(){
            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            this.form.type = 1
            Work.getWork(this.form).then((res) => {
                loading.close()
                this.temporarylist = res.data
            });

        },
        setData(){
            this.$refs.totalscore.setData(this.file_form,this.checkList)
            this.$refs.periodicstatistics.setData(this.workfile_form,this.checkList,this.form.state)
            this.$refs.temporarystatistics.setData(this.file_form,this.checkList,this.form.state)
        },

        // 总成绩统计
        getDetail(plan_id,state){
            this.$refs.totalscore.getDetail(plan_id,state)
        },

        // 下方学生列表
        getBottomStudent(){
            this.$refs.totalscore.getBottomStudent(this.file_form,this.checkList)
            
        },

        viewWorkDetail_(task_id){
            this.loading = true
            this.$refs.periodicstatistics.viewWorkDetail(this.form.plan_id,task_id)
        },
        // 临时作业下方学生列表
        viewWorkTemporaryDetail_(task_id){
            this.loading = true
            this.$refs.temporarystatistics.viewWorkDetail(this.form.plan_id,task_id)
        },

        // 自主学些
        selfStudy(){

            Http.selfStudy(this.self_form).then((res) => {
                this.self_data = res.data
            });
        },

        selectStudent(){
            this.selfStudy()
        },
        //
        handleClick(tab, event) {
            
            this.activeName = tab.name
            if(tab.index == 0){
                this.getDetail(this.form.plan_id)
                this.getBottomStudent()
            }else if(tab.index == 1){
                this.form.type = 0
                this.geList()
            } else if(tab.index == 2){
                this.form.type = 1
                this.geTemporaryList()
            } else{
                this.selfStudy()
            }
        },
        hideLoading(res){
            this.loading = res
        }
        
    }
  };
</script>
<style scoped>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .label_button_box_right >>> .el-button{
        float: right;
        margin-top: 0px;
    }
    .label_button_box_left{
        float: right;
        width:21vw;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .label_button_box_right >>> .el-button{
        float: right;
    }
    .label_button_box_left{
        float: right;
        width:19vw;
    }
}
.main_body >>> .el-tabs{
    margin-top: 1vw;
}

.left_worrk{
    text-align: center;
    width: 40vw;
    position: relative;
}
.left_worrk p{
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 26px;
    font-size: 14px;
}
.left_worrk .number{
    float: left;
    width: 25px;
    height: 25px;
    background: #409eff;
    border-radius: 50px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
    margin-right: 10px;
}
.left_worrk .title{
    float: left;
    line-height: 25px;
    font-weight: 700;
}
.right_work{

    position: absolute;
    width: 10vw;
    padding: 10px;
    right: 0;
    top: 50%;
    bottom: 50%;
    margin-top: -25px;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:20px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;

}
.label_button_box_right >>> .el-button{
        float: right;
    }
.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.second_box >>> .el-row{
    margin-bottom: 15px;
}
</style>