<template>
    <div class="_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/xiaoyubaner.png" />
            <div class="_page_content">
                <div class="screen">
                    <el-row v-if="!loading">
                        <el-button size="small" disabled type="text">选文类别</el-button>
                        <span v-for="(item,index) in culture" :class="`${form.type == index?'select':''}`" @click="selectLabel(index)" :key="index">{{ item }}</span>
                    </el-row>
                    <el-skeleton v-if="loading" :rows="1" animated />
                </div>
                <p class="screen_read">
                    <span @click="order('timedown')" class="select el-icon-sort-down">时间降序</span>
                    <span @click="order('timeup')" class="select el-icon-sort-up">时间升序</span>
                    <span @click="order('click')" class="select">文章热度</span>
                    <el-input @input="changKeyword" v-model="form.title" placeholder="请输入材料标题" size="small" clearable></el-input>
                </p>
                <div v-if="form.type == 1 || form.type == 3 || form.type == 4">
                    <div class="box" :class="{ 'shrink': isShrink }" @click="toggleShrink">
                    
                        <div v-if="!isShrink" class="box_list">
                            <div style="display: inline-block;width: 100%;line-height: 40px;">
                                <span class="box_list_num table_title" >序号</span>
                                
                                <span class="box_list_title table_title">标题</span>
                                
                                <span class="box_list_click table_title" style="float: right;">{{ form.type == 3?'歌手':'浏览量' }}</span>
                            </div>
                        </div>
                        
                        <div @click="selectArticl(index)" v-for="(item,index) in row" :key="index" :class="`box_list ${isShrink && index == select?'box_list_select':''}`">
                            
                            <div v-if="isShrink" class="box_list_one">
                                <div style="float: left;">
                                    <span class="box_list_one_title">{{ item.title }}</span>
                                <div class="box_list_one_time"><span style="margin-right: 10px;">{{ culture[form.type] }}</span> <span v-if="form.type != 3">浏览量: {{ item.hits }}</span> <span v-if="form.type == 3">歌手: {{ item.singer }}</span></div>
                                </div>
                                <!-- <img style="width: 92px;border-radius: 5px;" v-if="item.image" :src="item.image" />
                                <img style="width: 92px;border-radius: 5px;" v-if="!item.image" src="../../../static/image/image_none.png" /> -->
                            </div>
                            <div v-if="!isShrink" style="display: inline-block;width: 100%;">
                                <span class="box_list_num">{{ index+1 }}</span>
                                <span class="box_list_title">{{ item.title }}</span>
                                <span v-if="form.type != 3" class="box_list_click">{{ item.hits }}</span>
                                <span v-if="form.type == 3" :class="`box_list_click ${form.type == 3?'music_sin':''}`">{{ item.singer }}</span>
                            </div>
                        </div>
                    </div>
                    <transition name="fade">
                        <div v-if="isShown" class="content_right">
                            <h3>{{ select_data.title }}</h3>
                            <!-- <el-button :type="button_type" :loading="button_loading" @click="Coll" size="mini" icon="el-icon-star-off">收藏</el-button> -->
                            <el-divider content-position="right">{{ select_data.createtime }}</el-divider>
                            <video v-if="form.type == 3" controls :src="select_data.m_url"></video>

                            <div v-html="select_data.content"></div>
                            <a style="display: inline-block;width: 100%;color: #409EFF;" v-if="form.type == 1 && select_data.fileurl" :href="select_data.fileurl" target="_blank" download><el-button type="primary" size="small" plain>文件：{{ select_data.filename }}</el-button></a>
                        </div>
                        
                    </transition>
                </div>
                <div class="video" v-if="form.type == 2">
                    <el-col class="video_list" v-for="(item,index) in row"  :key="index">
                        <el-card shadow="hover">
                            <el-image @click="play(index)" :src="item.image" :fit="fit"></el-image>
                            <span >{{ item.title }}</span>
                        </el-card>
                    </el-col>
                </div>

                <div v-if="form.type == 5" class="recreation">   
                    <div v-for="(item,index) in row" :key="index" class="recreation_box">
                        <img src="../../../static/image/tnsw.png">
                        <div class="recreation_box_content">
                            {{ item.title }}
                        </div>
                        <div class="recreation_box_answer">   
                            <el-tooltip :content="item.answer" placement="bottom" effect="light">
                                <el-button>移动鼠标查看答案</el-button>
                            </el-tooltip>
                        </div>
                    </div>  
 
                </div>
                <el-dialog :title="select_data.title" width="1300px" :visible.sync="dialogVisible">
                    <video style="width: 1200px;" controls :src="select_data.w_url"></video>
                </el-dialog>
                <p style="width: 100%;display: inline-block;">
                    <el-pagination
                        v-if="total_number"
                        @size-change="changeSize"
                        @current-change="changeNumber"
                        @prev-click="prev"
                        @next-click="next"
                        :page-size="form.pageSize"
                        :total="total_number"
                        style="margin-top: 20px;">
                    </el-pagination>
                </p>
            </div>
            
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/campus";
export default {
    data(){
        return{
            button_loading:false,
            button_type:'info',
            answer_index:-1,
            show:false,
            dialogVisible:false,
            fit:'none',
            select:0,
            isShrink: false,
            isShown:false,
            form:{
                page:1,
                pageSize:20,
                type:1,
                title:'',
                order:'timedown',
            },
            loading:true,
            total_number:0,
            row:{},
            culture:[],
            select_data:{},
            userInfo:{}
        }
    },
    activated(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.get_type()
        this.get_list()
    },
    methods: {
        answer(index){
            this.index = index
            this.show = true
        },
        play(index){
            this.dialogVisible = true
            this.select_data = this.row[index]
            var params = {}
            params.id = this.select_data.id
            Http.setIn(params).then((res) => {

            })
        },
        handleClose(){

        },
        get_type(){
            Http.getCampusType().then((res) => {
     
                this.culture= res.data

            })
        },
        get_list(){
            Http.Index(this.form).then((res) => {
                if(this.form.type == 1){
                    this.$Common.reconsitution(res.data.data,'campus')
                }
                if(this.form.type == 2){
                    this.$Common.reconsitution(res.data.data,'shorts')
                    console.log(res.data.data);
                }
                if(this.form.type == 3){
                    this.$Common.reconsitution(res.data.data,'music')
                }
                if(this.form.type == 4){
                    this.$Common.reconsitution(res.data.data,'read')
                }
                this.loading = false
                this.row= res.data.data
                this.total_number= res.data.total_number
            })
        },
        // 选择类别
        selectLabel(index){
            this.form.type = index
            this.form.order = 'timedown'
            this.form.page = 1
            this.select = 0
            this.get_list()
        },
        toggleShrink() {
            if(this.isShrink){
                return false
            }
            this.isShrink = !this.isShrink;
            setTimeout(()=>{
                this.isShown = true
            },300)
        },  
        selectArticl(index){
            this.select = index
            this.button_type = this.row[index].collection?'primary':'info'
            this.select_data = this.row[index]
        },
        // 排序
        order(value){
            this.form.order = value
            this.get_list()
        },

        // 上一页
        prev(e){
            this.form.page = e
            this.get_list()
        },

        // 下一页
        next(e){
    
            this.form.page = e
            this.get_list()
        },

        // 点击页码
        changeNumber(e){
            this.form.page = e
            this.get_list()
        },

        // 前往指定页数
        changeSize(e){
            this.form.page = e
            this.get_list()
        },
        // 搜索
        changKeyword(value){
            console.log(value)
            this.form.title = value
            this.get_list();
        },
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.select_data.id
            params.tabel_type = 'wiki'
            params.title = this.select_data.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
            });
        },
    }
}
</script>
<style scoped>

._page_content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
}
._page_content .screen{
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
    margin-bottom: 20px;
}
._page_content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
._page_content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.box {
    width: 100%;
    transition: width 0.3s ease;
    float: left;
    background: #ffffff;
    border-radius: 5px;
}

.shrink {

    width: 318px !important;
    padding: 10px;
    box-sizing: border-box;
}
.content_right{
    float: right;
    width: 870px;
    min-height: 939px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    text-align: left;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.screen_read {
    text-align: left;
}
.screen_read span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;

    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.screen_read >>> .el-input{
    
    width: 400px;
    float: right;
}
.box_list{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 15px;
    color: #282828;
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
}
.box_list span{
    float: left;

}
.box_list_num{
    width: 50px;
    margin-right: 10px;
    line-height: 25px;
    font-size: 15px;
}
.box_list_title{
    width: 880px;
    margin-right: 10px;
    font-size: 15px;
}
.box_list_click{
    width: 80px;
    margin-right: 10px;
    float: right !important;
    line-height: 25px;
    font-size: 15px;
}
.box_list_time{
    width: 150px;
    float: right !important;
    text-align: right;
    color: #999999;
    line-height: 25px;
    font-size: 15px;
}
.box_list_one{
    width: 268px;
    display: inline-block;
}
.box_list_one_title{
    width: 100%;font-size: 15px;color: #282828;display: inline-block;
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 2;  
    overflow: hidden; 
}
.box_list_one_time{
    width: 100%;
    font-size: 12px;
    color: #999999;
    margin-top: 8px;
    display: inline-block;
    line-height: 15px;
}
.box_list_select{
    background: #ecf5ff;
    border: 1px #409eff solid;
    border-radius: 5px;
}
._page_content h3{
    text-align: left;
}
.table_title{
    font-size: 15px !important;
    font-weight: 700;
    color: #000000 !important;
    line-height: 40px !important;
}

.video{
    display: flex;
    flex-wrap: wrap;
}
.video >>> .el-card__body{
    padding: 0;
}
.video_list{
    width: calc(20% - 10px); 
    margin: 5px;
    text-align: center;
    box-sizing: border-box;
    height: 300px;
}
.video_list >>> .el-image__inner{
    height: 200px;
}
.video_list span{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.video_list >>> .el-card{
    height: 280px;
    cursor: pointer;
}
.music_sin{
    width: 200px !important;
}
.recreation{
    width: 100%;
    display: flex;  
    flex-wrap: wrap;  
    justify-content: flex-start;
    padding: 0;
    
}
.recreation_box {  
    flex: 0 0 calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 10px; 
    background-color: #ffffff;
    box-sizing: border-box;
    text-align: left;
    border-radius: 5px;
}
.recreation_box img{
    float: left;
    margin: 20px 20px 20px 30px;
}
.recreation_box_content {
    float: left;
    height: 50px;
    margin-top: 20px;
    text-align: left;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
}
.recreation_box_answer{
    font-size: 14px;
    height: 50px;
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    line-height: 50px;
    cursor: pointer;

    color: #409EFF;
}
.transition-box{
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    width: 30%;
    text-align: center;
}
</style>