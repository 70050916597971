<template>
    <div class="message_page">
        <layout>
            <div class="message_body">

                <el-row v-for="(item,index) in list" :key="index" style="margin-bottom: 15px;" :gutter="24">
                    <el-col :span="24">
                        <el-card shadow="hover">
                            <span>{{ item.body }}</span>
                            <span style="color: #999999;">{{ item.createtime }}</span>
                        </el-card>
                    </el-col>
                </el-row>
                <el-pagination
                    v-if="total_number > form.pageSize"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="form.pageSize"
                    :total="total_number">
                </el-pagination>
            </div>
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/teach";
export default {
    data(){
        return{
            form:{
                page:1,
                pageSize:20
            },
            list:[],
            total_number:0
        }
    },
    activated(){
        this.getList()
    },
    methods:{
        getList(){
            Http.tidingsList(this.form).then((res) => {
                this.list = res.data.data
                this.total_number = res.data.count
            });
        },
        // 上一页

        prev(e){
            this.form.page = e
            this.getList()
        },

        // 下一页
        next(e){
    
            this.form.page = e
            this.getList()
        },

        // 点击页码
        changeNumber(e){
            this.form.page = e
            this.getList()
        },

        // 前往指定页数
        changeSize(e){
            this.form.page = e
            this.getList()
        },
        // 搜索班级
        changKeyword(value){
            this.form.keyword = value
            this.getList();
        },
    }
}
</script>
<style scoped>
.message_body{
    width: 100%;
    min-height: 40vw;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 6px;
    text-align: right;
    position: relative;
}
.message_body span{
    width: 100%;
    display: inline-block;
    line-height: 28px;
}
.message_body >>> .el-card{
    text-align: left;
    font-size: 14px;
}
.message_body >>> .el-card__body{
    padding: 10px !important;
}
.message_state{
    position: absolute;
    width: 50px !important;
    right: 22px;
    margin-top: -15px;
    
}
</style>