<template>
    <div class="page">
        <layout>
            <div class="map_page">
                <div class="top_label">
                    <span class="lable_name">学习计划</span>

                    <el-button size="small" @click="createMap" type="primary" class="el-icon-plus"> 创建学习计划</el-button>
                </div>
                <el-empty v-if="!list.length" description="还未创建学习计划"></el-empty>
                <el-col v-for="(item,index) in list" :key="index" :span="24">
                    <el-card shadow="hover">
                        <span class="ontime name el-icon-tickets"> 学习计划{{ index+1 }} ( {{ item.state_text }} )</span>
                        <span class="body el-icon-monitor"> 计划内容: {{ item.starttime_text }} 到 {{ item.endtime_text }}每天完成 {{ item.read_number }} 篇阅读在线、 {{ item.specialty_number }} 篇应试英语</span>
                        <span class="createtime el-icon-date"> 创建时间: {{ item.createtime }}</span>
                        <el-button @click="mapdetail(item.id)" size="small" class="mapdetail" type="primary">计划详情</el-button>
                    </el-card>
                </el-col>
            </div>
            <el-dialog
                title="创建学习计划"
                :visible.sync="dialogVisible"
                width="30%"
            >
            <form :model="form_data">
                    <div class="top_label">
                        <span class="lable_name">选择类型</span>
                    </div>
                    <div class="top_label_type">
                        <div>
                            <el-button size="small" type="primary">阅读在线</el-button>
                            <el-input size="small" v-model="form_data.read_number" placeholder="输入篇数"></el-input>
                            <div style="clear: both;"></div>
                            <el-checkbox-group v-model="form_data.read_type" >
                                <el-checkbox v-for="(city,index) in cities" :label="index" :key="city">{{city}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
            
                    <div class="top_label_type">
                        <div>
                            <el-button size="small" type="primary">应试英语</el-button>
                            <el-input size="small" v-model="form_data.specialty_number" placeholder="输入篇数"></el-input>
                            <div style="clear: both;"></div>
                            <el-checkbox-group v-model="form_data.specialty_type" >
                                <el-checkbox v-for="(city,index) in cispace_data" :label="index" :key="index">{{city}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    
                    <div class="top_label_type" style="margin-bottom: 20px;">
                        <div>
                            <el-button size="small" type="primary">计划周期</el-button>
                            <el-date-picker
                                v-model="form_data.time"
                                type="daterange"
                                align="right"
                                size="small"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>

                </form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitMap">确 定</el-button>
                </span>
            </el-dialog>
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/work";
import Student from "../../apis/student";
export default {
    data(){
        return{
            dialogVisible:false,
            form_data:{
                read_type:[],
                read_number:'',
                specialty_type:[],
                specialty_number:''
            },
            cispace_data: [],
            list:[],
            cities: {1:'一级选文', 2:'二级选文', 3:'三级选文', 4:'四级选文',5:'五级选文',6:'六级选文',7:'七级选文'},
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    activated(){
        this.getCollection_()
        this.getData()
    },
    methods:{
        getData(){
            Student.mapList().then((res) => {
                this.list = res.data
            });
        },
        // 获取应试题型
        getCollection_(){
            Http.getCollection().then((res) => {
                delete res.data[0];
                this.cispace_data = res.data
            });
        },
        mapdetail(id){

            this.$router.push({name:'StudentMapDetail',query:{id:id}})
        },

        createMap(){
            this.dialogVisible = true
        },

        submitMap(){
            var params = JSON.parse(JSON.stringify(this.form_data))
            
            if (!params.hasOwnProperty('time')) {  
                this.$message({
                    message: '请选择计划周期',
                    type: 'error'
                });
                return false
            }

            if (!params.hasOwnProperty('read_number') && !params.hasOwnProperty('specialty_number')) {  
                this.$message({
                    message: '请任意选择一种类型作业',
                    type: 'error'
                });
                return false
            }
            params['starttime'] = this.$Common.getTime(params.time[0])
            params['endtime'] = this.$Common.getTime(params.time[1])
            //console.log(params);return
            Student.mapCreate(params).then((res) => {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                if(res.code){
                    this.dialogVisible = false
                    this.getData()
                }
            });
        }

    }
}
</script>
<style scoped>
.map_page >>> .el-card{
    position: relative;
}
.map_page >>> .el-col{
    text-align: left;
    margin-bottom: 15px;
}
.map_page >>> .mapdetail{
    position: absolute;
    right: 30px;
    bottom: 38px;
    z-index: 99;
}
.map_page .name{
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 18px;
}
.map_page .body{
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;
}
.map_page .createtime{
    width: 100%;
    font-size: 14px;
    opacity: 0.7;
}
.map_page .ontime{
    color: #f56c6c;
}
.map_page .outtime{
    color: #409EFF;
}
.top_label{
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
}
.top_label >>> .el-button{
    float: right;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.top_label_type div{
    text-align: left;
}
.top_label_type div >>> .el-button{
    float: left;
}
.top_label_type{
    width: 100%;
    margin-bottom: 50px;
    line-height: 30px;
    position: relative;
}
.top_label_type >>> .el-input{
    width: 12vw;
    position: absolute;
    margin-left: 10px;
    margin-right: 15px;
}
.top_label_type >>> .el-checkbox-group{
    
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.top_label_type >>> .el-checkbox{
    width: calc(100%/4);
    text-align: left;
}
.top_label_type >>> .el-date-editor{
    float: left;
    margin-left: 15px;
}
.top_label_type >>> .el-radio-group{
    margin-left: 15px;
}
.top_label_type >>> .el-tag{
    margin-right: 12px;
}
</style>