import axios from '../services/request'
/* 统计总成绩 */
export const Index = (data = {}) => axios.get('api/teachgrades/index',data);

/* 统计总成绩 - 下方学生列表*/
export const Student = (data = {}) => axios.get('api/teachgrades/student',data);

/* 统计周期、临时作业成绩 */
export const Cycle = (data = {}) => axios.get('api/teachgrades/cycle',data);

/* 自主学习 */
export const selfStudy = (data = {}) => axios.get('api/teachgrades/selfStudy',data);



export default{
    Index,
    Student,
    Cycle,
    selfStudy
}