<template>
    <!-- 文章类 -->
    <div>
        <div class="page-header">
            <el-page-header @back="goBack" :content="typetext"></el-page-header>
        </div>
        <div class="content">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span style="font-weight: 700;">{{ specialty.title }}</span>
                    
                    <el-button v-if="userInfo && userInfo.type == 1" size="mini" type="primary">布置作业</el-button>
                    <!-- <el-button :type="button_type" :loading="button_loading" @click="Coll" icon="el-icon-star-off" size="mini" circle></el-button> -->
                </div>
                <div class="question_box">
                    <div class="question_left" v-if="isNotEmptyObject(specialty)">
                        <el-tabs v-model="specialtyname">
                            <el-tab-pane :label="typetext" name="first">
                                <div style="text-align: left;font-size: 14px;" v-html="specialty.body"></div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
          
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import Http from "../../src/apis/specialty";
export default {
    props: {  
        form_data: {  
            type: Object,
        },
        userInfo:{
            type: Object,
        }
    },
    data(){
        return{
            button_loading:false,
            button_type:'info',
            specialtyname:'first',
            testname:'first',
            isPlaying: false,  
            specialty:{},
            specialtytest:[],
            typetext:'',
            currentTime: 0,
            duration: 0,
            progress: 0,
        }
    },
    mounted() {  
  
    },
    computed: {  
        isNotEmptyObject() {  
            // 返回一个函数，用于在模板中作为方法调用  
            return function(obj) {  
                return Object.keys(obj).length > 0;  
            };  
        }
        
    },  
    mounted(){
        window.addEventListener('popstate', this.handlePopState);  
    },
    methods:{
        handlePopState(){
            this.specialty = {}
            this.specialtytest = []
        },

        getData(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            var params = {}
            params.id = this.form_data.id
            Http.Detail(params).then((res) => {
                this.button_type = res.data.collection?'primary':'info'
                this.specialty = res.data.specialty
                this.specialtytest = res.data.specialty_test
                this.typetext = res.data.typetext
                loading.close()
            });

        },
        // 收藏
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.form_data.id
            params.tabel_type = 'specialty'
            params.title = this.specialty.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
        
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                
            });
        },
        goBack(){
            window.history.back();
        }
    }
}
</script>
<style scoped>
.content{
    width: 1200px;
    margin: 0 auto;
}
.content >>> .el-card__header{
    display: inline-block;
    width: 100%;
}
.clearfix >>> .el-button{
    float: right;
    margin-left: 15px;
}
.clearfix span{
    float: left;
}
.question_box{

    width: 100%;
    height: 800px;
}
.question_left{
    width: 100%;
    float: left;
    height: 800px;
    overflow-y: auto;
    box-sizing: border-box;
}
.question_left::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_left::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_left::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_left::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_left >>> .el-tabs__content{

    padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
}
.right{
    background: #e9f7ff !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.error{
    background: #ffd7d7 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.specialtytest{
    width: 100% !important;
}
.progress-bar {  
  width: 100%;  
  background-color: #f3f3f3;  
  border-radius: 10px;  
  overflow: hidden;  
}  
  
.progress-fill {  
    height: 20px;  
    background-color: #409eff;  

    text-align: center; /* 通常不需要在进度条上显示文本 */  
    line-height: 20px; /* 通常不需要在进度条上显示文本 */  
    color: white; /* 通常不需要在进度条上显示文本 */  
    width: 0%; /* 初始宽度为0 */  
    border-radius: 10px; /* 圆角只在左边 */  
    transition: width 0.2s ease; /* 添加过渡效果 */  
}
.page-header{
    width: 1200px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.page-header >>> .el-page-header{
    line-height: 40px;
    padding: 5px 15px;
}
.page-header >>> .el-page-header__content{
    font-size: 15px;
}
</style>