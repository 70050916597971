<template>
  <div class="fullscreen-video-container">
    <!-- <video autoplay muted loop class="fullscreen-video" src="../../static/video/1.mp4">
    </video> -->
    <video :style="`height: ${screenHeight};`" ref="videoPlayer" width="600" class="fullscreen-video" :controls="false" muted loop @ended="restartVideo">
      <source src="../../static/video/1.mp4" type="video/mp4">
    </video>

    <div v-if="login_form == 1" class="login_form">

      <el-form :model="LoginForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
        <span class="login_title">欢迎登录</span>
        <el-input placeholder="请输入账号或手机号" @keyup.enter.native="submitFormLogin" prefix-icon="el-icon-user" type="text" v-model="LoginForm.account"></el-input>
        <el-input placeholder="请输入密码" @keyup.enter.native="submitFormLogin" prefix-icon="el-icon-lock" type="password"
          v-model="LoginForm.password"></el-input>
        <el-button size="small" @click="submitFormLogin" :loading="loading" type="primary">登录</el-button>
        <el-button @click="checkLable(2)" size="small">注册</el-button>
        <el-checkbox v-model="checked">我已阅读并同意<a>《用户服务协议》</a>,<a>《隐私协议》</a></el-checkbox>
        <el-button @click="checkLable(3)" type="text">忘记密码？</el-button>
      </el-form>

    </div>

    <div v-if="login_form == 2" class="login_form register_form">
      <el-form ref="registerForm_" :model="registerForm" label-width="100px" class="demo-ruleForm">
        <span class="login_title">账号注册</span>
        <p style="text-align: left;">
          <el-radio-group v-model="registerForm.type">
            <el-radio size="medium" border :label="0">学生</el-radio>
            <el-radio size="medium" :label="1" border>老师</el-radio>
            <el-radio size="medium" :label="3" border>游客</el-radio>
          </el-radio-group>
        </p>
        <el-input placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone" type="text" v-model="registerForm.mobile"></el-input>
        <el-autocomplete v-if="registerForm.type != 3" prefix-icon="el-icon-school" @input="changeSchool" v-model="registerForm.school_name" :fetch-suggestions="querySearchAsync" placeholder="请输入关键词搜索学校" @select="handleSelect"></el-autocomplete>
        <el-input v-if="registerForm.type != 3" placeholder="请输入学号/工号" prefix-icon="el-icon-user" type="text" v-model="registerForm.stuid"></el-input>
        <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" type="password" v-model="registerForm.password"></el-input>
        <el-input placeholder="请再次输入密码" prefix-icon="el-icon-lock" type="password" v-model="registerForm.newpassword"></el-input>

        <p class="captcha">
          <el-input placeholder="请输入验证码" prefix-icon="el-icon-key" type="text" v-model="registerForm.code"></el-input>
          <el-button :loading="sendLoading" @click="sendCaptcha(0)" type="primary">{{ captcha_text }}</el-button>
        </p>
        <el-button size="small" @click="submitFormRegister" type="primary">注 册</el-button>
        <el-button @click="checkLable(1)" type="text">有账号？去登录</el-button>

      </el-form>

    </div>
    <div v-if="login_form == 3" class="login_form">
      <el-form :model="resetForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
        <span class="login_title">找回密码</span>
        <el-input placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone" type="text"
          v-model="resetForm.mobile"></el-input>
        <!-- <el-input placeholder="请输入学号" prefix-icon="el-icon-user" type="text" v-model="resetForm.stuid"></el-input> -->
        <el-input placeholder="请输入新密码" prefix-icon="el-icon-lock" type="password"
          v-model="resetForm.newpassword"></el-input>

        <p class="captcha">
          <el-input placeholder="请输入验证码" prefix-icon="el-icon-key" type="text" v-model="resetForm.captcha"></el-input>
          <el-button :loading="sendLoading" @click="sendCaptcha(1)" type="primary">{{ captcha_text }}</el-button>
        </p>
        <el-button size="small" @click="resetFormSubmit" type="primary">确 认</el-button>

        <el-button @click="checkLable(1)" type="text">返回登录</el-button>
      </el-form>

    </div>
    <img class="zxrt" src="../../static/image/zxrt.png" />
    <img class="logo" style="cursor: pointer;" @click="IndexPage" src="../../static/image/logo-min.png" />
  </div>
</template>

<script>
import Http from "../apis/user";
export default {
  name: 'LoginPage',
  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      sendLoading:false,
      LoginForm: {
        account: '18628952895',
        password: '123321'
      },
      registerForm: {
        stuid:'',
        mobile: '18628952895',
        password: '',
        newpassword: '',
        school_id:'',
        school_name:'',
        type: 0,
      },
      resetForm: {
        mobile: '18628952895',
        stuid:'',
        newpassword: '',
        captcha: ''
      },
      captcha_text:'发送验证码',
      checked: true,
      login_form: 1,
      
      time:60,
      time_boj:{},
      redirect:'',
      screenWidth:'',
      screenHeight:'',
      currentTime:0,
      restaurants:[]
    }
  },
  activated(){
    
    setTimeout(()=>{
      this.playPause()
    },300)
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {  
    
    this.$refs.videoPlayer.removeEventListener('timeupdate', this.updateTime);
  },  
  mounted() {
    window.addEventListener('popstate', this.handlePopState);  

    this.redirect = this.$route.query.redirect

    this.$refs.videoPlayer.addEventListener('timeupdate', this.updateTime);

    //this.restaurants = this.loadAll();
  },
  methods: {
    checkEnter(){
      console.log(1)
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 1000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      this.registerForm.school_name = item.value
      this.registerForm.school_id = item.id
    },
    changeSchool(e){
      var params = {}
      params.keyword = e
      Http.Serach(params).then((res) => {
        this.restaurants = res.data
      });
    },

    handlePopState(){
      document.body.style.overflow = 'auto';
    },

    checkLable(type) {
      this.login_form = type
    },

    // 登录提交
    submitFormLogin() {
      this.loading = true
      var login_data = this.LoginForm
      if (!login_data.account) {
        this.$message.error('请填写账号');
        return false;
      }
      if (!login_data.password) {
        this.$message.error('请填写密码');
        return false;
      }
      Http.Login(this.LoginForm).then((res) => {
        this.loading = false
        this.$message({
          showClose: true,
          message: res.msg,
          type: res.code ? 'success' : 'error'
        });
        if (res.code) {
          window.sessionStorage.setItem("token", res.data.userinfo.token);
          window.sessionStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));

          if(this.redirect){

            window.location.href = decodeURIComponent(this.redirect);
            return false;
          }

          if(res.data.userinfo.type == 0){
            this.$router.push({ name: 'StudentPlan' });
          }else{
            this.$router.push({ name: 'Plan' });
          }

        }

      });
    },
    IndexPage(){
      this.$router.push({ name: 'Index' });
    },

    // 重置密码

    resetFormSubmit(){
      Http.Resetpwd(this.resetForm).then((res) => {
        this.$message({
          showClose: true,
          message: res.msg,
          type: res.code ? 'success' : 'error'
        });
        if(res.code){
          this.login_form = 1
        }
      });

    },



    // 发送验证码

    sendCaptcha(type) {
      this.sendLoading = true
      var params = {}
      if(type == 0){
        params.mobile = this.registerForm.mobile
        params.event = 'register'
      }else{
        params.mobile = this.resetForm.mobile
        params.event = 'resetpwd'
      }
      Http.Sms(params).then((res) => {
        this.sendLoading = false
        this.$message({
          showClose: true,
          message: res.msg,
          type: res.code ? 'success' : 'error'
        });
        if(res.code){
          this.downTime()
        }

      });

    },


    downTime(){
      this.time_boj = setInterval(()=>{
  
        if(this.time == 0){
            this.time = 60
            this.captcha_text = '发送验证码'
            clearInterval(this.time_boj)
            return false;
        }

        this.time -= 1;
        this.captcha_text = `${this.time} S`

      },1000)
    },

    // 注册
    submitFormRegister() {

      Http.Register(this.registerForm).then((res) => {
        this.$message({
          showClose: true,
          message: res.msg,
          type: res.code ? 'success' : 'error'
        });
        if(res.code){
          this.login_form = 1
        }
      });
    },
    playPause() {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play();
        this.playing = true;
      } else {
        this.$refs.videoPlayer.pause();
        this.playing = false;
      }
    },
    updateTime() {
      this.currentTime = Math.floor(this.$refs.videoPlayer.currentTime);
    },
    restartVideo() {
      this.$refs.videoPlayer.currentTime = 0; // 将视频回到起始位置
      this.$refs.videoPlayer.play(); // 重新开始播放
    }

  }
}
</script>

<style lang="less">
@import url('../../static/css/login.less');
.fullscreen-video-container {
  position: relative;
  top: 0;
  left: 0;
}

.fullscreen-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  /* 保持视频的宽高比 */
}

.captcha {
  margin-top: 0;
}
</style>

<style scoped>

.login_form>>>.el-input {

  margin-bottom: 20px;
  height: 40px;
}

.login_form>>>.el-checkbox__label {
  font-size: 12px;
  padding-left: 5px;
}

.login_form>>>.el-button--primary {

  width: 100%;
  margin-top: 20px;
}

.login_form>>>.el-button {
  width: 100%;
  margin-top: 20px;
  margin-left: 0;
  height: 2.5rem;
  font-size: 1rem;
}

.login_form>>>.el-checkbox {
  margin-top: 1rem;
}

.register_form>>>.el-radio {
  margin-right: 0;
}

.captcha>>>.el-input {
  width: 50%;
  float: left;
}

.captcha>>>.el-button {
  width: 40%;
  float: right;
  margin-top: 0;
  text-align: center;
}
.register_form >>> .el-autocomplete{
  width: 100%;
}
</style>