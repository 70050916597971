<template>
    <div>
        <div class="page-header">
            <el-page-header @back="goBack" :content="typetext"></el-page-header>
        </div>
        <!-- 完型填空 -->
        <div class="content">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span style="font-weight: 700;">{{ specialty.title }}</span>
                    
                    <el-button v-if="userInfo && userInfo.type == 1" @click="addWork" size="mini" type="primary">布置作业</el-button>
                    <el-button :type="button_type" :loading="button_loading" @click="Coll" icon="el-icon-star-off" size="mini" circle></el-button>
                </div>
                <div class="question_box">
                    <div class="question_left" v-if="isNotEmptyObject(specialty)">
                        <el-tabs v-model="specialtyname">
                            <el-tab-pane :label="typetext" name="first">
                                <form id="my-form">
                                    <div v-if="isNotEmptyObject(specialty)" v-html="specialty.body" ></div>
                                        
                                    <button v-if="!submitanswers" @click="submitForm_" class="submit" type="submit">提交答案</button>
                                </form>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div v-if="loading" class="question_right">
                        <el-tabs v-model="testname">
                            <el-tab-pane label="答案解析" name="first">
                                
                                <el-empty v-if="!submitanswers" description="请先做题"></el-empty>
                                <div v-if="submitanswers">
                                    <div class="first_question" v-for="(item,index) in specialty.answer" :key="index" style="padding: 10px;">
                                        <span class="question">{{ index+1 }}. {{ item }}</span>

                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-card>
        </div>
        
        <Arrangement :dialogVisible="dialogVisible" @success="back_success" @update:dialogVisible="updatedialogVisible" :wid="specialty.id" table="specialty_type"></Arrangement>
    </div>
</template>
<script>
import Http from "../../src/apis/specialty";
import arrangement from "../../untils/arrangement.vue";
import $ from 'jquery';  
export default {
    components:{
        'Arrangement':arrangement
    },
    props: {  
        form_data: {  
            type: Object,
        },
        userInfo:{
            type: Object,
        }
    },
    data(){
        return{
            button_loading:false,
            button_type:'info',
            loading:false,
            specialtyname:'first',
            testname:'first',
            id:'',
            specialty:{},
            specialtytest:[],
            score:0,
            submitanswers:[],
            options:{},
            task_id:0,
            plan_id:0,
            periods:'',
            in_time:'',
            score:0,
            decipher:[],
            typetext:'',
            inputs: [],
            dialogVisible:false

        }
    },
    computed: {  
        isNotEmptyObject() {  
            // 返回一个函数，用于在模板中作为方法调用  
            return function(obj) {  
                return Object.keys(obj).length > 0;  
            };  
        }, 
        
    },  
    mounted(){
        window.addEventListener('popstate', this.handlePopState);  
    },

    methods:{
        addWork(){
            this.dialogVisible = true
        },
        updatedialogVisible(e){
            this.dialogVisible = e
        },
        back_success(){
            this.dialogVisible = false
        },
        handlePopState(){
            this.specialty = {}
            this.specialtytest = []
            this.loading = false
        },
        submitForm_(e){
            e.preventDefault();
            var formValues = [];
            $('#my-form input[name="answer[]"]').each(function() {  
                formValues.push($(this).val());  
            }); 

            var params = {}
            params.body = formValues
            params.w_id = this.form_data.id
            params.table_type = 'specialty'
            params.source = 'PC'
            params.in_time = this.in_time
            params.task_id = this.task_id
            params.plan_id = this.plan_id
            params.periods = this.periods
            params.worktype = this.form_data.worktype
            Http.Submit(params).then((res) => {
                if(res.code){
                    this.score = res.data.score * 100
                    this.$alert(res.msg, '提示', {
                        confirmButtonText: '确定'
                    });
                    this.getData()
                }else{
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    });
                }
            });
            
        },
       
        handleSubmit() {  
            // 这里你可以访问 this.inputs，它包含了所有输入的值  
            console.log(this.inputs);
            // 然后你可以根据需要发送这些数据到服务器或进行其他处理  
        }, 
        getData(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            var params = {}
            params.id = this.form_data.id
            Http.Detail(params).then((res) => {
                res.data.specialty.id = res.data.specialty.id.toString()
                this.specialty = res.data.specialty
                this.specialtytest = res.data.specialty_test

                var submitanswers = res.data.submitanswers

                if(submitanswers){
                    var inputs = []
                    for(var index in submitanswers){
                        inputs.push(submitanswers[index])
                    }
                    this.inputs = inputs
                }
                this.button_type = res.data.collection?'primary':'info'
                this.submitanswers = res.data.submitanswers
                this.in_time = res.data.time
                this.decipher = res.data.decipher
                this.score = res.data.score * 100
                this.typetext = res.data.typetext
                this.loading = true
                loading.close()
                
            });

        },
       /**
        *   选择题
        */
        optionsQuestion(id,option){
            var params = JSON.parse(JSON.stringify(this.options))
            params[id] = option.name

            this.options = params
        },
        // 收藏
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.form_data.id
            params.tabel_type = 'specialty'
            params.title = this.specialty.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
      
                    
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                
            });
        },
        goBack(){
            window.history.back();
        },
    }
}
</script>
<style scoped>
.content{
    width: 1200px;
    margin: 0 auto;
}
.content >>> .el-card__header{
    display: inline-block;
    width: 100%;
}
.clearfix >>> .el-button{
    float: right;
    margin-left: 15px;
}
.clearfix span{
    float: left;
}
.question_box{

    width: 100%;
    height: 800px;
}
.question_left{
    width: 50%;
    float: left;
    height: 800px;
    overflow-y: auto;
    box-sizing: border-box;
}
.question_left::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_left::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_left::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_left::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_left >>> .el-tabs__content{

    padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
}
.right{
    background: #75A3E4 !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.error{
    background: #FF6E6E !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.specialtytest{
    width: 100% !important;
}
.question_left form{

    text-align: center;
    font-size: 14px;
}
.question_left form div{
    text-align: left;
    line-height: 40px;
    margin-bottom: 10px;
}

.submit{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #409eff;
    border: 1px solid #409eff;
    color: #ffffff;

    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 40px;
}
.page-header{
    width: 1200px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.page-header >>> .el-page-header{
    line-height: 40px;
    padding: 5px 15px;
}
.page-header >>> .el-page-header__content{
    font-size: 15px;
}
</style>