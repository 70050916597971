<template>
    <div class="page">
        <layout>
            <div class="main_body">

                <div class="top_label">
                    <span class="lable_name">

                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: 'StudentWork' }">作业管理</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ plan_name }}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </span>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="周期作业" name="first">
 
                    </el-tab-pane>
                    <el-tab-pane label="临时作业" name="second">

                    </el-tab-pane>
                    <el-empty v-if="!list.length" description="暂无作业"></el-empty>
                    <el-row v-for="(item,index) in list" :key="index" :gutter="24">
                        <el-col :span="24">
                            <el-card shadow="hover">
                                <div class="left_worrk">
                                    <p>
                                        <span class="number">{{ index + 1 }}</span>
                                        <span class="title">{{ item.title }}</span>
                                    </p>
                                    <p>作业内容：阅读在线 {{ item.read_number?item.read_number:0 }} 篇 / 应试英语 {{ item.specialty_number?item.specialty_number:0 }} 篇</p>
                                    <p v-if="form.type == 0">作业周期：{{ item.period_text }}</p>
                                    <p>布置时间：{{ item.createtime_text }}</p>
                                    <p>起止时间：{{ item.start_time_text }} 至 {{ item.end_time_text }}</p>
                                    
                                </div>
                                <div class="right_work">
                                    
                                    <el-button size="small" style="margin-bottom: 10px;" @click="viewWorkDetail_(item.id,item.period_text)" type="primary">查看作业</el-button><br>
                            
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-tabs>
                <div class="work_dialog_box">
                    <Student-work ref="studentwork"></Student-work>

                    <Student-temporary ref="studenttemporary"></Student-temporary>
                </div>
            </div>
                
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/student";
import studentwork from "../../../untils/studentwork.vue";
import studenttemporary from "../../../untils/studenttemporary.vue";
export default {
    components:{
        'Student-work':studentwork,
        'Student-temporary':studenttemporary
    },
    data() {
        return {
            activeName: 'first',
            dialogFormVisible:false,
            plan_name:'',
            tableData: [],
            form:{
                plan_id:'',
                page:1,
                pageSize:20,
                type:0
            },
            list:[]
        }
    },
    activated(){
        this.form.plan_id = this.$route.query.plan_id
        this.plan_name = this.$route.query.plan_name
        this.geList(this.plan_id)
    },
    methods: {
        geList(){

            Http.Task(this.form).then((res) => {
   
                this.list = res.data
            });

        },

        // 查看作业
        // type 0 周期作业 1 系统作业
        viewWorkDetail_(task_id,period_text){

            if(this.form.type == 0){
                this.$refs.studentwork.viewWorkDetail(task_id,period_text,this.form.plan_id)
            }else{
                this.$refs.studenttemporary.viewWorkDetail(task_id,this.form.plan_id)
            }

            
        },
        handleClick(tab, event) {
            this.form.type = tab.index
            this.geList()
        }
    }
};
</script>

<style scoped>
.main_body >>> .el-empty{

    padding: 130px 0;
}
.main_body >>> .el-tabs{
    margin-top: 1vw;
}
.left_worrk{
    text-align: center;
    width: 40vw;
    position: relative;
}
.left_worrk p{
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 35px;
    font-size: 14px;
}
.left_worrk .number{
    float: left;
    width: 25px;
    height: 25px;
    background: #409eff;
    border-radius: 50px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
    margin-right: 10px;
}
.left_worrk .title{
    float: left;
    line-height: 25px;
    font-weight: 700;
}
.right_work{

    position: absolute;
    width: 10vw;
    padding: 10px;
    right: 0;
    top: 50%;
    bottom: 50%;
    margin-top: -50px;
}
.main_body >>> .el-row{
    margin-bottom: 20px;
}
.main_body >>> .el-tabs__content{
    text-align: left;
}
.work_dialog_box >>> .el-dialog__header{
    background: #409EFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
.work_dialog_box >>> .el-dialog__title{
    color: #ffffff;
}
.work_dialog_box >>> .el-icon-close{
    color: #ffffff;
}
.work_dialog_box >>> .el-dialog{
    border-radius: 8px;
}
.work_dialog_box >>> .is-disabled{

    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.work_dialog_box {

    text-align: left;
}
.work_dialog_box >>> .el-button--default{
    margin-bottom: 10px;
}

.work_dialog_box span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.work_dialog_box >>> .el-pagination{
    text-align: center;
    margin-top: 1vw;
}
</style>