<template>
    <div>
        <div class="window" v-bind:class="{ minimized: isMinimized }">
            <div class="title-bar" @click="toggleMinimize">收起</div>
            <div class="window_content" v-if="!isMinimized">
                
                <div @click="handleRowClick(item)" v-for="(item,index) in task_data" :key="index" class="window_list">

                    <span class="window_list_title">{{ index+1 }} 、{{ item.title }}</span>
                    <span class="window_list_do">{{ item.do_text }}</span>
                </div>
            </div>
        </div>
        <button v-if="isMinimized" class="restore-button el-icon-edit-outline" title="查看作业" @click="toggleMinimize"></button>
    </div>
</template>
<script>
import Student from "../../src/apis/student";
export default {
    props:{
        task_id:{
            type:String
        },
        worktype:{
            type:String
        },
        plan_id:{
            type:String
        },
        periods:{
            type:String
        },
        temptype:{
            type:String
        }
    },
    data(){
        return{
            window:'',
            isMinimized: true,
            // plan_identifying:'', //作业跳转 - 班级
            // type_identifying:0, //作业跳转 - 类型 0 周期作业 1临时作业
            // task_data:[],
            // workdetail_read_data:[],
            // workdetail_specialty_data:[],
            // task_id:'',
            task_data:[],
            spe_type:''
        }
    },
    mounted(){
        this.getWork()
    },
    methods:{
        getWork(){
            var params = {}

            params.plan_id = this.plan_id
            params.worktype = this.worktype
            params.task_id = this.task_id
            params.periods = this.periods
            Student.readDetailWork(params).then((res) => {
        
                this.task_data = res.data
            });
        },


        handleRowClick(row){
            this.spe_type = row.work_type
            var params = {}
            params.wid = row.wid
            params.task_id = this.task_id
            params.plan_id = this.plan_id
            params.worktype = this.worktype
            params.row_type = 0
            params.periods = this.periods
            params.spe_type = row.work_type
            //console.log(params)
            if(this.temptype == 'read'){
                if(row.type == 1){
                    this.$router.push({name:'SpecialtyDetail',query:{id:row.wid,task_id:this.task_id,plan_id:this.plan_id,periods:this.periods,worktype:this.worktype,type:1,dotype:0}})
                    //this.$router.push({name:'SpecialtyDetail',query:{id:row.wid,type:row.work_type}});
                }else{
                    this.$emit('call_back',params);
                }
            }else{
                if(row.type == 1){
                    this.$emit('call_back',params);
                    //this.$router.push({name:'SpecialtyDetail',query:{id:row.wid,task_id:this.task_id,plan_id:this.plan_id,periods:this.periods,worktype:this.worktype,type:1,dotype:0}})
 
                }else{
                    this.$router.push({name:'ReadDetail',query:{id:row.wid,task_id:this.task_id,plan_id:this.plan_id,periods:this.periods,worktype:this.worktype,type:0}})
                }
            }

            
            
            
                //this.$router.push({name:'SpecialtyDetail',query:{id:wid,task_id:tid,plan_id:this.plan_identifying,worktype:1,type:z_type,dotype:0}})
            
        },

        toggleMinimize() {
            
            this.isMinimized = !this.isMinimized;

            if(!this.isMinimized){
                this.getWork()
            }
            
        },
    }
}
</script>
<style scoped>
.window {
    width: 350px;
    height: 600px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: fixed;
    bottom: 200px;
    overflow: auto;
    right: 0;
}

.title-bar {
    background-color: #3498db;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: right;
}

.window_content {
    padding: 10px;
}

.minimized {
    height: 0;
    opacity: 0;
    padding: 0;
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
}

.restore-button {
    margin-top: 10px;
    padding: 15px;
    background-color: #FF6E6E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: fixed;
    right: 0;
    bottom: 200px;
    border-radius: 50px;
    font-size: 24px;
}
.window_list{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: inline-block;
    color: #000000;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    margin-bottom: 15px;
    cursor: pointer;
}
.window_list_title{
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 14px;
}
.window_list_do{
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}
.restore-button:hover {
    background-color: #75A3E4;
}


.window::-webkit-scrollbar {  
    width: 10px; /* 滚动条的宽度 */  
}  
  
.window::-webkit-scrollbar-track {  
    background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.window::-webkit-scrollbar-thumb {  
    background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
    border-radius: 10px; /* 圆角效果 */  
}  
  
.window::-webkit-scrollbar-thumb:hover {  
    background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
</style>