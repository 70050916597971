<template>
    <div class="match_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/zhishijinsai.jpg" />
            <div class="content">
                <el-page-header @back="goBack" content="比赛介绍"></el-page-header>
                <el-row style="margin-top: 15px;" :gutter="24">
                    <el-col :span="24">
                        <el-card shadow="never">
                            <div v-html="detail.body"></div>
                            <el-button :loading="loading" @click="doWork" type="primary">
                                {{ detail.status_text}}
                            </el-button>
                        </el-card>
                        
                    </el-col>
                </el-row>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/match";
export default {
    data(){
        return{
            id:'',
            detail:{},
            loading:false
        }
    },
    activated(){
        this.id = this.$route.query.id
        this.getDetail();
    },
    methods:{
        goBack(){
            window.history.back();
        },
        doWork(){
            if(this.detail.status == 4){
                this.$router.push({name:'MatchList',query:{id:this.detail.id}});
            }else if(this.detail.status == 2){
                // 报名
                this.enroll()
            }else if(this.detail.status == 5){
                // 跳转公示
            }else{
                this.$message({
                    showClose: true,
                    message: this.detail.status_text,
                    type: 'error'
                });
            }
        },
        enroll(){
            this.loading = true
            var params = {}
            params.mid = this.id
            Http.Enroll(params).then((res) => {
                this.loading = false
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code?'success':'error'
                });
            })
        },
        getDetail(){
            var params = {}
            params.id = this.id
            Http.Detail(params).then((res) => {
                this.detail = res.data
            })
        }
    }
}
</script>
<style scoped>
.banner{
    width: 100%;
}
.match_page .content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
}
</style>