<!-- 临时作业 -->
<template>
    <div>
        <el-dialog title="查看作业" :visible.sync="dialogFormVisible">

            <el-card style="margin-bottom: 20px;">
                <h4 style="color: #409EFF;"><span>阅读/应试：{{ detail.number }}</span>  <span style="margin-left: 20px;">完成情况：{{ detail.complete }}</span> <span style="margin-left: 20px;">作业时间：{{ detail.start_time }} 至 {{ detail.end_time }}</span></h4>
            </el-card>
            <el-card style="margin-bottom: 20px;">
                <h4 style="color: #409EFF;">阅读在线</h4>
                <el-table
               
                    :data="detail.read"
                    highlight-current-row
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    property="title"
                    label="材料名称">
                    </el-table-column>
                    <el-table-column
                    property="type_text"
                    label="类型"
                    width="120">
                    </el-table-column>
     
                </el-table>
            </el-card>
            <el-card >
                <h4 style="color: #409EFF;">应试英语</h4>
                <el-table
          
                    :data="detail.specialty"
                    highlight-current-row
      
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    property="title"
                    label="材料名称">
                    </el-table-column>
                    <el-table-column
                    property="type_text"
                    label="类型"
                    width="120">
                    </el-table-column>
     
                </el-table>
            </el-card>
        
        </el-dialog>
    </div>
</template>

<script>
import Http from "../src/apis/work";
export default {
    data(){
        return{
            dialogFormVisible:false,
            detail:[]
        }
    },
    
    methods:{
        viewWorkDetail(task_id,plan_id){
            var params = {}
            params.task_id = task_id
            params.plan_id = plan_id
            Http.getTemporaryWorkDetail(params).then((res) => {
                this.detail = res.data
                this.dialogFormVisible = true
            });
        }
    }
}
</script>