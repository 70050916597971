import axios from '../services/request'
/* 应试英语首页 */
export const Index = (data = {}) => axios.get('api/specialty/index',data);

/* 应试英语详情 */
export const Detail = (data = {}) => axios.get('api/specialty/detail',data);

/* 应试英语详情 */
export const Submit = (data = {}) => axios.post('api/specialty/submit',data);

/* 应试英语收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);

/* 获取相关情景对话 */
export const getTalk = (data = {}) => axios.get('api/specialty/getTalk',data);

export default{
    Index,
    Detail,
    Submit,
    Coll,
    getTalk
}