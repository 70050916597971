<template>
    <!-- 阅读、应试文章详情页面 布置作业 -->
    <div class="work_body">
        <el-dialog
            title="提示"
            :visible.sync="localDialogVisible"
            width="700px"
            :before-close="handleClose">
                <el-form ref="form" :model="form" label-width="80px">
                    
                    <el-form-item label="作业名称">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="周期">
                        <el-date-picker
                            v-model="form.time"
                            type="daterange"
                            align="right"
                            size="small"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            tooltip-effect="dark"
                            style="width: 100%"
                            @selection-change="handleSelectionChange">
                            <el-table-column
                            type="selection"
                            width="55">
                            </el-table-column>
                        
                            <el-table-column
                            prop="name"
                            label="班级"
                            >
                            </el-table-column>

                        </el-table>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="removeDia">取 消</el-button>
                    <el-button type="primary" @click="submitWork">确 定</el-button>
                </span>
            </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/read";
export default {
    props:{
        wid:{
            type: String
        },
        dialogVisible:{
            type:Boolean,
            default:false
        },
        table:{
            type:String
        }
    },
    data(){
        return{
            form:{
                title:'',
                plan:[]
            },
            localDialogVisible: this.dialogVisible,
            tableData: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            plan_form:{
                page:1,
                pageSize:50,
                state:0
            },
        }
    },
    mounted(){
        var userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        if(userInfo.type == 1){
            this.getPlan_()
        }
        
    },
    watch: {
        // 当 parent 传入的新 dialogVisible 值时，更新 local 状态
        dialogVisible(newVal) {
            this.localDialogVisible = newVal;
        }
    },
    methods:{
        removeDia(){
            this.dialogVisible = false
        },
        handleClose(){
            
            this.localDialogVisible = false; // 关闭对话框
            this.$emit('update:dialogVisible', false); // 通知父组件
     
        },
        submitWork(){
            var params = JSON.parse(JSON.stringify(this.form))
            
            if (!params.title) {  
                this.$message({
                    message: '请填写本次作业名称',
                    type: 'error'
                });
                return false
            }
            if (!Reflect.has(params,'time')) {  
                this.$message({
                    message: '请选择作业时间周期',
                    type: 'error'
                });
                return false
            }
            if(this.table == 'read_type'){
                params['specialty_type'] = ''
            }else{
                params['read_type'] = ''
            }
            params[this.table] = this.wid
            params['start_time'] = this.$Common.getTime(params.time[0])
            params['end_time'] = this.$Common.getTime(params.time[1])
            delete params.time;

            Http.createTemporaryWork(params).then((res) => {
                this.createLoading = false
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                if(res.code){
                    // this.form.time = ""
                    // this.form.title = ""
                    // this.dialogVisible = false
                    this.$emit('success',false)
                }
            });

        },
        handleSelectionChange(row){
            var plan = []
            for(var index in row){
                plan.push(row[index].id)
            }
            this.form.plan = plan.join(',')
        },
        getPlan_(){
            var params = this.plan_form
    
            Http.PlanList(params).then((res) => {
                this.tableData = res.data.list
            });
            
        }
    }
}
</script>
<style scoped>
.work_body >>> .el-form-item__content{
    text-align: left;
}
</style>