<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">班级管理</span>
                    <el-row>

                        <el-button size="small" type="primary" @click="dialogFormVisible = true" >加入班级</el-button>
     
                    </el-row>
                </div>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="我的班级" name="first">
                        <p class="serach" style="margin-bottom: 15px;text-align: left;float: left;">

                            <el-input style="float: left;" v-model="keyword" size="small" :clearable="true" @input="changKeyword" placeholder="请输入班级"></el-input>

                        </p>
                        <Skeleton v-if="loading" :skeleton_type="'image_text'"></Skeleton>
                        <div v-if="!loading">
                            <el-col v-if="!plan_data" :span="24">
                                <el-empty description="还未加入班级"></el-empty>
                            </el-col>
                        
                            <el-col v-for="(item,index) in plan_data" :span="24" :key="index">
                                <el-card shadow="hover">
                                    <div class="list_thumb">
                                        <img v-if="!item.image" src="../../../static/image/bjtp-mr4.png" />
                                        <img v-if="item.image" :src="item.image" />
                                        <img class="plan_tage" v-if="item.status == 1" src="../../../static/image/dlbj.png">
                                        <img class="plan_tage" v-if="item.status == 0" src="../../../static/image/zrbj.png">
                                    </div>
                                    <div class="list_info">
                                        <div class="list_info_text" style="font-weight: 700;">{{ item.name }}</div>
                                        <div class="list_info_text" style="font-size: 15px;">
                                            <span>年级: {{ item.grade_text }}</span>
                                            <span>学院: {{ item.college }}</span>
                                            <span>专业: {{ item.specialty }}</span>
                                        </div>
                                        <div class="list_info_text" style="font-size: 15px;">人数: {{ item.number }}</div>
                                        <div class="list_info_text" style="font-size: 15px;">班级码: {{ item.qcode }}</div>
                                    </div>
                                    <div class="list_right">
                            
                                        <div class="list_right_bu">
                                            <el-button size="small" @click="planDetail(item.plan_id)" type="primary">班级详情</el-button>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </div>
                    </el-tab-pane>

                </el-tabs>
            </div>
            <div class="dialog_">
                <el-dialog title="加入班级" width="50%" :visible.sync="dialogFormVisible">
                    <el-form :model="form">
                        <el-form-item label="" :label-width="formLabelWidth">
                            <el-input v-model="form.qcode" @input="serachPlan" size="small" placeholder="输入班级码"></el-input>
                        </el-form-item>
                        
                    </el-form>
                    
                    <el-table size="medium" :data="tableData" style="width: 100%">
                        <el-table-column
                            prop="name"
                            label="班级名称"
                            width="260">
                        </el-table-column>
                        <el-table-column
                            prop="u_id_text"
                            label="管理员">
                        </el-table-column>
                        <el-table-column
                            prop="grade_text"
                            label="年级">
                        </el-table-column>
                        <el-table-column
                            prop="college"
                            label="学院">
                        </el-table-column>
                        <el-table-column
                            prop="specialty"
                            label="专业">
                        </el-table-column>
          
                        <el-table-column label="操作">
                            <template slot-scope="scope">
       
                                <el-button
                                size="mini"
                                type="primary"
                                @click="pushPlan(scope.row.plan_id)">加入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-dialog>
            </div>
        </layout>
    </div>
</template>
  
<script>
    import Http from "../../apis/student";
    import skeleton from "../../../untils/skeleton.vue";
    export default{
        components:{
            'Skeleton':skeleton
        },
        data(){

            return{
                variant:'image',
                activeName:'first',
                dialogFormVisible:false,
                dialogImageUrl:false,
                form:{
                    qcode:''
                },
                formLabelWidth:'0px',
                tableData: [],
                dialogVisible:false,
                addformLabelWidth:'3vw',
                keyword:'',
                plan_data:[],
                loading:true
            }
        },
        activated(){
            this.getPlan()
            window.addEventListener('popstate', this.handlePopState());
        },
        methods:{
            handlePopState(){
                document.body.style.overflow = 'auto';
            },
            // 获取班级
            getPlan(){
                // const loading = this.$loading({
                //     lock: true,
                //     text: '疯狂加载中...',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.5)'
                // });
                var params = {}
                params.keyword = this.keyword
                Http.Plan(params).then((res) => {
                    this.loading = false
                    //loading.close()
                    this.plan_data = res.data
                });

            },
            // 搜索班级
            changKeyword(){
                this.getPlan()
            },

            // 班级码搜索班级
            serachPlan(){

                Http.searchPlan(this.form).then((res) => {
                    this.tableData = res.data
                });
            },
            // 加入班级
            pushPlan(plan_id){
                var params = {}
                params.plan_id = plan_id
                Http.pushPlan(params).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    if(res.code){
                        this.dialogFormVisible = false
                        this.getPlan()
                    }
                });
            },

            planDetail(plan_id){

                var _this = this
                _this.$router.push({name:'StudentPlanDetail',query:{plan_id:plan_id}});
            }

        }
    }

</script>

<style scoped>
    .paln_page >>> .el-main{
        background: #ffffff !important;
        margin: 20px;
        border-radius: 8px;
    }
    .top_label >>> .el-row{
        float: right;
    }
    .main_body >>> .el-tabs__item{
        font-size: 16px;
    }
    .main_body >>> p{
        text-align: left;
    }
    .search{
        margin-left: 15px;
    }
    .main_body >>>.el-card__body{
        float: left;
        width: 100%;
        padding-right: 25px;
        box-sizing: border-box;
    }
    .list_right >>> i{
        float: right;
        margin-left: 10px;
        font-size: 18px;
    }
    .list_right_bu >>> .el-button{
        float: right;
    }
    .main_body >>> .el-col{
        margin-bottom: 20px;
    }
    .serach >>> .el-input{
        width: 10vw;
        float: left;
    }
    .dialog_ >>> .el-input{
        width: 50%;
        float: left;
    }
    .dialog_ >>> .el-dialog{
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
    }
    .dialog_ >>> .search_button{
        float: left;
        margin-left: 10px;
    }
    .dialog_ >>> .el-dialog__header{
        background: #409EFF;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .dialog_ >>> .el-dialog__title{
        color: #ffffff !important;
    }
    .dialog_ >>> .el-dialog__close{
        color: #ffffff !important;
    }
    .dialog_add >>> .el-form{
        width: 60%;
        margin: 0 auto;
    }
    .dialog_add >>> .el-select{
        width: 100%;
    }
    .list_right{
        line-height: 7.5vw;
    }
    .list_info_text{
        line-height: 28px !important;
    }
.list_thumb{
    position: relative;
}
.plan_tage{
    position: absolute;
    width: 60px !important;
    height: 38px !important;
    left: 0;
}
</style>
<style lang="less" scoped>
@import url('../../../static/css/main.less');
</style>