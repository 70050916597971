import axios from '../services/request'

/* 阅读首页 */
export const readIndex = (data = {}) => axios.get('api/read/index',data);

/* 阅读文章类型 */
export const getReadType = (data = {}) => axios.get('api/read/getReadType',data);

/* 阅读详情 */
export const getDetail = (data = {}) => axios.get('api/read/getDetail',data);

/* 阅读做题 */
export const submitAnswer = (data = {}) => axios.post('api/read/submitAnswer',data);

/* 阅读收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);

/* 布置临时作业 */
export const createTemporaryWork = (data = {}) => axios.post('api/teachwork/createTemporaryWork',data);


/* 班级列表 */
export const PlanList = (data = {}) => axios.get('api/teach/plan',data);
export default{
    readIndex,
    getReadType,
    getDetail,
    submitAnswer,
    Coll,
    createTemporaryWork,
    PlanList
}