<template>
    <div class="specialtydetail_page">
        <layoutpage>
            <!-- 阅读 -->
            <Specialty-reading v-if="type == 1 || type == 5" ref="reading" :userInfo="userInfo" :form_data="form_data"></Specialty-reading>
            <!-- 情景对话 -->
            <Specialty-talk v-if="type == 2" ref="talk" :userInfo="userInfo" :form_data="form_data"></Specialty-talk>
            <!-- 单词记忆 -->
            <Specialty-word v-if="type == 3" ref="word" :userInfo="userInfo" :form_data="form_data"></Specialty-word>
            <!-- 单词变形  AB级填空题 -->
            <Specialty-cloze v-if="type == 4" ref="cloze" :userInfo="userInfo" :form_data="form_data"></Specialty-cloze>

            <Specialty-choice v-if="type == 6" ref="choice" :userInfo="userInfo" :form_data="form_data"></Specialty-choice>
            <!-- 文章 -->
            <Specialty-articl v-if="type == 0 || type == 10 || type == 11 || type == 12" ref="articl" :userInfo="userInfo" :form_data="form_data"></Specialty-articl>

            <!-- 翻译 -->
            <Specialty-translate v-if="type == 7" ref="translate" :userInfo="userInfo" :form_data="form_data"></Specialty-translate>
            
            <!-- 选词填空 -->
            <Specialty-fill v-if="type == 'fill'" ref="fill" :userInfo="userInfo" :form_data="form_data"></Specialty-fill>

            <Gzdcyj v-if="type == 28 || type == 29 || type == 30" :form_data="form_data" ref="gzdcyj"></Gzdcyj>

            <Readwindow v-if="form_data.task_id && form_data.worktype == 0" :plan_id="form_data.plan_id.toString()" :worktype="form_data.worktype.toString()" :task_id="form_data.task_id.toString()" :periods="form_data.periods.toString()" temptype="spe" @call_back="reDetail"></Readwindow>
        </layoutpage>
    </div>
</template>
<script>

import reading from "../../../untils/specialty/reading.vue";
import talk from "../../../untils/specialty/talk.vue";
import word from "../../../untils/specialty/word.vue";
import cloze from "../../../untils/specialty/cloze.vue";
import choice from "../../../untils/specialty/choice.vue";
import articl from "../../../untils/specialty/articl.vue";
import translate from "../../../untils/specialty/translate.vue";
import fill from "../../../untils/specialty/fill.vue";
import readwindow from "../../../untils/read/readwindow";
import gzdcyj from "../../../untils/specialty/gzdcjy";
export default {
    components:{
        'Specialty-reading':reading,
        'Specialty-talk':talk,
        'Specialty-word':word,
        'Specialty-cloze':cloze,
        'Specialty-choice':choice,
        'Specialty-articl':articl,
        'Specialty-translate':translate,
        'Specialty-fill':fill,
        'Readwindow':readwindow,
        'Gzdcyj':gzdcyj
    },
    data(){
        return{
            specialtyname:'first',
            testname:'first',
            form_data:{
                id:'',
                task_id:0,
                plan_id:0,
                periods:'',
                worktype:0
            },
            type:0,
            userInfo:{}
        }
    },
    activated(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        if(!this.userInfo){
            this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
        }
        this.form_data.id = this.$route.query.id
        this.form_data.task_id = this.$route.query.task_id
        this.form_data.plan_id = this.$route.query.plan_id
        this.form_data.periods = this.$route.query.periods
        this.form_data.worktype = this.$route.query.worktype
        this.type = this.$route.query.type

        
        setTimeout(()=>{
            this.getUnitls(this.type)
        },50)
    
    },
    methods:{
        reDetail(item){
            this.form_data.id = item.wid
            this.form_data.task_id = item.task_id
            this.form_data.plan_id = item.plan_id
            //this.form_data.row_type = item.row_type
            this.form_data.worktype = item.worktype
            this.form_data.periods = item.periods
            this.type = item.spe_type
            //this.$router.push({name:'SpecialtyDetail',query:{id:item.wid,type:item.spe_type}});
            setTimeout(()=>{
                this.getUnitls(this.type)
            },50)
        },
        getUnitls(type){

            if(type == 1 || type == 5){
                this.$refs.reading.getData()
            }else if(type == 2){
                this.$refs.talk.getData()
            }else if(type == 3){
                this.$refs.word.getData()
            }else if(type == 4){
                this.$refs.cloze.getData()
            }else if(type == 6){
                this.$refs.choice.getData()
            }else if(type == 0 || type == 10 || type == 11 || type == 12){
                this.$refs.articl.getData()
            }else if(type == 7){
                this.$refs.translate.getData()
            }else if(type == 'fill'){
                this.$refs.fill.getData()
            }else if(type == 28 || type == 29 || type == 30){
                this.$refs.gzdcyj.getData()
                
            }
        }
    }
}
</script>
