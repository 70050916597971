import { render, staticRenderFns } from "./Statisticsdetail.vue?vue&type=template&id=350dedd3&scoped=true"
import script from "./Statisticsdetail.vue?vue&type=script&lang=js"
export * from "./Statisticsdetail.vue?vue&type=script&lang=js"
import style0 from "./Statisticsdetail.vue?vue&type=style&index=0&id=350dedd3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350dedd3",
  null
  
)

export default component.exports