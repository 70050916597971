<template>
    <div class="specialty_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/yingshiyingyubanner.png" />
            <div class="content">
                <div class="screen">
                    <el-skeleton v-if="!label.length" :rows="3" animated />
                    <div v-if="label.length">
                        <el-row>
                            <el-button size="small" disabled type="text">类别</el-button>
                            <span v-for="(item,index) in label" :class="`${select == index?'select':''}`" @click="selectLabel(index)" :key="index">{{ item }}</span>

                        </el-row>
                        <el-row>
                            <el-button size="small" disabled type="text">题型</el-button>
                            <span v-for="(item,index) in Chil_data" :class="`${chil_select == index?'select':''}`" @click="selectGrade(index,item)" :key="index">{{ item.name }}</span>
                        </el-row>
                    </div>
                    
                    <el-input style="width: 400px;" placeholder="请输入材料标题" size="small" v-model="search_data.title" @change="changekeyword" clearable></el-input>
                </div>
                <el-table
                    :data="list"
                    v-loading="loading"
                    element-loading-text="Loading..."
                    :cell-style="{cursor: 'pointer'}"
                    @row-click="handleRowClick"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="title"
                    label="标题"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="type_text"
                    label="类别"
                    width="170">
                    </el-table-column>
                    <el-table-column
                    prop="click"
                    label="浏览"
                    width="100"
                    >
                    </el-table-column>   
                </el-table>
                <el-pagination
                    v-if="total_number && total_number >= search_data.pageSize"
                    style="margin-top: 20px;"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="search_data.pageSize"
                    :current-page="search_data.page"
                    :total="total_number">
                </el-pagination>

            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/specialty";
export default {
    data(){
        return{
            select:0,
            chil_select:0,
            label:[],
            groupedArray:[],
            Chil_data:[],
            list:[],
            total_number:0,
            search_data:{
                page:1,
                pageSize:20,
                title:"",
                type:1,
                title:''
            },
            loading:false
        }
    },

    activated(){

        if(this.$route.query.select){
            this.select = this.$route.query.select
        }

        this.getList()
    },

    methods:{
        changekeyword(){
            this.search_data.page = 1 
            this.getList()
        },
        handleRowClick(row,event){
            var _this = this
            if(!window.sessionStorage.getItem("userinfo")){

                _this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false
            }
            _this.$router.push({name:'SpecialtyDetail',query:{id:row.id,type:row.type}});
        },
        getList(){
            this.loading = true
            Http.Index(this.search_data).then((res) => {
                
                this.list = res.data.data
                this.total_number = res.data.total_number
                var label = []
                for(var index in res.data.groupedArray){
                    label.push(index)
                }
                this.label = label
                this.groupedArray = res.data.groupedArray
                this.loading = false
                this.selectChil()
            });
        },

        selectChil(){
            var label = this.label
            var index = label[this.select]
            this.Chil_data = this.groupedArray[index]
        },
        // 点击父级
        selectLabel(index){
            this.chil_select = 0
            this.search_data.page = 1
            this.select = index
            var label = this.label
            var index = label[this.select]
            this.Chil_data = this.groupedArray[index]
            this.search_data.type = this.Chil_data[this.chil_select].key
            this.getList()
        },

        // 点击子级
        
        selectGrade(index,item){
            this.search_data.page = 1
            this.chil_select = index
            this.search_data.type = item.key
            this.getList()
        },
        // 上一页
        prev(e){

            this.search_data.page = e
            this.getList()
        },

        // 下一页
        next(e){

            this.search_data.page = e
            this.getList()
        },

        // 点击页码
        changeNumber(e){

            this.search_data.page = e
            this.getList()
        },

        // 前往指定页数
        changeSize(e){

            this.search_data.page = e
            this.getList()
        }


    }
}
</script>


<style scoped>
.banner{
    width: 100%;
    height: 360px;
}
.content{
    width: 1200px;
    margin: 0 auto;
}
.screen{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
}
.content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
</style>