<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">修改密码</span>
                </div>

                <div class="second_box">
                    <el-card shadow="always" header="修改密码">

                        <el-form  :model="ruleForm" ref="ruleForm" label-width="0px" class="demo-ruleForm">
                            
                            <el-form-item label="" prop="password">
                                <el-input type="password" v-model="ruleForm.password" placeholder="请输入新密码" ></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="newpassword">
                                <el-input type="password" v-model="ruleForm.newpassword" placeholder="请再次输入新密码" ></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="" prop="mobile">
                                <el-input type="number" v-model="ruleForm.mobile" placeholder="请输入手机号" ></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input type="text" v-model="ruleForm.captcha" style="width: 70%;float: left;" placeholder="请输入验证码"></el-input>
                                <el-button style="float: right;" @click="captcha" type="primary">{{ captcha_text }}</el-button>
                            </el-form-item> -->
                            <el-form-item>
                                <el-button style="width: 100%;margin-top: 10px;" type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                    
 


                </div>

            </div>
        </layout>
    </div>
</template>

<script>
import Http from "../../apis/user";
  export default {
    
    data() {
        return {
            time:60,
            time_boj:{},
            captcha_text:'发送验证码',
            ruleForm: {
                password: '',
                newpassword: '',
                mobile: '',
                captcha:''
            },
        };
    },

    methods: {
        submitForm(formName) {

            Http.Resetinfopwd(this.ruleForm).then((res) => {

                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                if(res.code){
                    this.ruleForm = {
                        password: '',
                        newpassword: '',
                        mobile: '',
                        captcha:''
                    }
                    setTimeout(()=>{
                        this.loginOut()
                    },1000)
                }
                
            });
        },
 
        loginOut(){
            Http.Logout().then((res) => {
                if(res.code){
                    this.userInfo = {}
                    window.sessionStorage.clear()
                    this.$router.push({path:'/Login'});
                }
                
            });
        },
        // 发送验证码 event:'resetpwd',
        captcha(){

            var params = {}
            params.event = 'resetpwd'
            params.mobile = this.ruleForm.mobile
            Http.Sms(params).then((res) => {

                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });

                if(res.code){
                    this.downTime()
                }
                
            });

        },


        downTime(){
            this.time_boj = setInterval(()=>{
                console.log(this.time)
                if(this.time == 0){
                    this.time = 60
                    this.captcha_text = '发送验证码'
                    clearInterval(this.time_boj)
                    return false;
                }

                this.time -= 1;
                this.captcha_text = `${this.time} S`

            },1000)
        }
        
    }
  };
</script>

<style scoped>

.top_label{
    width: 100%;
    height: 1.6vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}

.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.second_box{
    width: 30vw;
    margin: 0 auto;
    margin-top: 4vw;
}

</style>