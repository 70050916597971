<template>
    <div class="_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/jnxlbanenr.png" />
            <div class="_page_content">
                <div class="screen">
                    <el-row v-if="!loading">
                        <el-button size="small" disabled type="text">选文类别</el-button>
                        <span v-for="(item,index) in culture" :class="`${form.type == index?'select':''}`" @click="selectLabel(index)" :key="index">{{ item }}</span>
                    </el-row>
                    <el-skeleton v-if="loading" :rows="1" animated />
                </div>
                <p class="screen_read">
                    <span @click="order('timedown')" class="select el-icon-sort-down">时间降序</span>
                    <span @click="order('timeup')" class="select el-icon-sort-up">时间升序</span>
                    <span @click="order('click')" class="select">文章热度</span>
                    <el-input @input="changKeyword" v-model="form.title" placeholder="请输入材料标题" size="small" clearable></el-input>
                </p>
                <div class="box" :class="{ 'shrink': isShrink }" @click="toggleShrink">
                    
                    <div v-if="!isShrink" class="box_list">
                        <div style="display: inline-block;width: 100%;line-height: 40px;">
                            <span class="box_list_num table_title" >序号</span>
                            
                            <span class="box_list_title table_title">标题</span>
                            
                            <!-- <span class="box_list_time table_title" style="float: right;">发布时间</span> -->
                            <span class="box_list_click table_title" style="float: right;">浏览量</span>
                        </div>
                    </div>
                    

                    <div @click="selectArticl(index)" v-for="(item,index) in row" :key="index" :class="`box_list ${isShrink && index == select?'box_list_select':''}`">
                        
                        <div v-if="isShrink" class="box_list_one">
                            <div style="width: 178px;float: left;">
                                <span class="box_list_one_title">{{ item.title }}</span>
                                <div class="box_list_one_time"><span style="margin-right: 10px;">{{ culture[form.type] }}</span> <span>浏览量: {{ item.click }}</span></div>
                            </div>
                            <img style="width: 92px;border-radius: 5px;" :src="item.thumb" />
                            <img style="width: 92px;border-radius: 5px;" v-if="!item.image" src="../../../static/image/image_none.png" />
                        </div>
                        <div v-if="!isShrink" style="display: inline-block;width: 100%;">
                            <span class="box_list_num">{{ index+1 }}</span>
                            <span class="box_list_title">{{ item.title }}</span>
                            <!-- <span class="box_list_time">{{ item.createtime }}</span> -->
                            <span class="box_list_click">{{ item.click }}</span>
                        </div>
                    </div>
                    <p style="width: 100%;display: inline-block;">
                        <el-pagination
                            v-if="total_number"
                            @size-change="changeSize"
                            @current-change="changeNumber"
                            @prev-click="prev"
                            @next-click="next"
                            :pager-count="5"
                            :page-size="form.pageSize"
                            :total="total_number"
                            style="margin-top: 20px;">
                        </el-pagination>
                    </p>
                </div>
                <transition name="fade">
                    <div v-if="isShown" class="content_right">
                        <h3>{{ select_data.title }}</h3>
                        <el-button :type="button_type" :loading="button_loading" @click="Coll" size="mini" icon="el-icon-star-off">收藏</el-button>
                        <el-divider content-position="right">{{ culture[form.type] }}</el-divider>
                        <div v-html="select_data.detail"></div>
                    </div>
                </transition>

                
            </div>
            
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/skill";
export default {
    data(){
        return{
            button_loading:false,
            button_type:'info',
            select:0,
            isShrink: false,
            isShown:false,
            form:{
                page:1,
                pageSize:20,
                type:1,
                title:'',
                order:'timedown',
            },
            loading:true,
            total_number:0,
            row:{},
            culture:[],
            select_data:{},
            userInfo:{}
        }
    },
    activated(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.get_type()
        this.get_list()
    },
    methods: {

        get_type(){
            Http.getSkillType().then((res) => {
     
                this.culture= res.data

            })
        },

        get_list(){
            Http.Index(this.form).then((res) => {
                this.$Common.reconsitution(res.data.data,'skill')
                this.loading = false
                this.row= res.data.data
                this.total_number= res.data.total_number
            })
        },
        // 选择类别
        selectLabel(index){
            this.form.type = index
            this.form.page = 1
            this.select = 0
            this.get_list()
        },
        toggleShrink() {
            if(this.isShrink){
                return false
            }
            this.isShrink = !this.isShrink;
            setTimeout(()=>{
                this.isShown = true
            },300)
        },  
        selectArticl(index){
            const jumpElement = this.$el.querySelector('.el-pagination__jump');
            if (jumpElement) {
                jumpElement.style.display = 'none'; 
            }
            this.select = index
            this.button_type = this.row[index].collection?'primary':'info'
            this.select_data = this.row[index]
        },
        // 排序
        order(value){
            this.form.order = value
            this.get_list()
        },

        // 上一页
        prev(e){
            this.form.page = e
            this.get_list()
        },

        // 下一页
        next(e){
    
            this.form.page = e
            this.get_list()
        },

        // 点击页码
        changeNumber(e){
            this.form.page = e
            this.get_list()
        },

        // 前往指定页数
        changeSize(e){
            this.form.page = e
            this.get_list()
        },
        // 搜索
        changKeyword(value){
            this.form.title = value
            this.get_list();
        },
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.select_data.id
            params.tabel_type = 'skill'
            params.title = this.select_data.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
            });
        },
    }
}
</script>
<style scoped>

._page_content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
}
._page_content .screen{
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
    margin-bottom: 20px;
}
._page_content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
._page_content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.box {
    width: 100%;
    transition: width 0.3s ease;
    float: left;
    background: #ffffff;
    border-radius: 5px;
}

.shrink {

    width: 318px !important;
    padding: 10px;
    box-sizing: border-box;
}
.content_right{
    float: right;
    width: 870px;
    min-height: 939px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    text-align: left;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.screen_read {
    text-align: left;
}
.screen_read span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;

    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.screen_read >>> .el-input{
    
    width: 400px;
    float: right;
}
.box_list{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 15px;
    color: #282828;
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
}
.box_list span{
    float: left;

}
.box_list_num{
    width: 50px;
    margin-right: 10px;
    line-height: 25px;
    font-size: 15px;
}
.box_list_title{
    width: 500px;
    margin-right: 10px;
    font-size: 15px;
}
.box_list_click{
    width: 80px;
    margin-right: 10px;
    float: right !important;
    line-height: 25px;
    font-size: 15px;
}
.box_list_time{
    width: 150px;
    float: right !important;
    text-align: right;
    color: #999999;
    line-height: 25px;
    font-size: 15px;
}
.box_list_one{
    width: 300px;
    display: inline-block;
}
.box_list_one_title{
    width: 100%;font-size: 15px;color: #282828;display: inline-block;
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 2;  
    overflow: hidden; 
}
.box_list_one_time{
    width: 100%;
    font-size: 12px;
    color: #999999;
    margin-top: 8px;
    display: inline-block;
    line-height: 15px;
}
.box_list_select{
    background: #ecf5ff;
    border: 1px #409eff solid;
    border-radius: 5px;
}
._page_content h3{
    text-align: left;
}
.table_title{
    font-size: 15px !important;
    font-weight: 700;
    color: #000000 !important;
    line-height: 40px !important;

}
</style>