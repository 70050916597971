<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">修改资料</span>
                </div>
                <div class="info_form" style="text-align: center;">
                <el-col :span="20" style="margin: 0 auto;">
                    <el-card shadow="always">
                        <div @click="avatarDiao" class="parent">
                            <img class="avatar" :src="form.avatar" />
                            <el-upload
                                class="upload-demo"
                                :action="upload_path"
                                :data="upload_data"
                                :on-success="handleSuccess_"
                                :show-file-list="false"
                                style="float: left;"
                                >
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </div>
                            
                            <el-row>
                                <span style="margin-bottom: 20px;">点击头像上传图片，图片格式JPG、PNG</span>
                            </el-row>
                            <el-form ref="form" :model="form" label-width="60px">
                                
                                <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '请填写姓名'}">
                                    <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="性别" style="text-align: left;">
                                    <el-radio v-model="form.gender" label="0" size="medium" border>男</el-radio>
                                    <el-radio v-model="form.gender" label="1" size="medium" border>女</el-radio>
                                </el-form-item>
                                <el-form-item label="昵称">
                                    <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
                                </el-form-item>
                                <el-form-item prop="stuid" :rules="{ required: true, message: '请填写工号'}" label="工号">
                                    <el-input v-model="form.stuid" placeholder="请输入工号"></el-input>
                                </el-form-item>
                                <el-form-item prop="mobile" :rules="{ required: true, message: '请填写电话'}" label="电话">
                                    <el-input v-model="form.mobile" placeholder="请输入电话"></el-input>
                                </el-form-item>
                                <el-form-item label="生日">
                                    <el-date-picker v-model="form.birthday" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                                <el-form-item prop="school_id" :rules="{ required: true, message: '请选择学校'}" class="school" label="学校">

                                    <el-select v-model="form.aid" filterable remote reserve-keyword placeholder="请输入关键词" @change="changeArea" :loading="loading">
                                        <el-option
                                            v-for="(item,index) in area"
                                            :key="index"
                                            :label="item.s_name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>

                                    <el-select v-model="form.school_id" filterable remote reserve-keyword placeholder="请输入关键词" :loading="loading">
                                        <el-option
                                        
                                        v-for="(item,index) in school"
                                        :key="index"
                                        :label="item.school_name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>

                                </el-form-item>
                                
                                <el-form-item>
                                    <el-button :loading="submitloading" type="primary" size="small" @click="onSubmit('form')">确认修改</el-button>
                    
                                </el-form-item>
                            </el-form>
                        

                    </el-card>
                </el-col>
            </div>
                    
                    <el-dialog
                        title="头像设置"
                        :visible.sync="dialogVisible"
                        width="30%"
                        :before-close="handleClose">
                            <div style="display: inline-block;width: 100%;">
                                <vueCropper
                                    ref="cropper"
                                    img=''
                                    :outputSize="option.size"
                                    :autoCropWidth="option.autoCropWidth"
                                    :autoCropHeight="option.autoCropHeight"
                                    :outputType="option.type"
                                    :autoCrop="true"
                                    :centerBox="false"
                                    :infoTrue="true"
                                    style="width: 18vw;height: 18vw;float: left;margin-right: 20px;"
                                    >
                                </vueCropper>
                                <div style="float: left;width: auto;text-align: center;">
                                    <el-row style="text-align: center;">
                                        <el-col :span="24" style="text-align: center;">
                                            <el-image style="width: 100px; height: 100px;border-radius: 50px;" src="http://gips3.baidu.com/it/u=1821127123,1149655687&fm=3028&app=3028&f=JPEG&fmt=auto?w=720&h=1280" fit="fill"></el-image>
                                        </el-col>
                                        
                                        <el-col :span="24" style="text-align: center;line-height: 40px;">
                                            100 * 100
                                        </el-col>
                                    </el-row>
                                    <el-row style="text-align: center;margin-top: 30px;">
                                        <el-col :span="24" style="text-align: center;">
                                            <el-image style="width: 30px; height: 30px;border-radius: 50px;" src="http://gips3.baidu.com/it/u=1821127123,1149655687&fm=3028&app=3028&f=JPEG&fmt=auto?w=720&h=1280" fit="fill"></el-image>
                                            
                                        </el-col>
                                        
                                        <el-col :span="24" style="text-align: center;line-height: 40px;">
                                            30 * 30
                                        </el-col>
                                    </el-row>
                                </div>

                                
                            </div>
             
                            <p style="margin-top: 20px;">
                                <el-upload
                                    class="upload-demo"
                                    :action="upload_path"
                                    :data="upload_data"
                                    :on-success="handleSuccess_"
                                    :show-file-list="false"
                                    style="float: left;"
                                    >
                                    <el-button size="small" type="primary">点击上传</el-button>
                                </el-upload>
                                <el-button type="primary" size="small" @click="getCropImage" plain>确认裁剪</el-button>
                            </p>
                            


                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                        </span>
                        </el-dialog>
                    

            </div>
        </layout>
    </div>
</template>
  
<script>
import Http from "../../apis/user";
import School from "../../apis/school";
export default {

    data() {
        return {
            fit:'cover',
            dialogVisible:false,
            option:{

                size:1,
                autoCropWidth:'300',
                autoCropHeight:'300',
                type:'png'

            },
            upload_path:`${this.$Config.baseUrl}/api/common/upload`,
            upload_data:{},
            form: {
                name: '',
                birthday: '',
                gender:'0',
                stuid:'',
                nickname:'',
                mobile:'',
                aid:'',
                school_id:'',
                avatar:''
            },
            area:[],
            school:[],
            store:{},
            loading:false,
            submitloading:false
        };
    },
    activated(){
        
        var params = {}
        params.token = window.sessionStorage.getItem("token")
        this.upload_data = params
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.form.avatar = this.userInfo.avatar
        this.Info_()
        this.getStore()
    },
    methods: {
        avatarDiao(){
            //this.dialogVisible = true
        },
        getStore(){
            Http.Config_().then((res) => {
                this.store = res.data
            });
        },
        Info_(){
            Http.Info().then((res) => {
                var data = res.data.data
                this.area = res.data.area
                this.school = res.data.school
                if(!res.data.data.avatar){
                    delete res.data.data.avatar
                    data['avatar'] = this.form.avatar
                }
                for(var index in this.form){

                    if(data[index]){
                        this.form[index] = data[index]
                    }
                }
                window.sessionStorage.setItem("userinfo", JSON.stringify(data));
            });
        },

        getCropImage() {
            this.$refs.cropper.getCropData((data) => {
                console.log('Cropped Image Data URL:', data);
            });
        },

        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitloading = true
                    if(this.store.is_school){
                        if(this.store && this.store.school && !this.store.school.personal){
                            this.$message({
                                showClose: true,
                                message: '不允许修改资料',
                                type: 'error'
                            });
                            this.submitloading = false
                            return false
                        }
                    }
                    this.form.birthday = this.$Common.getTime(this.form.birthday)
                    Http.Edit(this.form).then((res) => {
                        this.submitloading = false
                        this.Info_()
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.code ? 'success' : 'error'
                        });
                    });

                } else {
                    return false;
                }
            });

        },
        handleSuccess_(e){
            let _this = this;
     
            _this.form.avatar = e.data.fullurl
        },

        imageToBase64() {

        },

        changeArea(e){
            var params = {}
            params.aid = e
            School.Index(params).then((res) => {
                this.school = res.data
            });
        },
        handleClose(){}
    }
}
</script>
  
<style scoped>
.top_label{
    width: 100%;
    height: 1.6vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.info_form{
    width: 60%;
    margin:0 auto;
    margin-top: 3vw;
}
.avatar{
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    border-radius: 50%;
}
.info_form >>> .el-date-editor{

    width: 100%;
}
.info_form >>> .el-row{
    margin-bottom: 20px;
    opacity: 0.7;
}
.school{
    text-align: left;
    
}
.school >>> .el-select{
    width: 100%;
    margin-bottom: 20px;
}
.parent {  
  position: relative;  

}

.upload-demo{
    position: absolute;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%);
    z-index: 99;
    height: 100px;
}
.parent >>> .el-button{
    height: 100px;
    opacity: 0;
}
</style>