<template>
    <div class="down_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/ziyuanxiazai.png" />
            <div class="down_page_content">
                <div class="screen">
                    <el-row>
                        <el-button size="small" disabled type="text">选文类别</el-button>
                        <span v-for="(item,index) in down" :class="`${form.d_type == index?'select':''}`" @click="selectLabel(index)" :key="index">{{ item }}</span>
                    </el-row>
                </div>
                <p class="screen_read">
                    <span @click="order('timedown')" class="select el-icon-sort-down">时间降序</span>
                    <span @click="order('timeup')" class="select el-icon-sort-up">时间升序</span>
                    <span @click="order('click')" class="select">文章热度</span>
                    <el-input @input="changKeyword" placeholder="请输入材料标题" v-model="form.title" size="small" clearable></el-input>
                </p>
                <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="标题"
                        >
                    </el-table-column>

                    <el-table-column
                        v-if="form.d_type == 4 || form.d_type == 6 || form.d_type == 7"
                        prop="detail"
                        label="提取码"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="downnum"
                        label="下载量"
                        width="120">
                    </el-table-column>
     
                    <el-table-column width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            icon="el-icon-star-off"
                            :type="scope.row.collection?'primary':'info'"
                            @click="handleEdit(scope.$index, scope.row)">收藏</el-button>
                            <el-button
                            size="mini"
                            type="danger"
                            icon="el-icon-download"
                            @click="handleDown(scope.$index, scope.row)">下载</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total_number && total_number >= form.pageSize"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="form.pageSize"
                    :total="total_number"
      
                    style="margin-top: 20px;">
                </el-pagination>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/down";
export default {
    data(){
        return{
            tableData: [],
            form:{
                page:1,
                pageSize:20,
                d_type:1,
                title:'',
                order:'timedown',
            },
            down:[],
            total_number:0,
            userInfo:{}
            //order:'timedown',
        }
    },
    activated(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.get_type()
        this.get_list()
    },
    methods: {
        get_type(){
            Http.getDownloadType().then((res) => {
                this.down= res.data
            })
        },

        get_list(){
            Http.Index(this.form).then((res) => {
                this.$Common.reconsitution(res.data.data,'read')
                this.loading = false
                this.tableData= res.data.data
                this.total_number= res.data.total_number
            })
        },
        // 选择类别
        selectLabel(index){
            this.form.d_type = index
            this.form.page = 1
            this.get_list()
        },
        // 排序
        order(value){
            this.form.order = value
            this.get_list()
        },

        // 上一页
        prev(e){
            this.form.page = e
            this.get_list()
        },

        // 下一页
        next(e){
    
            this.form.page = e
            this.get_list()
        },

        // 点击页码
        changeNumber(e){
            this.form.page = e
            this.get_list()
        },

        // 前往指定页数
        changeSize(e){
            this.form.page = e
            this.get_list()
        },
        // 搜索
        changKeyword(value){

            this.form.title = value
            this.get_list();
        },

        handleDown(index,row){
            if(row.baidu_url){
                window.open(row.baidu_url, '_blank');
            }else{
                console.log(`${this.$Server.Host}${row.down_url}`)
                window.open(`${this.$Server.Host}${row.down_url}`, '_blank');
            }
        },

        handleEdit(index,row){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            var params = {}
            params.wid = this.tableData[index].id
            params.tabel_type = 'down'
            params.title = this.tableData[index].title
            Http.Coll(params).then((res) => {
      
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
 
                    
                this.get_list()
                
            });
        }
    }
}
</script>
<style scoped>

.down_page_content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
}
.down_page_content .screen{
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
    margin-bottom: 20px;
}
.down_page_content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.down_page_content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}

.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.screen_read {
    text-align: left;
}
.screen_read span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;

    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.screen_read >>> .el-input{
    
    width: 400px;
    float: right;
}

</style>