<template>
    <div class="page">
        <layout>
            <div class="main_body">

                <div class="top_label">
                    <span class="lable_name">

                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: 'work' }">作业管理</el-breadcrumb-item>
                            <el-breadcrumb-item>布置临时作业</el-breadcrumb-item>
                        </el-breadcrumb>

                    </span>

                </div>
                <form :model="form_data">
                    <div class="top_label">
                        <span class="lable_name">作业名称</span>
                        <span><el-input v-model="form_data.title" size="small" placeholder="请输入内容"></el-input></span>
                    </div>
                    <div class="top_label">
                        <span class="lable_name">作业类型</span>
                    </div>
                    <div class="top_label_type">
                        <div>
                            <el-button size="small" type="primary" @click="readDialogShow" class="el-icon-plus"> 阅读在线</el-button>
                            <el-button size="small" type="danger" @click="specialtyDialogShow" class="el-icon-plus"> 应试英语</el-button>
        
                        </div>
                    </div>
                    <div class="top_label_type" style="text-align: left;margin-bottom: 10px;">
                        <el-tag class="selection" v-for="(item,index_) in form_data.read_type" @close="closeRead(index_)" :key="index_" closable>({{ (index_+1) }})、{{item.title}}</el-tag>
             
                    </div>
                    
                    <div class="top_label_type" style="text-align: left;margin-bottom: 10px;">
        
                        <el-tag type="danger" class="selection" v-for="(specialtyitem,index_specialty) in form_data.specialty_type" @close="closeSpecialty(index_specialty)" :key="index_specialty" closable>{{specialtyitem.title}}</el-tag>
                    </div>
                    <div class="top_label">
                        <span class="lable_name">推送设置</span>
                    </div>

                    <div class="top_label_type" style="margin-bottom: 20px;display: inline-block;width: 100%;">
                        <div>
                            <el-button size="small" type="primary">作业周期</el-button>
                            <el-date-picker
                                v-model="form_data.time"
                                type="daterange"
                                align="right"
                                size="small"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                </form>
                
        
                <div class="top_label">
                    <span class="lable_name">推送班级</span>
                    <!-- <el-button type="primary" size="mini" icon="el-icon-plus" circle></el-button> -->
                </div>

                <div class="top_label_type" style="margin-bottom: 20px;">
                    <div>
                        <el-tag v-for="tag in plan" :key="tag" type="danger">{{tag}}</el-tag>
                    </div>
                    
                </div>

                <el-button size="small" @click="addWork" type="primary">设置完成，确认布置作业</el-button>

                <div class="top_label">
                    <span class="lable_name">推送明细</span>
                </div>
                <div class="top_label_type" style="margin-bottom: 20px;">
                    <div>
                        
                        <el-table
                        :data="work_list"
                        style="width: 100%">
                        <el-table-column
                        type="index"
                        label="序号"
                        width="120">
                        </el-table-column>
                        <el-table-column
                            prop="title"
                            label="标题"
                            
                            >
                        </el-table-column>
                        <el-table-column
                            prop="type_text"
                            label="类型"
                            width="120"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="starttime"
                            width="120"
                            label="开始时间">
                        </el-table-column>
                        <el-table-column
                            prop="endtime"
                            width="120"
                            label="结束时间">
                        </el-table-column>
       
                        <!-- <el-table-column width="120" label="操作">
                            <template slot-scope="scope">
                                <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                               
                            </template>
                        </el-table-column> -->
                        </el-table>
                    </div>
                </div>
                <div class="dialog_box">
                    <read-dialog ref="readdialog" @handsSelect="handsSelect"></read-dialog>
                    <specialty-dialog ref="specialtydialog" @handsSelect="handsSelectSpecialty"></specialty-dialog>
                </div>
                
            </div>
            
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/work";
import readdialog from "../../../untils/readdialog.vue";
import specialtydialog from "../../../untils/specialtydialog.vue";
export default {
    components:{
        'read-dialog':readdialog,
        'specialty-dialog':specialtydialog
    },
    data(){
        return{
            form_data:{
                read_type:[],
                specialty_type:[],
                plan:'',
                title:'',
                time:null
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            plan:[],
            work_list:[],
            isVisible: true
        }
        
    },
    activated(){
        
        this.form_data = {
                            read_type:[],
                            specialty_type:[],
                            title:'',
                            time:null
                        }
        this.work_list = []
        this.form_data.plan = this.$route.query.plan
        this.getPlan_(this.form_data.plan)
        
        setTimeout(()=>{
            this.getReadType_()
            this.changeSearch()
            //this.changeSpecialtySearch()
            //this.getSpecialtyType_()
        },500)
        
    },

    methods: {

        // 选中文章
        handsSelect(val){
            this.form_data.read_type = val
        },
        handsSelectSpecialty(val){
            console.log(val)
            this.form_data.specialty_type = val
        },
        readDialogShow(){
            this.$refs.readdialog.readDialogShow()
        },
        // 应试
        specialtyDialogShow(){
            this.$refs.specialtydialog.readDialogShow()
        },

        // 搜索阅读文章
        changeSearch(){
            
            this.$refs.readdialog.changeSearch()
        },

    
        // 获取阅读题型、等级、分类
        getReadType_(){
     
            this.$refs.readdialog.getReadDiaoType()
        },
        // 搜索应试文章
        changeSpecialtySearch(){
            
            this.$refs.specialtydialog.changeSearch()
        },
        getSpecialtyType_(){
            this.$refs.specialtydialog.getSpecialtyDiaoType()
        },
        // 获取班级名称
        getPlan_(plan){
            var params = {}
            params.plan = plan
            Http.getPlanName(params).then((res) => {
                this.plan = res.data

            });
        },
        // 提交
        addWork(){
            
            var params = JSON.parse(JSON.stringify(this.form_data))
            if (!params.title) {  
                this.$message({
                    message: '请填写本次作业名称',
                    type: 'error'
                });
                return false
            }
            if (!params.hasOwnProperty('time')) {  
                this.$message({
                    message: '请选择作业时间周期',
                    type: 'error'
                });
                return false
            }
            const loading = this.$loading({
                lock: true,
                text: '正在布置作业，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.3)'
            });
            if(params.read_type){
                this.getAritclData(params.read_type)
                params['read_type'] = this.getAritclData(params.read_type).join(',')
            }
            if(params.specialty_type){
                this.getAritclData(params.specialty_type)
                params['specialty_type'] = this.getAritclData(params.specialty_type).join(',')
            }
            params['start_time'] = this.$Common.getTime(params.time[0])
            params['end_time'] = this.$Common.getTime(params.time[1])

            delete params.time;
            Http.createTemporaryWork(params).then((res) => {
                this.work_list = res.data
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                setTimeout(()=>{
                    loading.close()
                },1000)
                
            });
        },

        getAritclData(data){
            var result = [];
            for(var index in data){
                result.push(data[index].id)
            }
            return result;
        },

        
        // 清除文章

        closeRead(index){
            var params = this.form_data.read_type
            params.splice(index, 1);
        },
        closeSpecialty(index){
            var params = this.form_data.specialty_type
            params.splice(index, 1);
        }
    }


}
</script>

<style scoped>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .top_label_type >>> .el-input{
        margin-top: 0px;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .top_label_type >>> .el-input{
        margin-top: -3px;
    }
    
}

.page >>> .el-main{
    min-height: 48vw;
}
.top_label{
    width: 100%;
    height: 2vw;
    margin-bottom: 1vw;
    line-height: 2vw;
    display: inline-block;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
    line-height: 1.4vw;
    margin-top: 0.3vw;
    margin-right: 15px;
}
.lable_name >>> .el-breadcrumb{
    font-size: 16px;
}
.top_label >>> .el-input{
    width: 20vw;
    float: left;
}
.top_label_type div{
    text-align: left;
}
.top_label_type div >>> .el-button{
    float: left;
}
.top_label_type{
    width: 100%;
 
    margin-bottom: 50px;
    line-height: 1.7vw;
    position: relative;
}
.top_label_type >>> .el-input{
    width: 12vw;
    position: absolute;
    margin-left: 10px;
    margin-right: 15px;
}
.top_label_type >>> .el-checkbox-group{
    text-align: left;
    margin-top: 10px;
}
.top_label_type >>> .el-date-editor{
    float: left;
    margin-left: 15px;
}
.top_label_type >>> .el-radio-group{
    margin-left: 15px;
}
.top_label_type >>> .el-tag{
    margin-right: 12px;
}
.top_label >>> .is-circle{
    float: left;
    margin-top: 0.5vw;
}

.top_label_type >>> .selection{
    margin-bottom: 15px;
}
</style>
