<template>
    <div class="page">
        <layout>
            <div class="stage_body">
                <div class="top_label">
                    <span class="lable_name">分层教学</span>
                </div>
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>分层规则</span>
                </div>
                <div class="timeline">
                    <el-timeline>
                        <el-timeline-item v-for="(item,index) in stage" :key="index" :class="colorClass[index]" :timestamp="`${item.starttime_text} ~ ${item.endtime_text}`" placement="top">
                            <el-card>
                                <h4>{{ item.char }}</h4>
                                <p>{{ item.intro }}</p>
                            </el-card>
                        </el-timeline-item>
                        
                        <div class="timeline_info">
                            <span>提示1: 提示:每日积分上限为10分，超过十分不会再累计，请各位同学合理安排</span>
                            <span>提示2: 请不要使用自行注册的账号进行学习，阶段考试必须使用学号进行登录，使用自行注册的账号会导致无法参加考试</span>
                        </div>
                
                    </el-timeline>
                </div>
                
          
                <div class="top_plan_info_title" style="margin-top: 20px;">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>做题记录</span>
                </div>
                <el-table
                :data="student"
                style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                        prop="uid_text"
                        label="姓名"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="stuid"
                        label="学号"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="integrallist"
                        label="积分"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="score"
                        label="正确率"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="number"
                        label="做题数"
                        >
                    </el-table-column>
                    <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            type="primary"
                            @click="handleStage(scope.row.uid,scope.row.uid_text)">查看</el-button>
      
                        </template>
                    </el-table-column>
                </el-table>
                <Stage-submitanswer ref="stagesubmitanswer"></Stage-submitanswer>

            </div>
        </layout>
    </div>
</template>

<script>
import Http from "../../apis/teach";
import stagesubmitanswer from "../../../untils/stagesubmitanswer.vue";

export default {
    components:{
        'Stage-submitanswer':stagesubmitanswer,
    },
    data(){
        return{
            colorClass:['greentimelinetitle','bluetimelinetitle','yellowtimelinetitle','redtimelinetitle'],
            stage:[],
            tableData:[],
            trem:0,
            plan_id:'',
            level:'',
            student:[]
        }
    },

    activated(){
        this.plan_id = this.$route.query.plan_id
        this.getStageWork()

    },

    methods:{
        getStageWork(){
            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            Http.getStage().then((res) => {
                loading.close()
                this.stage = res.data.list
                this.trem = res.data.trem
                this.level = res.data.level
                this.getStudentStageWork(res.data.trem,res.data.level)
            });
        },
        // 下方表格
        getStudentStageWork(trem,level){

            var params = {}
            params.plan_id = this.plan_id
            params.trem = trem
            params.level = level
            Http.studentStageWork(params).then((res) => {
                
                this.student = res.data
            });
            
        },

        handleStage(uid,name){
            
            this.$refs.stagesubmitanswer.getStageWork_(uid,this.trem,this.level,name)
        }

    }
}
</script>
<style scoped>
.stage_body >>> .el-timeline{
    text-align: left;
 
    margin-top: 40px;
}
.stage_body >>> .el-timeline-item{
    padding-bottom: 40px;
}
.timeline >>> .el-card{
    border: none;
}
.top_label{
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #EBEEF5;
    margin-bottom: 20px;
    padding-bottom: 5px;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.top_plan_info_title{
    width: 100%;
    height: 30px;
}
.top_plan_info_title img{
    width: 23px;
    height: 18px;
    float: left;
}
.top_plan_info_title span{
    float: left;
    margin-left: 10px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
}
.stage_body .greentimelinetitle >>> .el-timeline-item__timestamp{
    color: #1A9D30;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .greentimelinetitle >>> .el-card__body h4{
    color: #1A9D30;
}
.stage_body .greentimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .greentimelinetitle >>> .el-card__body{
    background: #D4EAD8;
}

.stage_body .bluetimelinetitle >>> .el-timeline-item__timestamp{
    color: #3197D8;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .bluetimelinetitle >>> .el-card__body h4{
    color: #3197D8;
}
.stage_body .bluetimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .bluetimelinetitle >>> .el-card__body{
    background: #DCEDF7;
}

.stage_body .yellowtimelinetitle >>> .el-timeline-item__timestamp{
    color: #EC9D5E;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .yellowtimelinetitle >>> .el-card__body h4{
    color: #EC9D5E;
}
.stage_body .yellowtimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .yellowtimelinetitle >>> .el-card__body{
    background: #F8EADF;
}

.stage_body .redtimelinetitle >>> .el-timeline-item__timestamp{
    color: #f56c6c;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .redtimelinetitle >>> .el-card__body h4{
    color: #f56c6c;
}
.stage_body .redtimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .redtimelinetitle >>> .el-card__body{
    background: #fef0f0;
}
.timeline_info{
    width: 100%;
    display: inline-block;
    text-align: left;
    box-sizing: border-box;
    padding: 10px;
}
.timeline_info span{
    width: 100%;
    display: inline-block;
    line-height: 30px;
    color: #FF5151;
    font-size: 14px;
    font-weight: 700;
}

</style>