import axios from '../services/request'

// 首页
export const Index = (data = {}) => axios.get('api/index/index',data);

// 谚语
export const Everyday = (data = {}) => axios.get('api/index/everyday',data);

// 封面图
export const getBanner = (data = {}) => axios.get('api/index/banner',data);
export default{
    Index,
    Everyday,
    getBanner
}