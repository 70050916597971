// 导入axios
import axios from 'axios';
import { Loading } from 'element-ui';
// 进行一些全局配置
// 公共路由(网络请求地址)
//axios.defaults.baseURL = 'http://www.xichuanwh.com/';
// 请求响应超时时间
axios.defaults.timeout = 50000;

// 封装自己的get/post方法
export default {
  get: function(path = '', data = {}) {
    //  console.log(data)
    return new Promise(function(resolve, reject) {
      axios.get(path, {
        params: data,
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded',
          'token':window.sessionStorage.getItem('token')
        }
      }
      )
        .then(function(response) {
          
          // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
          resolve(response.data);
        })
        .catch(function(error) {

          if(error.response.data.code == 401){
            window.location.href = '/#/login';
            
          }
          //resolve(error.response.data);
          //console.log(error.response.data.code);return
          //reject(error);
        });
    });
  },
  post: function(path = '', data) {
    return new Promise(function(resolve, reject) {
      axios.post(path, data,
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'token':window.sessionStorage.getItem('token')
          }
        }
        )
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
      
          //reject(error);
        });
    });
  }
};
