<template>
    <div class="match_page">
        <layoutpage>
            <img class="banner" src="../../../static/image/zhishijinsai.jpg" />
            <div class="content">
                <el-input v-model="keyword" placeholder="请输入学校"></el-input>
                <div class="content_box">
                    <el-empty v-if="!list.length" description="暂无比赛信息"></el-empty>
                    <router-link v-for="(item,index) in list" :key="index" :to="`/matchDetail?id=${item.id}`">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-card shadow="hover">
                                    <div class="cqgsdx">
                                        <img :src="item.image">
                                        <span class="cqgsdx_title">{{ item.title }}</span>
                                        <el-button size="mini" :type="item.status == 1 || item.status == 6?'info':(item.status == 2 || item.status == 3?'warning':(item.status == 4?'primary':'danger'))" round>{{ item.status_text }}</el-button>
                                    </div>
                                    <div class="time_box">
                                        <el-button type="text">报名时间: {{ item.enroll_start_time_text }} - {{ item.enroll_end_time_text }}</el-button>
                                        <el-button style="color: #f56c6c;" type="text">比赛时间: {{ item.start_time_text }} - {{ item.end_time_text }}</el-button>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </router-link>
                </div>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/match";
export default {
    data(){
        return{
            keyword:'',
            form:{
                page:1,
                pageSize:20
            },
            list:[]
        }
    },
    activated(){
        this.getData();
    },
    methods:{
        getData(){
            Http.Index(this.form).then((res) => {
                this.$Common.reconsitution(res.data,'read')
                this.list = res.data
            })
        }
    }
}
</script>
<style scoped>
.banner{
    width: 100%;
}
.match_page .content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
}
.match_page >>> .el-input{
    width: 400px;
    float: left;
    margin-bottom: 20px;
}
.content_box{
    width: 100%;
    display: inline-block;
    background: #ffffff;
    padding: 20px 15px;
    border-radius: 5px;
    text-align: left;
}
.content_box >>> .el-card__body{
    padding: 0 !important;
    cursor: pointer;
}
.time_box{
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
    text-indent: 22px;
}
.cqgsdx img{
    float: left;
    
}
.cqgsdx >>> .el-button{
    float: left;
    margin-top: 23px;
}
.content_box >>> .el-row{
    margin-bottom: 15px;
}
.cqgsdx_title{
    float: left;
    line-height: 75px;
    margin-right: 15px;
    color: #000000;
    font-weight: 700;
}
</style>