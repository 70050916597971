import axios from '../services/request'

// 列表
export const Index = (data = {}) => axios.get('api/culture/index',data);

// 文化赏析分类
export const getCultureType = (data = {}) => axios.get('api/culture/getCultureType',data);

// 详情
export const getDetail = (data = {}) => axios.get('api/culture/getDetail',data);

/* 收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);
export default{
    Index,
    getCultureType,
    getDetail,
    Coll
}