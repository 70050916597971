<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="title" label="标题"></el-table-column>
                <el-table-column property="type_text" label="类型" width="120"></el-table-column>
                <el-table-column width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        :type="scope.row.is_do?'danger':'primary'"
                        @click="handleEdit(scope.$index, scope.row)">{{ scope.row.is_do?'查看':'做题' }}</el-button>
    
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    style="margin-top: 20px;"
                    v-if="total_number"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="form.pageSize"
                    :total="total_number">
                </el-pagination>
                
        </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/student";
export default {
    data(){
        return{
            dialogTableVisible:false,
            gridData:[],
            total_number:0,
            
            title:'',
            form:{
                level:'',
                grade:'',
                trem:'',
                page:1,
                pageSize:20,
            }
        }
    },

    methods:{
        getStageWork(level,grade,trem,type){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            this.title = `${trem}期${level}层学习题目`
            this.form.level = level
            this.form.grade = grade
            this.form.trem = trem
            this.form.type = type
            Http.stageWork(this.form).then((res) => {
                this.dialogTableVisible = true
                this.gridData = res.data.list
                this.total_number = res.data.total_number
                loading.close()
            });
        },
        // 上一页

        prev(e){

            this.form.page = e
            this.getStageWork(this.form.level,this.form.grade,this.form.trem,this.form.type)
        },

        // 下一页
        next(e){

            this.form.page = e
            this.getStageWork(this.form.level,this.form.grade,this.form.trem,this.form.type)
        },

        // 点击页码
        changeNumber(e){

            this.form.page = e
            this.getStageWork(this.form.level,this.form.grade,this.form.trem,this.form.type)
        },

        // 前往指定页数
        changeSize(e){

            this.form.page = e
            this.getStageWork(this.form.level,this.form.grade,this.form.trem,this.form.type)
        },
        handleEdit(index,item){
            this.dialogTableVisible = false
            if(item.type == 'specialty'){
                this.$router.push({name:'SpecialtyDetail',query:{id:item.wid,type:item.z_type}});
            }else{
                this.$router.push({name:'ReadDetail',query:{id:item.wid}});
            }
            
        }
    }
}
</script>