import Vue from 'vue'
import Router from 'vue-router'
// 老师个人中心
import Login from '@/components/LoginPage'
import Plan from '@/components/teach/Plan'
import PlanDetail from '@/components/teach/Plandetail'
import Work from '@/components/teach/Work'
import Cyclework from '@/components/teach/Cyclework'
import Temporary from '@/components/teach/Temporary'
import Worklist from '@/components/teach/Worklist'
import Statistics from '@/components/teach/Statistics'
import Statisticsdetail from '@/components/teach/Statisticsdetail'
import Share from '@/components/teach/Share'
import Collection from '@/components/teach/Collection'
import Updatepsd from '@/components/teach/Updatepsd'
import Info from '@/components/teach/Info'
import Stage from '@/components/teach/Stage'
import StageIndex from '@/components/teach/Stageindex'
import TeachMessage from '@/components/teach/Message'
// 学生个人中心
import StudentPlan from '@/components/student/Plan'
import StudentPlanDetail from '@/components/student/Plandetail'
import StudentWork from '@/components/student/Work'
import StudentWorklist from '@/components/student/Worklist'
import StudentStatistics from '@/components/student/Statistics'
import StudentStatisticsdetail from '@/components/student/Statisticsdetail'
import StudentShare from '@/components/student/Share'
import StudentCollection from '@/components/student/Collection'
import StudentUpdatepsd from '@/components/student/Updatepsd'
import StudentInfo from '@/components/student/Info'
import StudentStage from '@/components/student/Stage'
import StudentMap from '@/components/student/Mapout'
import StudentMapDetail from '@/components/student/Mapdetail'
import StudentMessage from '@/components/student/Message'
// 阅读
import ReadIndex from '@/components/read/Index'
import ReadDetail from '@/components/read/Detail'
// 应试英语
import SpecialtyIndex from '@/components/specialty/Index'
import SpecialtyDetail from '@/components/specialty/Detail'
// 首页
import Index from '@/components/index/Index'
// 文化赏析
import CultureIndex from '@/components/culture/Index'
// 文件下载
import DownIndex from '@/components/down/Index'
// 百科讲堂
import WikiIndex from '@/components/wiki/Index'
// 技能训练
import SkillIndex from '@/components/skill/Index'
// 校园生活
import CampusIndex from '@/components/campus/Index'
// 每日谚语更多
import EverydayIndex from '@/components/everyday/Index'
// 知识竞赛 
import MatchIndex from '@/components/match/Index'
import MatchDetail from '@/components/match/Detail'
import MatchList from '@/components/match/List'
// 搜索
import SearchIndex from '@/components/search/Index'

// 隐私条款
import Privacy from '@/components/privacy/Index'

// 商务合作
import Cooperation from '@/components/cooperation/Index'


const routerRePush = Router.prototype.push
Router.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

const routerReplace = Router.prototype.replace
Router.prototype.replace = function (location) {
    return routerReplace.call(this, location).catch(error => error)
}
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/plan',
      name: 'Plan',
      component: Plan
    },
    {
      path: '/plandetail',
      name: 'PlanDetail',
      component: PlanDetail
    },
    {
      path: '/work',
      name: 'Work',
      component: Work
    },
    {
      path: '/cyclework',
      name: 'Cyclework',
      component: Cyclework
    },
    {
      path: '/temporary',
      name: 'Temporary',
      component: Temporary
    },
    {
      path: '/worklist',
      name: 'Worklist',
      component: Worklist
    },
    {
      path: '/statistics',
      name: 'Statistics',
      component: Statistics
    },
    {
      path: '/statisticsdetail',
      name: 'Statisticsdetail',
      component: Statisticsdetail
    },
    {
      path: '/share',
      name: 'Share',
      component: Share
    },
    {
      path: '/collection',
      name: 'Collection',
      component: Collection
    },
    {
      path: '/updatepsd',
      name: 'Updatepsd',
      component: Updatepsd
    },
    {
      path: '/info',
      name: 'Info',
      component: Info
    },
    {
      path: '/studentplan',
      name: 'StudentPlan',
      component: StudentPlan
    },
    {
      path: '/studentPlanDetail',
      name: 'StudentPlanDetail',
      component: StudentPlanDetail
    },
    {
      path: '/studentWork',
      name: 'StudentWork',
      component: StudentWork
    },
    {
      path: '/studentWorklist',
      name: 'StudentWorklist',
      component: StudentWorklist
    },
    {
      path: '/studentStatistics',
      name: 'StudentStatistics',
      component: StudentStatistics
    },
    {
      path: '/studentStatisticsdetail',
      name: 'StudentStatisticsdetail',
      component: StudentStatisticsdetail
    },
    {
      path: '/studentShare',
      name: 'StudentShare',
      component: StudentShare
    },
    {
      path: '/studentCollection',
      name: 'StudentCollection',
      component: StudentCollection
    },
    {
      path: '/studentUpdatepsd',
      name: 'StudentUpdatepsd',
      component: StudentUpdatepsd
    },
    {
      path: '/studentInfo',
      name: 'StudentInfo',
      component: StudentInfo
    },
    {
      path: '/stage',
      name: 'Stage',
      component: Stage
    },
    {
      path: '/studentStage',
      name: 'StudentStage',
      component: StudentStage
    },
    {
      path: '/stageIndex',
      name: 'StageIndex',
      component: StageIndex
    },
    {
      path: '/readIndex',
      name: 'ReadIndex',
      component: ReadIndex
    },
    {
      path: '/readDetail',
      name: 'ReadDetail',
      component: ReadDetail
    },
    {
      path: '/studentMap',
      name: 'StudentMap',
      component: StudentMap
    },
    {
      path: '/studentMapDetail',
      name: 'StudentMapDetail',
      component: StudentMapDetail
    },
    {
      path: '/SpecialtyIndex',
      name: 'SpecialtyIndex',
      component: SpecialtyIndex
    },
    {
      path: '/specialtyDetail',
      name: 'SpecialtyDetail',
      component: SpecialtyDetail
    },
    {
      path: '/cultureIndex',
      name: 'CultureIndex',
      component: CultureIndex
    },
    {
      path: '/downIndex',
      name: 'DownIndex',
      component: DownIndex
    },
    {
      path: '/wikiIndex',
      name: 'WikiIndex',
      component: WikiIndex
    },
    {
      path: '/skillIndex',
      name: 'SkillIndex',
      component: SkillIndex
    },
    {
      path: '/campusIndex',
      name: 'CampusIndex',
      component: CampusIndex
    },
    {
      path: '/everydayIndex',
      name: 'EverydayIndex',
      component: EverydayIndex
    },
    {
      path: '/matchIndex',
      name: 'MatchIndex',
      component: MatchIndex
    },
    {
      path: '/matchDetail',
      name: 'MatchDetail',
      component: MatchDetail
    },
    {
      path: '/matchList',
      name: 'MatchList',
      component: MatchList
    },
    {
      path: '/teachMessage',
      name: 'TeachMessage',
      component: TeachMessage
    },
    {
      path: '/studentMessage',
      name: 'StudentMessage',
      component: StudentMessage
    },
    {
      path: '/searchIndex',
      name: 'SearchIndex',
      component: SearchIndex
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/cooperation',
      name: 'Cooperation',
      component: Cooperation
    }

    
  ]
})

