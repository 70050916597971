<!-- 阅读在线选题 -->
<template>
    <div class="dialog_box">
        <el-dialog title="查找文章" :visible.sync="dialogFormVisible">
            <el-row>
                <el-button size="small" disabled type="text">选文类别</el-button>
                <span v-for="(item,index) in label" :class="`${item.select?'select':''}`" @click="selectLabel(index)" :key="index">{{ item.value }}</span>

            </el-row>
            <el-row>
                <el-button size="small" disabled type="text">选文难度</el-button>
                <span v-for="(item,index) in grade" :class="`${item.select?'select':''}`" @click="selectGrade(index)" :key="index">{{ item.value }}</span>
            </el-row>
            <el-row>
                <el-button size="small" disabled type="text">选择题型</el-button>
                <span v-for="(item,index) in type" :class="`${item.select?'select':''}`" @click="selectType(index)" :key="index">{{ item.value }}</span>
            </el-row>
            
            <el-input v-model="search_data.title" @input="changeSearch" placeholder="请输入文章标题"></el-input>

            <el-table
                ref="multipleTable"
                :data="tableData_"
                tooltip-effect="dark"
                row-key="id"  
                
                style="width: 100%;margin-top: 10px;"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection"
                :reserve-selection="true"
                width="55">
                </el-table-column>
                <el-table-column
                prop="title"
                label="名称"
                
                >
            
                </el-table-column>
                <el-table-column
                prop="label_text"
                label="类别"
                width="100"
                >
                </el-table-column>
                <el-table-column
                prop="grade_text"
                label="等级"
                width="100"
                >
                </el-table-column>
                <el-table-column
                prop="type_text"
                label="题型"
                width="100">
                </el-table-column>
            </el-table>

            <el-pagination
                v-if="total_number"
                @size-change="changeSize"
                @current-change="changeNumber"
                @prev-click="prev"
                @next-click="next"
                :page-size="search_data.pageSize"
                :total="total_number">
            </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/work";
export default {
    data(){
        return{
            dialogFormVisible:false,
            search_data:{
                r_type:0,
                label:0,
                grade:0,
                title:'',
                page:1,
                pageSize:15
            },
            cities: {1:'一级', 2:'二级', 3:'三级', 4:'四级',5:'五级',6:'六级',7:'七级'},
            tableData_: [],
            multipleSelection:[],
            total_number:0,
            cispace_data: [],
            label:{},
            type:{},
            grade:{},
        }
    },

    methods:{
        readDialogShow(){
            this.dialogFormVisible = true
        },

        handleSelectionChange(val) {

            this.$emit('handsSelect',val)
            //this.form_data.read_type = val
        },
        changeSearch(){
            
            Http.searchTemporaryRead(this.search_data).then((res) => {
                this.total_number = res.data.count
                this.tableData_ = res.data.data
        
            });
        },
        // 选择类别
        selectLabel(index){
            var label = JSON.parse(JSON.stringify(this.label))
            for(var i in label){
                if(i == index){
                    label[i].select = true
                }else{
                    label[i].select = false
                }
            }
            this.search_data.label = index
            this.label = label

            this.changeSearch()
        },

        // 选择题型
        selectType(index){
            var type = JSON.parse(JSON.stringify(this.type))
            for(var i in type){
                if(i == index){
                    type[i].select = true
                }else{
                    type[i].select = false
                }
            }
            this.search_data.r_type = index
            this.type = type
            this.changeSearch()
        },
        // 选择等级
        selectGrade(index){
            var grade = JSON.parse(JSON.stringify(this.grade))
            for(var i in grade){
                if(i == index){
                    grade[i].select = true
                }else{
                    grade[i].select = false
                }
            }
            this.search_data.grade = index
            this.grade = grade
            this.changeSearch()
        },

        // 获取阅读题型、等级、分类
        getReadDiaoType(){
     
            Http.getReadType().then((res) => {
                var label = []
                for(let index in res.data.label){
                    let result = {}
                    result.key = index
                    result.value = res.data.label[index]
                    result.select = index == 0?true:false
                    label.push(result)
                }
                this.label = label

                var type = []
                for(let index in res.data.type){
                    let result = {}
                    result.key = index
                    result.value = res.data.type[index]
                    result.select = index == 0?true:false
                    type.push(result)
                }
                this.type = type

                var grade = []
                for(let index in res.data.grade){
                    let result = {}
                    result.key = index
                    result.value = res.data.grade[index]
                    result.select = index == 0?true:false
                    grade.push(result)
                }
                this.grade = grade
            });
        },
        // 上一页

        prev(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 下一页
        next(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 点击页码
        changeNumber(e){
 
            this.search_data.page = e
            this.changeSearch()
        },

        // 前往指定页数
        changeSize(e){
   
            this.search_data.page = e
            this.changeSearch()
        },
    }
}
</script>
<style scoped>
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.dialog_box >>> .el-dialog__header{
    background: #409EFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
.dialog_box >>> .el-dialog__title{
    color: #ffffff;
}
.dialog_box >>> .el-icon-close{
    color: #ffffff;
}
.dialog_box >>> .el-dialog{
    border-radius: 8px;
}
.dialog_box >>> .is-disabled{

    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.dialog_box {

    text-align: left;
}
.dialog_box >>> .el-button--default{
    margin-bottom: 10px;
}

.dialog_box span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.dialog_box >>> .el-pagination{
    text-align: center;
    margin-top: 1vw;
}
</style>