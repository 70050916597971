<template>
    <div ref="myDiv" class="serach_area">
        <el-input placeholder="请输入内容" v-model="input3" prefix-icon="el-icon-search" :clearable="true" class="input-with-select">
            <el-select v-model="select" slot="prepend" placeholder="请选择">
                <el-option label="餐厅名" value="1"></el-option>
                <el-option label="订单号" value="2"></el-option>
                <el-option label="用户电话" value="3"></el-option>
            </el-select>
        </el-input>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input3: '',
            select: '',
            resizeObserver: null,  
        };
    },
    mounted() {  
    this.initResizeObserver();  
  },
    beforeDestroy() {  
    if (this.resizeObserver) {  
      this.resizeObserver.disconnect();  
      this.resizeObserver = null;  
    }  
  }, 
    methods: {  
    initResizeObserver() {  
      if ('ResizeObserver' in window) {  
        this.resizeObserver = new ResizeObserver(entries => {  
          entries.forEach(entry => {  
            console.log(`Element size: ${entry.contentRect.width}px x ${entry.contentRect.height}px`);  
            // 处理宽度变化  
          });  
        });  
        this.resizeObserver.observe(this.$refs.myDiv);  
      } else {  
        // 降级策略：使用定时器检查元素尺寸变化  
        this.checkElementSize();  
      }  
    },  
    checkElementSize() {  
      let prevWidth = this.$refs.myDiv.offsetWidth;  
      setInterval(() => {  
        const currentWidth = this.$refs.myDiv.offsetWidth;  
        if (currentWidth !== prevWidth) {  
          console.log(`Element size changed: ${prevWidth}px -> ${currentWidth}px`);  
          prevWidth = currentWidth;  
          // 处理宽度变化  
        }  
      }, 500); // 每500毫秒检查一次  
    }  
  }  
};
</script>
  
<style scoped>
.serach_area{
    width: 1200px;
    position: absolute;
    height: 100px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    
    display: flex;
    
}

.a{
    animation: shrinkRightDisappear 2s forwards;
}

@keyframes shrinkRightDisappear {
  0% {
    width: 1160px;
    margin-left: 0;
    opacity: 1;
  }
  75% {
    width: 0;
    margin-left: 1160px; /* 这使得元素看似从左向右收缩 */
    opacity: 1;
  }
  100% {
    width: 0;
    margin-left: 1160px;
    opacity: 0; /* 最后逐渐消失 */
  }
}

.class2{
    opacity: 0;
}
.serach_area >>> .el-select .el-input {
    width: 130px;
  }
</style>
  