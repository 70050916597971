import axios from '../services/request'

// 列表
export const Index = (data = {}) => axios.get('api/down/index',data);

// 文件下载分类
export const getDownloadType = (data = {}) => axios.get('api/down/getDownloadType',data);

/* 收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);
export default{
    Index,
    getDownloadType,
    Coll
}