<template>
    <div class="page">
        <layout>
            <div class="main_body">

                <div class="top_label">
                    <span class="lable_name">

                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">成绩统计</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ plan_name }}</el-breadcrumb-item>
                        </el-breadcrumb>

                    </span>

                </div>

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="整体成绩" name="first">
                        <div class="first_box">
                            <Student-totalscore ref="studenttotalscore"></Student-totalscore>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="周期作业" name="second">
                        <div class="second_box">
                            

                            <Student-cycle ref="studentcycle"></Student-cycle>
                            <img v-if="cyc_work.length == 0" src="../../../static/image/none.png" style="margin-top: 100px;" />
                    
                                <el-col v-for="(item,index) in cyc_work" style="margin-bottom: 15px;" :key="index">
                                    <el-card shadow="hover">
                                        <div class="left_worrk">
                                            <p>
                                                <span class="number">{{ index + 1 }}</span>
                                                <span class="title">{{ item.title }}</span>
                                            </p>
                                            <p>作业内容：阅读在线 {{ item.read_number }} 篇/应试英语 {{ item.specialty_number }} 篇</p>
                                            <p v-if="form_.type == 0">作业周期：{{ item.period_text }}</p>
                                            <p>布置时间：{{ item.createtime_text }}</p>
                                            <p>起止时间：{{ item.start_time_text }} 至 {{ item.end_time_text }}</p>
                                        </div>
                                        <div class="right_work">
                                            <el-button size="small" :loading="loading" style="margin-bottom: 10px;" @click="viewWorkDetail_(item.id)" type="primary">作业统计</el-button><br>
                                        </div>
                                    </el-card>
                                </el-col>
                    

                        </div>
                        
                    </el-tab-pane>
                    <el-tab-pane label="临时作业" name="third">
                        <div class="second_box">
                            <img v-if="tem_work.length == 0" src="../../../static/image/none.png" style="margin-top: 100px;" />
           
                                <el-col v-for="(item,index) in tem_work" :key="index" style="margin-bottom: 15px;" :span="24">
                                    <el-card shadow="hover">
                                        <div class="left_worrk">
                                            <p>
                                                <span class="number">{{ index + 1 }}</span>
                                                <span class="title">{{ item.title }}</span>
                                            </p>
                                            <p>作业内容：阅读在线 {{ item.read_number }} 篇/应试英语 {{ item.specialty_number }} 篇</p>
                                            <p v-if="form_.type == 0">作业周期：{{ item.period_text }}</p>
                                            <p>布置时间：{{ item.createtime_text }}</p>
                                            <p>起止时间：{{ item.start_time_text }} 至 {{ item.end_time_text }}</p>
                                        </div>
                                        <div class="right_work">
                                            <el-button size="small" :loading="loading" style="margin-bottom: 10px;" @click="viewTemWorkDetail(item.id)" type="primary">作业统计</el-button><br>
                                        </div>
                                    </el-card>
                                </el-col>
        

                            <Student-temstatis ref="studenttemstatis"></Student-temstatis>
                  
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="自主学习" name="fourth">

                        <div class="second_box">
   
                            <el-row :gutter="20">

                                <p class="second_p_box"><el-button type="text" class="el-icon-s-data">成绩总览</el-button></p>

                                <el-col :span="4">
                                    <div>
                                    <el-statistic title="完成情况">
                                        <template slot="formatter">
                                            {{ self_work.total_number }}
                                        </template>
                                    </el-statistic>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div>
                                    <el-statistic title="阅读在线数量">
                                        <template slot="formatter">
                                            {{ self_work.read_number }}
                                        </template>
                                    </el-statistic>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div>
                                        <el-statistic title="应试英语数量">
                                            <template slot="formatter">
                                                {{ self_work.specialty_number }}
                                            </template>
                                        </el-statistic>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div>
                                        <el-statistic title="阅读在线正确率">
                                            <template slot="formatter">
                                                {{ self_work.read_average_score }}
                                            </template>
                                        </el-statistic>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div>
                                        <el-statistic title="应试英语正确率">
                                            <template slot="formatter">
                                                {{ self_work.specialty_average_score }}
                                            </template>
                                        </el-statistic>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div>
                                        <el-statistic title="学习总时长">
                                            <template slot="formatter">
                                                {{ self_work.total_time }}
                                            </template>
                                        </el-statistic>
                                    </div>
                                </el-col>
                            </el-row>
                            
                            <el-table
                                :data="history_data"
                                tooltip-effect="dark"
                                style="width: 100%;display: inline-block;margin-top: 30px;"
                                >
                                <el-table-column
                                type="index"
                                width="55">
                                </el-table-column>
                                <el-table-column
                                prop="w_id_text"
                                label="标题"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="table_type_text"
                                label="类别"
                                width="100"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="score"
                                label="正确率"
                                width="100"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="time"
                                label="耗时"
                                width="100"
                                >
                                </el-table-column>
                                <el-table-column
                                prop="createtime"
                                label="时间"
                                width="120"
                                >
                                </el-table-column>
                
                            </el-table>
                            
                 
                        </div>
                        <el-pagination
                            v-if="total_number"
                            @size-change="changeSize"
                            @current-change="changeNumber"
                            @prev-click="prev"
                            @next-click="next"
                            :page-size="pageSize"
                            :total="total_number">
                        </el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </div>
                
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/student";
import studenttotalscore from "../../../untils/studenttotalscore.vue";
import studentcycle from "../../../untils/studentcycle.vue";
import studenttemstatis from "../../../untils/studenttemstatis.vue";

  export default {
    components:{
        'Student-totalscore':studenttotalscore,
        'Student-cycle':studentcycle,
        'Student-temstatis':studenttemstatis
    },
    data() {
        return {
            activeName: 'first',
            plan_name:'',
            plan_id:'',
            cyc_work:[],
            tem_work:[],
            loading:false,
            self_work:[],
            history_data:[],
            page:1,
            pageSize:20,
            total_number:0,
            form_:{
                page:1,
                pageSize:20
  
            }
    
        };
    },

    activated(){
        this.plan_id = this.$route.query.plan_id

        this.plan_name = this.$route.query.plan_name

        setTimeout(()=>{
            this.getTotalData()
            this.geCycWorkList()
            this.geTemWorkList()
            this.geSelfWorkList()
        },250)
        
    },

    methods: {
        // 获取临时作业
        geTemWorkList(){

            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });

            var params = this.form_
            params.type = 1
            params.plan_id = this.plan_id
            Http.Task(params).then((res) => {
                loading.close()
                this.tem_work = res.data
            });
        },


        // 获取周期作业
        geCycWorkList(){
            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });

            var params = this.form_
            params.type = 0
            params.plan_id = this.plan_id
            Http.Task(params).then((res) => {
                loading.close()
                this.cyc_work = res.data
            });

        },
        
        // 查看周期作业
        viewWorkDetail_(task_id){
            this.$refs.studentcycle.viewWorkDetail(this.plan_id,task_id)
        },


        viewTemWorkDetail(task_id){
            this.$refs.studenttemstatis.viewWorkDetail(this.plan_id,task_id)
        },


        // 自主学习成绩统计
        geSelfWorkList(){
            var params = {}
            params.plan_id = this.plan_id
            params.type = 4
            Http.workResult(params).then((res) => {
                this.getBottomStudent()
                this.self_work = res.data
 
            });
        },


        // 自主学习下方学习记录
        getBottomStudent(){
            var params = {}
            params.type = 4
            params.plan_id = this.plan_id
            params.page = this.page
            params.pageSize = this.pageSize
            Http.historyList(params).then((res) => {
                this.history_data = res.data.list
                this.total_number = res.data.total_number
  
            });
        },

        // 整体成绩
        getTotalData(){
            
            this.$refs.studenttotalscore.getTotalData(this.plan_id)

            this.$refs.studenttotalscore.getHistory()
        },
        // 上一页

        prev(e){

            this.page = e
            this.getBottomStudent()
        },

        // 下一页
        next(e){

            this.page = e
            this.getBottomStudent()
        },

        // 点击页码
        changeNumber(e){

            this.page = e
            this.getBottomStudent()
        },

        // 前往指定页数
        changeSize(e){

            this.page = e
            this.getBottomStudent()
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },

    }
  };
</script>
<style scoped>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .label_button_box_right >>> .el-button{
        float: right;
        margin-top: 0px;
    }
    .label_button_box_left{
        float: right;
        width:21vw;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .label_button_box_right >>> .el-button{
        float: right;
    }
    .label_button_box_left{
        float: right;
        width:19vw;
    }
}
.main_body >>> .el-tabs{
    margin-top: 1vw;
}

.box-card{
    padding: 10px 35px;
    margin-top: 20px;
    margin-right: 15px;
}
.box-card  >>> .el-card__body{

    padding: 0;
}
.box-card .item{
    line-height: 30px;

}

.box-card .number{
    font-weight: 700;
    color: #409EFF;
}
.box-card >>> .name{
    font-weight: 0 !important;
    font-size: 14px;
}
.box-card span{
    width: auto;
}
.unit{
    display: inline-block;
    font-size: 12px;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:20px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;

}
.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.second_box {
    text-align: center;
}

.second_box >>> .el-select{
    width: 25vw;
}
.second_box >>> .el-row{
 
    border: 1px solid #ebeef5;
    margin: 0 !important;
    
    margin-top: 15px !important;
    padding: 20px 0;
}
.second_box >>> .title{
 
    line-height: 30px;
 
}
.second_box >>> .number{
 
    line-height: 30px;
    color: #409eff;
}
.second_p_box >>> .el-button--text{

    font-size: 18px;
}
.second_p_box{
    text-indent: 2vw;
    margin: 0 !important;
    padding: 0 !important;
}
.left_worrk{
    text-align: center;
    width: 40vw;
    position: relative;
}
.left_worrk p{
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 30px;
    font-size: 14px;
}
.left_worrk .number{
    float: left;
    width: 25px;
    height: 25px;
    background: #409eff;
    border-radius: 50px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
    margin-right: 10px;
}
.left_worrk .title{
    float: left;
    line-height: 25px;
    font-weight: 700;
}
.right_work{

    float: right;
    width: 10vw;
    padding: 10px;
    right: 0;
    top: 50%;
    bottom: 50%;
    margin-top: -75px;
}

</style>