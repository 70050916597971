<!-- 临时作业 -->
<template>
    <div>
        <el-dialog title="查看作业" :visible.sync="dialogFormVisible">

            <el-card style="margin-bottom: 40px;">
                <h4 style="color: #409EFF;">阅读在线</h4>
                <el-table
               
                    :data="detail.read"
                    highlight-current-row
      
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    property="title"
                    label="材料名称">
                    </el-table-column>
                    <el-table-column
                    property="do_text"
                    label="状态"
                    width="100">
                    </el-table-column>
                    <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            :type="scope.row.is_do?'danger':'primary'"
                            @click="doWork(scope.$index, scope.row.wid,0,'')">{{ scope.row.is_do?'查看':'做题' }}</el-button>
          
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-card >
                <h4 style="color: #409EFF;">应试英语</h4>
                <el-table
          
                    :data="detail.specialty"
                    highlight-current-row
      
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    property="title"
                    label="材料名称">
                    </el-table-column>
                    <el-table-column
                    property="do_text"
                    label="状态"
                    width="80">
                    </el-table-column>
                    <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            :type="scope.row.is_do?'danger':'primary'"
                            size="mini"
                            @click="doWork(scope.$index,scope.row.wid,1,scope.row.z_type)">{{ scope.row.is_do?'查看':'做题' }}</el-button>
          
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        
        </el-dialog>
    </div>
</template>

<script>
import Http from "../src/apis/student";
export default {
    data(){
        return{
            dialogFormVisible:false,
            detail:[],
            task_id:'',
            plan_id:''
        }
    },
    
    methods:{
        viewWorkDetail(task_id,plan_id){
            this.task_id = task_id
            this.plan_id = plan_id
            var params = {}
            params.task_id = task_id
            params.plan_id = plan_id
            params.type = 1
            Http.workDetail(params).then((res) => {
                this.detail = res.data
                this.dialogFormVisible = true
            });
        },

        doWork(index,wid,type,z_type){
            this.dialogFormVisible = false
            window.sessionStorage.setItem("plan_identifying", this.plan_id);
            window.sessionStorage.setItem("type_identifying", 1);
            if(type == 0){
                this.$router.push({name:'ReadDetail',query:{id:wid,task_id:this.task_id,plan_id:this.plan_id,worktype:1,type:0}})
            }else{
                this.$router.push({name:'SpecialtyDetail',query:{id:wid,task_id:this.task_id,plan_id:this.plan_id,worktype:1,type:z_type,dotype:0}})
            }
        }
    }
}
</script>