import axios from '../services/request'

// 首页
export const Index = (data = {}) => axios.get('api/match/index',data);

// 详情
export const Detail = (data = {}) => axios.get('api/match/detail',data);

// 报名
export const Enroll = (data = {}) => axios.get('api/match/enroll',data);

// 比赛页面
export const List = (data = {}) => axios.get('api/match/list',data);

// 比赛页面-排名
export const Usermatch = (data = {}) => axios.get('api/match/usermatch',data);

// 文章详情
export const readDetail = (data = {}) => axios.get('api/match/readDetail',data);

// 提交答案
export const matchAnswer = (data = {}) => axios.post('api/match/answer',data);
export default{
    Index,
    Detail,
    Enroll,
    List,
    Usermatch,
    readDetail,
    matchAnswer
}