<template>
    <div>
        <p v-if="skeleton_type == 'image_text'" style="width: 100%;display: inline-block;">
            <el-col v-for="(item,index) in 4" :key="index" :span="24">
                <el-card shadow="never">
                    <el-skeleton style="height: 100px;" :animated="true">
                        <template slot="template">
                            <el-skeleton-item variant="image" style="width: 165px;height: 125px;float: left;margin-right: 20px;" />
                            <div class="skeleton_list">
                                <el-skeleton />
                            </div>
                        </template>
                    </el-skeleton>
                </el-card>
            </el-col>
        </p>
        <p v-if="skeleton_type == 'message'" style="width: 100%;display: inline-block;">
            <el-col v-for="(item,index) in 3" :key="index" :span="24" style="margin-bottom: 10px;">
                <el-card shadow="never">
                    <el-skeleton :animated="true">
                        <template slot="template">
                            <div class="message_list">
                                <el-skeleton />
                            </div>
                        </template>
                    </el-skeleton>
                </el-card>
            </el-col>
        </p>
        <div v-if="skeleton_type == 'grade'" class="container" style="width: 100%;">
            <div v-for="(item,index) in 6" :key="index" class="item">
                <span class="item_name" >
                    <el-skeleton :rows="2" :animated="true" variant="text" />
                </span>
                <span class="item_value">
                    <el-skeleton :count="1" :rows="1" :animated="true" variant="text" />
                </span>
            </div>
        </div>
        <div v-if="skeleton_type == 'read_index'" class="container" style="width: 100%;">
            <el-skeleton v-for="item in 6" :key="item" :animated="true" style="width: 200px">
                <template slot="template">
                    <div style="padding: 14px;">
                        <el-skeleton-item variant="p" style="width: 50%" />
                        <div
                        style="display: flex; align-items: center; justify-items: space-between;"
                        >
                        <el-skeleton-item variant="text" style="margin-right: 16px;width: 60%;" />
                        <el-skeleton-item variant="text" style="width: 20%;" />
                        </div>
                    </div>
                    <el-skeleton-item variant="image" style="width: 170px; height: 120px;" />
                    <div style="padding: 14px;">
                        <el-skeleton-item variant="p" style="width: 40%" />
                        <div
                        style="display: flex; align-items: center; justify-items: space-between;"
                        >
                        <el-skeleton-item variant="text" style="margin-right: 16px;width: 60%;" />
                        <el-skeleton-item variant="text" style="width: 20%;" />
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
        <div v-if="skeleton_type == 'specialtyIndex'" class="specialtyIndex" style="width: 100%;">
            <el-skeleton :rows="5" :animated="true" style="width: 100%;"></el-skeleton>
        </div>
    </div>
</template>
<script>
export default {
    props: {  
        skeleton_type: {  
            type: String,
            default:''
        }
    },
    data(){
        return{
            variant:'image'
        }
    }
}
</script>
<style scoped>
.specialtyIndex >>> .el-skeleton__item{
    width: 100%;
}
.skeleton_list{
    width: 50%;
    display: inline-block;
}
.message_list{
    width: 100%;
    text-align: left;
}
.container {
    display: flex;
    flex-wrap: wrap; 
}
    
    .item {
        flex: 0 0 calc(33.3333% - 2px);
        height: auto;


        justify-content: center; 
        align-items: center; 
        box-sizing: border-box;
    }
    .item span{
        width: 100%;
        display: inline-block;
        text-align: left;
    }
    .item_name{
        font-size: 14px;
        line-height: 30px;
        font-weight: 700;
        color: #000000;
    }

    .item_value{
        font-size: 16px;
        line-height: 35px;
        font-weight: 700;
        color: #409eff;
    }
</style>