<!-- 添加学生 -->
<template>
    <div>
        <el-dialog title="添加学生" @close="diaClose" width="30%" :visible.sync="addstudentDialogFormVisible">
            <el-form size="small" :model="create_form">
                <el-form-item label="">
                    <el-input v-model="create_form.stuid" style="margin-bottom: 15px;" :clearable="true" placeholder="请输入学号"></el-input>

                    <el-input v-model="create_form.name" :clearable="true" placeholder="请输入姓名"></el-input>
                    <!-- <el-button class="search_button" type="primary">搜索</el-button> -->
                </el-form-item>
                
            </el-form>
            
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="addstudentDialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="createLoading" @click="addFormBotton">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/teach";
export default {
    data(){
        return{
            addstudentDialogFormVisible:false,
            create_form:{
                stuid:'',
                name:'',
            },
            createLoading:false,
            plan_id:0
        }
    },

    methods:{
        diaClose(){

        },

        showDia(plan_id){
            this.plan_id = plan_id
            this.addstudentDialogFormVisible = true
        },

        addFormBotton(){
            this.createLoading = true
            var params = this.create_form
            params.plan_id = this.plan_id
            Http.handCreate(params).then((res) => {
                this.createLoading = false
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                if(res.code){
                    this.create_form.name = ''
                    this.create_form.stuid = ''
                    this.addstudentDialogFormVisible = false
                    this.$emit('success')
                }
            });
        }
    }
}
</script>
<style scoped>

</style>