<template>
    <div>
        <layoutpage>
            <div class="banner">
                <el-input v-model="keyword" placeholder="请输入内容" @input="searchArticl" clearable><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
            </div>
            <div class="search_box">
                <el-table
                    :data="tableData_"
                    :cell-style="{cursor: 'pointer'}"
                    @row-click="handleRowClick"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="read_title"
                    label="标题"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="label"
                    label="类型"
                    width="100">
                    </el-table-column>

                </el-table>
                <el-pagination
                    v-if="total_number"
                    style="margin-top: 20px;"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="pageSize"
                    :total="total_number">
                </el-pagination>
            </div>
            
        </layoutpage>
        
    </div>
</template>
<script>
import Http from "../../apis/common";
export default {
    data(){
        return{
            keyword:'',
            page:0,
            pageSize:20,
            total_number:0,
            tableData_:[]
        }
    },

    methods:{
        searchArticl(e){
            var params = {}
            params.page = this.page
            params.pageSize = this.pageSize
            params.keyword = e
            Http.search(params).then((res) => {
                this.total_number = res.data.total_number
                this.tableData_ = res.data.data
        
            });
    
        },
        handleRowClick(e){
            if(e.table_name == "read"){
                this.$router.push({name:'ReadDetail',query:{id:e.id,type:0}});
            }
            if(e.table_name == "specialty"){
                this.$router.push({name:'SpecialtyDetail',query:{id:e.id,type:e.type}});
            }
            // if(e.table_name == "culture"){
            //     _this.$router.push({name:'ReadDetail',query:{id:e.id,type:0}});
            // }
            // if(e.table_name == "wiki"){
            //     _this.$router.push({name:'ReadDetail',query:{id:e.id,type:0}});
            // }
            // if(e.table_name == "skill"){
            //     _this.$router.push({name:'ReadDetail',query:{id:e.id,type:0}});
            // }

        },
        // 上一页
        prev(e){

            this.page = e
            this.searchArticl(this.keyword)
        },

        // 下一页
        next(e){

            this.page = e
            this.searchArticl(this.keyword)
        },

        // 点击页码
        changeNumber(e){

            this.page = e
            this.searchArticl(this.keyword)
        },

        // 前往指定页数
        changeSize(e){

            this.page = e
            this.searchArticl(this.keyword)
        }
    }
}
</script>
<style scoped>
.banner{
    position: relative;
    width: 100%;
    height: 360px;
    background: url('../../../static/image/sousuobanner.png') no-repeat;
    background-size: 100% contain;
    background-position: center;
}
.banner >>> .el-input{
    position: relative;
    width: 500px;
    top: 240px;
    left: -160px;
}
.search_box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
}
</style>