import axios from '../services/request'

// 列表
export const Index = (data = {}) => axios.get('api/skill/index',data);

// 技能训练分类
export const getSkillType = (data = {}) => axios.get('api/skill/getSkillType',data);

/* 收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);
export default{
    Index,
    getSkillType,
    Coll
}