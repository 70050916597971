<template>
    <div class="page">
        <layout>
            <div class="mapdetail_page">
                <div class="top_label">
                    <span class="lable_name">学习计划</span>
                </div>
                <p><span class="now_time el-icon-date"> 第{{detail.day}}天 </span> / 总计 {{ detail.everyday_number }} 天</p>

                <el-col :span="24">
                    <el-card shadow="never">
                        <div class="stage_task">
                            <div class="stage_task_left">
                                <el-row v-if="detail.mapout && detail.mapout.read_type" :gutter="14">
                                    <i style="float: left;" class="el-icon-warning"></i>
                                    <el-col :span="16">
                                        <el-card shadow="never">
                                            <div class="intro">
                                                <span>完成阅读在线</span>
                                                <span style="color: #409EFF;margin-left: 10px;margin-right: 10px;">{{ detail.mapout.read_type }}</span>
                                                <span style="color: red;"> {{ detail.submit_read }}</span>
                                                <span>/ {{ detail.mapout.read_number }} 篇 / 天</span>
                                            </div>
                                            
                                        </el-card>
                                        
                                    </el-col>
                                    <span @click="gopage(1)" class="gopage">前往做题 ></span>
                                </el-row>
                                <el-row style="margin-top: 10px;" v-if="detail.mapout && detail.mapout.specialty_type" :gutter="14">
                                    <i style="float: left;" class="el-icon-warning"></i>
                                    <el-col :span="16">
                                        <el-card shadow="never">
                                            <div class="intro">
                                                <span>完成应试英语</span>
                                                <span style="color: #409EFF;">{{ detail.mapout.specialty_type }}</span>
                                                <span style="color: red;"> {{ detail.submit_specialty }}</span>
                                                <span>/ {{ detail.mapout.specialty_number }} 篇 / 天</span>
                                            </div>
                                            
                                        </el-card>
                                        
                                    </el-col>
                                    <span @click="gopage(2)" class="gopage">前往做题 ></span>
                                </el-row>
                            </div>
                            <div class="stage_task_right">
                        
                                <el-button @click="history_" size="small" type="primary" plain>计划完成情况</el-button>
                            
                            </div>
                        </div>
                    </el-card>
                </el-col>

                <div class="top_plan_info_title">
                    <span class="el-icon-tickets"> 学习记录</span>
                </div>

                <el-table :data="detail.submitanswers" style="width: 100%">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column prop="w_id_text" label="材料" ></el-table-column>
                    <el-table-column prop="type" label="类别" width="120"></el-table-column>
                    <el-table-column prop="score" label="正确率" width="120"></el-table-column>
                    <el-table-column prop="createtime" label="时间" width="150"></el-table-column>
                </el-table>

                <el-dialog
                    title="计划完成情况"
                    :visible.sync="dialogVisible"
                    width="35%"
                >
                    <el-calendar v-if="range" :range="range">
                        <template #dateCell="{ date }" >

                        <div :class="{'el-icon-check': isHighlighted(date)}">
                            {{ new Date(date).getDate() }}
                        </div>
                        </template>
                    </el-calendar>

                </el-dialog>



    
            </div>  
            
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/student";
export default {
    data(){
        return{
            dialogVisible:false,
            highlightedDates: [],
            detail:[],
            range:[],
            id:''
        }
    },
    activated(){
        this.id = this.$route.query.id
        this.getData()
    },
    methods:{
        history_(){
            this.dialogVisible = true
        },
        getData(){
            var params = {}
            params.mid = this.id
            Http.mapDetail(params).then((res) => {
                this.detail = res.data
                this.range = res.data.range
                this.highlightedDates = res.data.submit_day
            });
        },

        gopage(table){
            if(table == 1){
                this.$router.push({name:'ReadIndex',query:{type:1}})
            }else{
                this.$router.push({name:'SpecialtyIndex',query:{type:1}})
            }
        },

        // 检查当前日期是否在高亮日期列表中
        isHighlighted(date) {
            const formattedDate = this.formatDate(date);
            return this.highlightedDates.includes(formattedDate);
        },
        // 格式化日期以匹配字符串格式
        formatDate(date) {
            return new Date(date).toISOString().slice(0, 10);
        }
    }
}
</script>
<style scoped>
.top_label{
    width: 100%;
    height: 1.6vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.mapdetail_page p{
    text-align: left;
    font-size: 15px;
}
.now_time{
    color: #409EFF;
}
.intro span{
    display: inline-block;
}
.stage_task{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}
.stage_task_left{
    float: left;
    width: 58%;
}
.stage_task_left .gopage{
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: -120px;
    color: #ADAEB0;
    border-bottom: 1px #ADAEB0 solid;
    padding-top: 8px;
    padding-bottom: 4px;
}
.stage_task_right{
    float: right;
}
.stage_task_left >>> .el-card__body{
    padding: 7px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
}
.stage_task_left >>> .el-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.stage_task_left >>> .el-icon-warning{
    margin-top: 10px;
    color: #409EFF;
}
.stage_task_right p{
    margin: 0;
}
.stage_task_right >>> .el-divider{

    margin: 20px 0;
}
.stage_task_right_value{
    float: right;
    color: #409EFF;
    font-size: 18px;
    font-weight: 700;
}
.top_plan_info_title{
    width: 100%;
    height: 30px;
    color: #409EFF;
    display: inline-block;
    margin-top: 20px;
}

.top_plan_info_title span{
    float: left;
    margin-left: 10px;
    line-height: 18px;
    font-size: 15px;
    font-weight: 700;
}
.highlighted-date {
  background-color: #409EFF; /* 蓝色背景 */
  color: white; /* 白色字体 */
  text-align: center;
}
.el-icon-check{
    color: red;
}
</style>