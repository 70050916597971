<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">我的分享</span>
                </div>

                <div class="second_box">
   

                    <!-- <div class="label_button_box">
                        <div class="label_button_box_left">
                            <el-input v-model="input" placeholder="请输入学生姓名"></el-input>
                            <el-button type="primary">搜索</el-button>
                        </div>
                        <div class="label_button_box_right">
                            
                            <el-button size="small" type="primary">导出成绩</el-button>
                        </div>
                        
                    </div> -->
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%">
                        <el-table-column
                        type="index"
                        width="55">
                        </el-table-column>
       
                        <el-table-column
                        prop="name"
                        label="名称"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="address"
                        label="时间"
                        width="120"
                        >
                        </el-table-column>

                    </el-table>


                </div>

            </div>
        </layout>
    </div>
</template>

<script>
  export default {
    data() {
        return {
      
            tableData: []
    
        };
    },
    methods: {

    }
  };
</script>

<style scoped>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .label_button_box_right >>> .el-button{
        float: right;
        margin-top: 0px;
    }
    .label_button_box_left{
        float: right;
        width:21vw;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .label_button_box_right >>> .el-button{
        float: right;
    }
    .label_button_box_left{
        float: right;
        width:19vw;
    }
}
.top_label{
    width: 100%;
    height: 1.6vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:20px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;

}
.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.second_box {
    text-align: left;
}

.second_box >>> .el-select{
    width: 25vw;
}
.second_box >>> .el-row{
 
    border: 1px solid #ebeef5;
    margin: 0 !important;
    
    margin-top: 15px !important;
    padding: 10px 0;
}
.second_box >>> .title{
 
    line-height: 30px;
 
}
.second_box >>> .number{
 
    line-height: 30px;

}
.second_p_box >>> .el-button--text{

    font-size: 18px;
}
.second_p_box{
    text-indent: 2vw;
    margin: 0 !important;
    padding: 0 !important;
}
</style>