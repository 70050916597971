<!-- 整体成绩 -->
<template>
    <div class="first_box">
        <el-row :gutter="20">
            <el-col :span="6">
                <div>
                <el-statistic title="完成材料总数">
                    <template slot="formatter">
                        {{ total_data.total_number }}
                    </template>
                </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                <el-statistic title="完成阅读在线篇数">
                    <template slot="formatter">
                    {{ total_data.read_number }}
                    </template>
                </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                <el-statistic title="完成应试英语篇数">
                    <template slot="formatter">
                        {{ total_data.specialty_number }}
                    </template>
                </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="学习总时长">
                        <template slot="formatter">
                        {{ total_data.study_time }}
                        </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>
        <div style="clear: both;"></div>
        <div style="display: flex;flex-wrap: wrap;">
            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.task_count }}</span><span class="unit"> 次</span>
                </div>
                <div class="item name">
                    布置作业总数 
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.cycle_number }}</span><span class="unit"> 次</span>
                </div>
                <div class="item name">
                    布置周期作业 
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.temporary_number }}</span><span class="unit"> 次</span>
                </div>
                <div class="item name">
                    布置临时作业 
                </div>
            </el-card>


            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_number }}</span><span class="unit"> 篇</span>
                </div>
                <div class="item name">
                    阅读在线 
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_number }}</span><span class="unit"> 篇</span>
                </div>
                <div class="item name">
                    应试英语 
                </div>
            </el-card>


            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_completion_count }}</span><span class="unit"> </span>
                </div>
                <div class="item name">
                    阅读平均做题时长
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_completion_count }}</span><span class="unit"> </span>
                </div>
                <div class="item name">
                    应试平均做题时长
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.read_user_score }}</span><span class="unit"> %</span>
                </div>
                <div class="item name">
                    阅读平均正确率
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.specialty_user_score }}</span><span class="unit"> %</span>
                </div>
                <div class="item name">
                    应试平均正确率
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.total_number }}</span><span class="unit"> 篇</span>
                </div>
                <div class="item name">
                    做题总数 
                </div>
            </el-card>

            <el-card class="box-card" shadow="never">
                <div class="item number">
                    <span>{{ total_data.scoreRate }}</span><span class="unit"> %</span>
                </div>
                <div class="item name">
                    总平均正确率 
                </div>
            </el-card>

            
            <el-card class="box-card" style="padding: 25px;" shadow="never">
                <div style="color: #409EFF;cursor: pointer;line-height: 60px;" class="item">
                    <i class="el-icon-s-data"></i>
                    图标统计 
                </div>
            </el-card>
        </div>
        

        <div class="label_button_box">
            <div class="label_button_box_left">
                <el-input v-model="form.name" size="small" @input="changeStudent" :clearable="true" placeholder="请输入学生姓名"></el-input>
                <!-- <el-button type="primary">搜索</el-button> -->
            </div>
            <div class="label_button_box_right" style="width: 450px;">
                <el-date-picker
                style="float: left;margin-top: 1px;"
                    size="small"
                    v-model="timeslot"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <el-button size="small" @click="importTotalFile" type="primary">导出成绩</el-button>
    
            </div>
            <p class="colm_box">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox v-for="(item,index) in file_form" :key="index" :disabled="isDisabled(index)" :label="index">{{ item }}</el-checkbox>
                </el-checkbox-group>
            </p>

        </div>

        <el-table
            :data="student"
            tooltip-effect="dark"
            style="width: 100%;display: inline-block;margin-top: 30px;"
            v-loading="table_loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.1)"
            element-loading-text="拼命计算中..."
            >
            <el-table-column
            type="index"
            width="55">
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名"
            >
            </el-table-column>
            <el-table-column
            prop="stuid"
            label="学号"
            >
            </el-table-column>
            <el-table-column
            prop="read_number"
            label="阅读在线"
            >
            </el-table-column>
            <el-table-column
            prop="read_rate"
            label="正确率"
            >
            </el-table-column>
            <el-table-column
            prop="specialty_number"
            label="应试英语"
            >
            </el-table-column>
            <el-table-column
            prop="specialty_rate"
            label="正确率"
            >
            </el-table-column>
            <el-table-column
            prop="study_time"
            label="做题时长"
            >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import Http from "../src/apis/statistics";
export default {
    data(){
        return{
            total_data:{},
            student:[],
            form:{
                name:'',
                plan_id:'',
                type:0
            },
            checkList:[],
            file_form:[],
            table_loading:false,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            checDisList:[],
            timeslot: ''
        }
    },

    methods:{
        isDisabled(index) {
            var checDisList = JSON.parse(JSON.stringify(this.checDisList))

            for(var i in checDisList){

                if(index == checDisList[i]){
                    return true
                }
            }

        },
        setData(file_form,checkList){
            this.checkList = checkList
            this.checDisList = checkList
            this.file_form = file_form
        },
        // 总成绩统计
        getDetail(plan_id,state){
            this.form.plan_id = plan_id
            this.form.state = state
            var params = {}
            params.state = state
            params.plan_id = plan_id
            Http.Index(params).then((res) => {
                this.total_data = res.data
            });
        },

        // 下方学生列表
        getBottomStudent(){
            this.table_loading = true
            Http.Student(this.form).then((res) => {
                this.student = res.data
                this.table_loading = false
            });
        },

        changeStudent(){
            this.getBottomStudent()
        },


        importTotalFile(){
            // 文件的直接下载链接  

            var params = this.checkList
            
            var condition = {}
            for(var index in params){
                condition[params[index]] = true
            }
            condition.plan_id = this.form.plan_id
            condition.type = 0
            var time = '';
            if(this.timeslot){
                var start_time = this.$Common.getTime(this.timeslot[0])
                var end_time = this.$Common.getTime(this.timeslot[1])
                time += `&start_time=${start_time}&end_time=${end_time}`
            }
            const fileUrl = `${this.$Config.baseUrl}/api/teachgrades/import?token=${window.sessionStorage.getItem("token")}&${this.$Common.objectToQueryString(condition)}${time}`;  

            location.href = fileUrl
        }
    }
}
</script>
<style scoped>


.box-card{
    padding: 10px 35px;
    margin-top: 20px;
    margin-right: 15px;
}
.box-card  >>> .el-card__body{

    padding: 0;
}
.box-card .item{
    line-height: 30px;

}

.box-card .number{
    font-weight: 700;
    color: #409EFF;
}
.box-card >>> .name{
    font-weight: 0 !important;
    font-size: 14px;
    font-weight: 700;
}
.box-card span{
    width: auto;
}
.first_box >>> .el-row{
    padding: 20px 0;
    border-top: 10px #409EFF solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin: 0 !important;
}
.first_box >>> .head{
    line-height: 30px;
}

.first_box >>> .title{
    color: #000000;
    font-size: 18px;
    font-weight: 700;
}
.first_box >>> .number{
    color: #409EFF;
}
.first_box >>> .el-card{
    width: 140px;
    padding: 10px 15px !important;
}
.unit{
    display: inline-block;
    font-size: 12px;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:20px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;
    
}
.label_button_box_right >>> .el-button{
        float: right;
    }
.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.colm_box{
    display: inline-block;
    text-align: right;
    height: 2vw;
    margin-bottom: 20px;
    border: 1px solid rgb(235, 238, 245);
    padding: 0px 20px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}
</style>