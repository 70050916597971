import axios from '../services/request'
/* 学生班级列表 */
export const Plan = (data = {}) => axios.get('api/student/plan',data);

/* 班级码搜索班级 */
export const searchPlan = (data = {}) => axios.get('api/student/getPlan',data);

/* 加入班级 */
export const pushPlan = (data = {}) => axios.get('api/student/pushPlan',data);

/* 班级班级详情 */
export const planDetail = (data = {}) => axios.get('api/student/planDetail',data);

/* 退出班级 */
export const quitPlan = (data = {}) => axios.get('api/student/quitPlan',data);

/* 班级作业 */
export const Task = (data = {}) => axios.get('api/studentwork/task',data);

/* 作业详情 */
export const workDetail = (data = {}) => axios.get('api/studentwork/workDetail',data);

/* 成绩统计 */
export const workResult = (data = {}) => axios.get('api/studentwork/workResult',data);

/* 历史记录 */
export const historyList = (data = {}) => axios.get('api/studentwork/historyList',data);

/* 分层教学 */
export const getStage = (data = {}) => axios.get('api/studentwork/getStage',data);

/* 分层教学做题记录 */
export const getStageSubmit = (data = {}) => axios.get('api/studentwork/getStageSubmit',data);

/* 分层教学题目 */
export const stageWork = (data = {}) => axios.get('api/studentwork/stageWork',data);

/* 学习计划列表 */
export const mapList = (data = {}) => axios.get('api/mapout/index',data);

/* 创建学习计划 */
export const mapCreate = (data = {}) => axios.post('api/mapout/create',data);

/* 学习计划详情 */
export const mapDetail = (data = {}) => axios.get('api/mapout/detail',data);

/* 消息列表 */
export const tidingsList = (data = {}) => axios.get('api/student/tidingsList',data);

/* 阅读详情作业列表 */
export const readDetailWork = (data = {}) => axios.get('api/studentwork/readDetailWork',data);

/* 验证作业是否可做 */
export const doWork = (data = {}) => axios.get('api/studentwork/doWork',data);
export default{
    Plan,
    searchPlan,
    pushPlan,
    planDetail,
    quitPlan,
    Task,
    workDetail,
    workResult,
    historyList,
    getStage,
    getStageSubmit,
    stageWork,
    mapList,
    mapCreate,
    mapDetail,
    tidingsList,
    readDetailWork,
    doWork
}