<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>我的班级</span>
                </div>
                <el-col :span="24">
                    <el-card shadow="never">
                        <div class="list_thumb">
                            <img src="../../../static/image/bjtp-mr4.png" />
                        </div>
                        <div class="list_info">
                            <div class="list_info_text" style="font-weight: 700;">{{ detail.name }}</div>
                            <div class="list_info_text">
                                <span>年级: {{ detail.grade_text }}</span>
                                <span>学院: {{ detail.college }}</span>
                                <span>专业: {{ detail.specialty }}</span>
                            </div>
                            <div class="list_info_text">
                                <span>管理员: {{ detail.u_id_text }}</span>
                                <span>学生人数: {{ student.length }}</span>
                                
                            </div>
                            <div class="list_info_text">
                                <span>邀请码: {{ detail.qcode }} <i @click="copyToClipboard(detail.qcode)" class="el-icon-copy-document" style="color: #409EFF;cursor: pointer;"></i></span>
                            </div>
                        </div>
           
                    </el-card>
                </el-col>
                <div class="top_label">
                    <span class="lable_name">管理老师</span>
              
                </div>
                <div class="tabel">
                    <el-table
                    :data="teach"
                    style="width: 100%">
                        <el-table-column
                            type="index"
                            width="100">
                            </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="mobile"
                            label="电话"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="admin_text"
                            label="角色">
                        </el-table-column>
                        <el-table-column
                            prop="createtime"
                            label="加入时间">
                        </el-table-column>
        
                    </el-table>
                </div>
                <div class="top_label">
                    <span class="lable_name">学生列表</span>
                    <el-row>

                        <el-button size="small" @click="quitPlan_" type="danger">退出班级</el-button>
                    </el-row>
                </div>
                <div class="tabel">
                    <el-table
                    :data="student"
                    style="width: 100%">
        
                        <el-table-column
                            type="index"
                            width="100">
                            </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="stuid"
                            label="学号"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="mobile"
                            label="手机号">
                        </el-table-column>
                        <el-table-column
                            prop="gender"
                            label="性别">
                        </el-table-column>
           
                        <el-table-column
                            prop="createtime"
                            label="加入时间">
                        </el-table-column>

                    </el-table>
                </div>
            
            </div>
        </layout>
    </div>
</template>

<script>
    import Http from "../../apis/student";
    import Clipboard from 'clipboard';
    export default{

        data(){
            return{
                dialogFormVisible:false,
                form:{
                    name:''
                },
                plan_id:'',
                detail:{},
                student:[],
                teach:[]
            }
        },
        activated(){
            this.plan_id = this.$route.query.plan_id
            this.getDetail()
            window.addEventListener('popstate', this.handlePopState());
        },
        mounted(){
            
        },
        methods:{
            copyToClipboard(text) {
                const clipboard = new Clipboard('.btn', {
                    text: () => text
                });
                clipboard.on('success', (e) => {
                    this.$message({
                        showClose: true,
                        message: '复制成功',
                        type: 'success'
                    });
                    clipboard.destroy();
                });
                clipboard.on('error', (e) => {
                    this.$message({
                        showClose: true,
                        message: '复制失败',
                        type: 'error'
                    });
                    clipboard.destroy();
                });
                clipboard.onClick(event);
            },
            handlePopState(){
                document.body.style.overflow = 'auto';
            },
            // 获取详情
            getDetail(){
                var params = {}
                params.plan_id = this.plan_id
                Http.planDetail(params).then((res) => {
                    this.detail = res.data.detail
                    this.student = res.data.student
                    this.teach = res.data.teach
                });
            },
            // 退出班级
            quitPlan_(){

                var params = {}
                params.plan_id = this.plan_id
                Http.quitPlan(params).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    if(res.code){
                        this.$router.back();
                    }
                });
            }
        }
    }
</script>
<style>

</style>
<style scoped>
body{
    overflow-y: auto !important;
}
.top_label >>> .el-row{
    float: right;
}
.top_plan_info_title{
    width: 100%;
    height: 30px;
}
.top_plan_info_title img{
    width: 23px;
    height: 18px;
    float: left;
}
.top_plan_info_title span{
    float: left;
    margin-left: 10px;
    line-height: 18px;
    font-size: 18px;
    font-weight: 700;
}
.main_body{
    padding: 10px;
}
.list_info{
    width: 30vw;
    height: 6vw;
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
}
.list_info_text{
    width: 100%;
    text-align: left;
    display: inline-block;
    line-height: 1.5vw;
}

.list_info_text span{
    display: inline-block;
    font-size: 14px;
}
.list_thumb{
    float: left;
    width: 10vw;
    height: 6vw;
}

.list_thumb img{
    width: 100%;
    height: 100%;

}
.main_body >>>.el-card{
    border:0;
}
.main_body >>>.el-card__body{
    float: left;
    width: 100%;
    padding-right: 25px;
    box-sizing: border-box;
}
.list_info_text span{
    margin-right: 1vw;
}
.top_label{
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-top: 1vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.dialog_ >>> .el-input{
    width: 50%;
    float: left;
}
.dialog_ >>> .el-dialog{
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
}
.dialog_ >>> .search_button{
    float: left;
    margin-left: 10px;
}
.dialog_ >>> .el-dialog__header{
    background: #409EFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.dialog_ >>> .el-dialog__title{
    color: #ffffff !important;
}
.dialog_ >>> .el-dialog__close{
    color: #ffffff !important;
}
</style>