<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>我的班级</span>
                </div>
                <el-col :span="24">
                    <el-card shadow="never">
                        <div class="list_thumb">
                            <img src="../../../static/image/bjtp-mr4.png" />
                        </div>
                        <div v-if="detail" class="list_info">
                            <div class="list_info_text" style="font-weight: 700;">{{ detail.name }}</div>
                            <div class="list_info_text">
                                <span>年级: {{ detail.grade_text }}</span>
                                <span>学院: {{ detail.college }}</span>
                                <span>专业: {{ detail.specialty }}</span>
                            </div>
                            <div class="list_info_text">
                                <span>管理员: {{ detail.u_id_text }}</span>
                                <span>学生人数: {{ detail.number }}</span>
                                <span>邀请码: {{ detail.qcode }} <i @click="copyToClipboard(detail.qcode)" class="el-icon-copy-document" style="color: #409EFF;cursor: pointer;"></i></span>
                            </div>
                            <div class="list_info_text">
                                <el-radio-group @change="changePush" v-model="is_push">
                                    <el-radio :label="0">允许加入</el-radio>
                                    <el-radio :label="1">禁止加入</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
           
                    </el-card>
                </el-col>
                <div class="top_label">
                    <span class="lable_name">班级管理</span>
                    <el-row>
                        
                        <el-button size="small" @click="addTeach(1)" type="primary">添加老师</el-button>
                        <el-button size="small" @click="addTeach(2)" type="danger" plain>退出班级</el-button>
                    </el-row>
                </div>
                <div class="tabel">
                    <el-table
                    :data="teach"
                    style="width: 100%">
                        <el-table-column
                            type="index"
                            width="100">
                            </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="gender"
                            label="性别">
                        </el-table-column>
                        <el-table-column
                            prop="mobile"
                            label="账号"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="admin_text"
                            label="角色">
                        </el-table-column>
                        <el-table-column
                            prop="createtime"
                            label="加入时间">
                        </el-table-column>
                        <el-table-column label="操作">
                                <template slot-scope="scope">
        
                                    <el-button @click="reamoveTeach_(scope.row)" :disabled="scope.row.is_admin == 1?true:false" size="mini" :type="scope.row.is_admin == 1 && uid != scope.row.id?'text':'danger'">

                                        {{ (scope.row.is_admin == 1?'/':(scope.row.is_admin == 0 && scope.row.id != uid?'移出班级':'/')) }}

                                    </el-button>
                                </template>
                            </el-table-column>
                    </el-table>
                </div>
                <div class="top_label">
                    <span class="lable_name">学生列表</span>
                    <el-row>
                        <el-button size="small" @click="downTemplate" class="el-icon-paperclip" type="primary"> 表格模板</el-button>
                        <el-button size="small" class="el-icon-plus" @click="handsCreate" type="primary"> 手动添加</el-button>
                        <div class="custom-file-upload">  
                            <input type="file" @change="handleFileUpload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            <label class="el-icon-upload2" for="myFile"> 选择文件</label>  
                        </div>
                        <el-button size="small" type="danger" @click="delStudent" plain>删除</el-button>
                    </el-row>
                </div>
                <div class="tabel">
                    <el-table
                    :data="student"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <!-- <el-table-column
                            type="index"
                            width="100">
                            </el-table-column> -->
                        <el-table-column
                            prop="name"
                            label="姓名"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="username"
                            label="账号"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="stuid"
                            label="学号"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="mobile"
                            label="手机号">
                        </el-table-column>
                        <el-table-column
                            width="55"
                            prop="gender"
                            label="性别">
                        </el-table-column>
                        <el-table-column
                            width="100"
                            prop="createtime"
                            label="加入时间">
                        </el-table-column>
                        <el-table-column label="操作" width="220">
                                <template slot-scope="scope">
                                    <el-popover style="margin-right: 10px;" placement="right" width="600" trigger="click" :title="`${study_user} - 本年做题记录`">
                                        <!-- <el-button type="text">{{ study_user }} - 做题记录</el-button> -->
                                        <el-button size="mini" style="margin-bottom: 15px;" type="danger" plain @click="delSubmitanser">删除</el-button>

                                        <el-table :data="study_data" v-loading="loading" @selection-change="handleSelectionChangeStudy" element-loading-background="rgba(0, 0, 0, 0.3)">
                                            <el-table-column type="selection" width="55"></el-table-column>
                                            <el-table-column width="300" property="w_id_text" label="标题"></el-table-column>
                                            <el-table-column property="createtime" label="时间"></el-table-column>
                                            <el-table-column property="table_type" label="类型"></el-table-column>
                                        </el-table>
                                        <el-pagination
                                            style="margin-top: 20px;margin-bottom: 20px;"
                                            v-if="total_number"
                                            @size-change="changeSize"
                                            @current-change="changeNumber"
                                            @prev-click="prev"
                                            @next-click="next"
                                            :page-size="pageSize"
                                            :total="total_number">
                                        </el-pagination>
                                        <el-button slot="reference" size="mini" type="primary" @click="resetting(scope.row)">重置学习记录</el-button>
                                    </el-popover>
                                    

                                    <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index,scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                    </el-table>
                </div>
                <div class="dialog_">
                    <el-dialog :title="dia_title" @close="diaClose" width="30%" :visible.sync="dialogFormVisible">
                        <el-form size="small">
                            <el-form-item label="">
                                <el-input v-model="keyword" @input="changKeyword" :clearable="true" placeholder="输入老师手机号或者姓名搜索"></el-input>
                                <!-- <el-button class="search_button" type="primary">搜索</el-button> -->
                            </el-form-item>
                            
                        </el-form>
                        
                        <el-table size="medium" :data="teach_data" style="width: 100%">
                            <el-table-column
                                prop="name"
                                label="姓名">
                            </el-table-column>
                            <el-table-column
                                prop="mobile"
                                label="账号">
                            </el-table-column>
                
                            <el-table-column label="操作">
                                <template slot-scope="scope">
        
                                    <el-button
                                    v-if="options_type == 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleAdd(scope.$index, scope.row)">添加成员</el-button>
                                    <el-button
                                    v-if="options_type == 2"
                                    size="mini"
                                    type="danger"
                                    @click="handleAdd(scope.$index, scope.row)">移交班级</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-dialog>
                </div>

                <Add-student ref="student_ref" @success="handsCreateSuccess"></Add-student>

            </div>
        </layout>
    </div>
</template>

<script>
    import addStudent from "../../../untils/addstudent.vue";
    import Http from "../../apis/teach";
    import Clipboard from 'clipboard';
    import { read } from 'xlsx';
    const XLSX = require('xlsx');  
    export default{
        components:{
            'Add-student':addStudent
        },
        data(){
            return{
                dialogFormVisible:false,
                keyword:'',
                teach_data:[],
                radio:0,
                plan_id:'',
                detail:{},
                student:[],
                teach:[],
                uid:'',
                student_id:[],
                study_data:[],
                page:1,
                pageSize:10,
                loading:false,
                study_user:'',
                total_number:0,
                study_item:{},
                submit_data:[],
                options_type:1,
                dia_title:'添加老师',
                is_push:0
            }
        },
        activated(){
            var userInfo = JSON.parse(window.sessionStorage.getItem('userinfo'))
            this.uid = userInfo.id
            this.plan_id = this.$route.query.plan_id
            this.getDetail(this.plan_id)
            
        },

        mounted() {
            
        },
        methods:{

            copyToClipboard(text) {
                const clipboard = new Clipboard('.btn', {
                    text: () => text
                });
                clipboard.on('success', (e) => {
                    this.$message({
                        showClose: true,
                        message: '复制成功',
                        type: 'success'
                    });
                    clipboard.destroy();
                });
                clipboard.on('error', (e) => {
                    this.$message({
                        showClose: true,
                        message: '复制失败',
                        type: 'error'
                    });
                    clipboard.destroy();
                });
                clipboard.onClick(event);
            },


            //班级详情

            getDetail(plan_id){
                const loading = this.$loading({
                    lock: true,
                    text: '疯狂加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                var params = {}
                params.plan_id = plan_id
                Http.planDetail(params).then((res) => {
                    loading.close()
                    this.detail = res.data.detail
                    this.student = res.data.student
                    this.teach = res.data.teach

                    this.is_push = this.detail.is_push
                });

            },

            handsCreateSuccess(){
                this.getDetail(this.plan_id)
            },

            // 搜索老师
            changKeyword(value){
                var params = {}
                params.keyword = value
                Http.addTeachSearch(params).then((res) => {
       
                    this.teach_data = res.data
                });
            },

            // 添加老师
            handleAdd(e,item){
                var params = {}
                params.teach_id = item.id
                params.plan_id = this.plan_id
                Http.addTeach(params).then((res) => {
       
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    this.getDetail(this.plan_id)
                    this.dialogFormVisible = false
                });
            },

            // 移除老师

            reamoveTeach_(item){
                var params = {}
                params.plan_id = this.plan_id
                params.uid = item.id
                params.name = item.name
                params.is_admin = item.is_admin
                Http.removeTeach(params).then((res) => {
       
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    if(res.code){
                        this.getDetail(this.plan_id)
                    }
                    
                });

            },

            // 导入学生名单
 
            handleFileUpload(event) {  
                const file = event.target.files[0];  
                if (!file) {  
                    return;  
                }  
                const loading = this.$loading({
                    lock: true,
                    text: '正在导入，请耐心等待...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.3)'
                });
                const reader = new FileReader();  
                reader.onload = (e) => {  
                    const data = new Uint8Array(e.target.result);  
                    const workbook = read(data, { type: 'array' }); // 使用 read 函数读取 Excel 文件  

                    const worksheetName = workbook.SheetNames[0];  
                    const worksheet = workbook.Sheets[worksheetName];  

                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 , defval: null}); // 假设第一行是标题行
                    const filteredData = jsonData.slice(1).filter(row => row.some(cell => cell !== null && cell !== '')); // 从第二行开始并过滤空行

                    var params = {}
                    params.plan_id = this.plan_id
                    params.student = filteredData
                    
                    Http.importStudent(params).then((res) => {
                        
                        loading.close();
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.code ? 'success' : 'error'
                        });
                        if(res.code){
                            this.getDetail(this.plan_id)
                        }
                        event.target.value = null;  
                    });

                    
                };  
                reader.readAsArrayBuffer(file);  
            },

            // 删除学生
            delStudent(){
                this.$confirm('请确认是否需要删除, 如有误删请手动添加或者重新导入, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.removeOptions()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });          
                });
            },

            // 勾选学生
            handleSelectionChange(val){
                var student = [];
                for(var index in val){
                    student.push(val[index].id)
                }
                this.student_id = student
            },


            removeOptions(){
                Http.removeStudent(this.student_id).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                    if(res.code){
                        this.getDetail(this.plan_id)
                    }
                });
            },

            // 删除单个

            handleDelete(index,item){
                var params = []
                params.push(item.id)
                this.student_id = params
                this.delStudent()
            },

            // 选择需要重置的学习记录
            handleSelectionChangeStudy(val){

                var sids = []
                for(var index in val){
                    sids.push(val[index].id)
                }
                this.submit_data = sids
            },

            // 删除学习记录
            delSubmitanser(){
                this.$confirm('删除后无法恢复, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                    Http.delSubmitanswe(this.submit_data).then((res) => {
                            
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.code ? 'success' : 'error'
                        });
                        if(res.code){
                            this.resetting(this.study_item)
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });          
                });
            },



            resetting(item){
                console.log(item)
                this.study_item = item
                this.study_user = item.name
                this.loading = true
                var params = {}
                params.uid = item.uid
                params.page = this.page
                params.pageSize = this.pageSize
                Http.resettingStudy(params).then((res) => {
                    this.loading = false
                    this.total_number = res.data.number
                    this.study_data = res.data.list
                });
            },
            // 上一页

            prev(e){
                this.page = e
                this.resetting(this.study_item)
            },

            // 下一页
            next(e){
        
                this.page = e
                this.resetting(this.study_item)
            },

            // 点击页码
            changeNumber(e){
                this.page = e
                this.resetting(this.study_item)
            },

            // 前往指定页数
            changeSize(e){
                this.page = e
                this.resetting(this.study_item)
            },
            addTeach(type){
                // 管理员
                if(this.teach[0].id == this.uid){
                    this.dia_title = type == 1?'添加老师':'退出班级';
                    this.options_type = type
                    this.dialogFormVisible = true
                    return false
                }
                // 教师成员不需要移交班级，直接退出
                this.$confirm('退出后不会再管理该班级, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params = {}
                    params.plan_id = this.plan_id
                    Http.qiutPlan(params).then((res) => {
                            
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.code ? 'success' : 'error'
                        });
                        if(res.code){
                            this.$router.back();
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });          
                });
            },
            diaClose(){
                this.keyword = ''
                this.teach_data = []
            },
            handsCreate(){
                this.$refs.student_ref.showDia(this.plan_id)
            },

            // 修改班级加入状态

            changePush(){

                if(this.teach[0].id != this.uid){
                    this.$message({
                        showClose: true,
                        message: '无操作权限',
                        type: 'error'
                    });

                    return false;
                }

                var params = {}
                params.plan_id = this.plan_id
                params.is_push = this.is_push
                Http.pushRidao(params).then((res) => {
                            
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.code ? 'success' : 'error'
                    });
                });
            },

            downTemplate(){
                location.href = `${this.$Server.tempHost}api/teach/studentTemplate`
            }
        }
    }
</script>
<style scoped>
.custom-file-upload {  
    position: relative;
    border-radius: 4px;  
    display: inline-block;  
    cursor: pointer;  
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
}  
  
.custom-file-upload input[type="file"] {  
    position: absolute;
    top: 0;  
    right: 0;  
    opacity: 0;  
    outline: none;  
    cursor: inherit;  
    width: 76px;
}  
  
.custom-file-upload label {  
    font-size: 12px;
    border-radius: 4px;  
    padding: 9px 15px;
    border: none;
    text-align: center;  
    background-color: #409EFF;
    color: #ffffff;
    border-color: #409EFF;
}



.upload-file{
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}
.top_label >>> .el-row{
    float: right;
}
.top_plan_info_title{
    width: 100%;
    height: 30px;
}
.top_plan_info_title img{
    width: 23px;
    height: 18px;
    float: left;
}
.top_plan_info_title span{
    float: left;
    margin-left: 10px;
    line-height: 18px;
    font-size: 18px;
    font-weight: 700;
}
.main_body{
    padding: 10px;
}
.list_info{
    width: 30vw;
    height: 6vw;
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
}
.list_info_text{
    width: 100%;
    text-align: left;
    display: inline-block;
    line-height: 1.5vw;
}

.list_info_text span{
    display: inline-block;
    
}
.list_thumb{
    float: left;
    width: 10vw;
    height: 6vw;
    
}

.list_thumb img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.main_body >>>.el-card{
    border:0;
}
.main_body >>>.el-card__body{
    float: left;
    width: 100%;
    padding-right: 25px;
    box-sizing: border-box;
}
.list_info_text span{
    margin-right: 1vw;
    font-size: 15px;
}
.top_label{
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-top: 1vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.dialog_ >>> .el-input{
    width: 50%;
    float: left;
}
.dialog_ >>> .el-dialog{
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
}
.dialog_ >>> .search_button{
    float: left;
    margin-left: 10px;
}
.dialog_ >>> .el-dialog__header{
    background: #409EFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.dialog_ >>> .el-dialog__title{
    color: #ffffff !important;
}
.dialog_ >>> .el-dialog__close{
    color: #ffffff !important;
}
</style>