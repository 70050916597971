<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="w_id_text" label="标题"></el-table-column>
                <el-table-column property="score" label="正确率" width="100"></el-table-column>
                <el-table-column property="signs" label="积分" width="100"></el-table-column>
                <el-table-column property="time" label="耗时" width="100"></el-table-column>
                <el-table-column property="table_type_text" label="类型" width="120"></el-table-column>
            </el-table>
            <el-pagination
                    style="margin-top: 20px;"
                    v-if="total_number"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="form.pageSize"
                    :total="total_number">
                </el-pagination>
                <Stage-work ref="stagework"></Stage-work>
        </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/teach";
import stagework from "../untils/stagework";
export default {
    components:{
        'Stage-work':stagework
    },
    data(){
        return{
            dialogTableVisible:false,
            gridData:[],
            total_number:0,
            
            title:'',
            form:{
                level:'',
                uid:'',
                trem:'',
                page:1,
                pageSize:20,
            }
        }
    },

    methods:{
        getStageWork_(uid,trem,level,name){
            this.title = `${trem}期 - ${level}层${name}做题记录`

            this.form.level = level
            this.form.uid = uid
            this.form.trem = trem
            Http.stageSubmitList(this.form).then((res) => {
                this.dialogTableVisible = true
                this.gridData = res.data.list
                this.total_number = res.data.total_number
            });
        },
        // 上一页

        prev(e){

            this.form.page = e
            this.getStageWork(this.form.uid,this.form.level,this.form.trem)
        },

        // 下一页
        next(e){

            this.form.page = e
            this.getStageWork(this.form.uid,this.form.level,this.form.trem)
        },

        // 点击页码
        changeNumber(e){

            this.form.page = e
            this.getStageWork(this.form.uid,this.form.level,this.form.trem)
        },

        // 前往指定页数
        changeSize(e){

            this.form.page = e
            this.getStageWork(this.form.uid,this.form.level,this.form.trem)
        },
    }
}
</script>