import axios from '../services/request'

// 列表
export const Index = (data = {}) => axios.get('api/campus/index',data);

// 校园生活分类
export const getCampusType = (data = {}) => axios.get('api/campus/getCampusType',data);

// 更新热度
export const setIn = (data = {}) => axios.get('api/campus/setIn',data);

/* 收藏 */
export const Coll = (data = {}) => axios.post('api/common/coll',data);
export default{
    Index,
    getCampusType,
    setIn,
    Coll
}